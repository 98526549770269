import React from 'react';
import TextField from '@mui/material/TextField';
import {Container, Row,Col, Button} from "react-bootstrap";
import { useTranslation } from 'react-i18next';

export interface ICustomAddress{
    customIndex: number,
    customAddress: string,
    IFsetSaveCustomAddress(e: number):void,
    IFsetSingeCustomAddress(e: number):void,
  }

function CustomAddress(props:ICustomAddress) {
  //lan
  const { t } = useTranslation();

  return (

            <Col xs="12" md="12" lg="4" className='non-p  border-bold-green ps-2 pe-2'>

                    <fieldset className="border radius-5 text-start ps-2 border-gray cursor-pointer " onClick={() => {props.IFsetSingeCustomAddress(props.customIndex)}}>
                      
                      <div className='fw-bold font-size-t4 border-bold-white'>{"送貨地址 " + (props.customIndex + 1) + " : - "}</div> {props.customAddress}
                      
                    </fieldset>

             </Col>
    
  );
}

export default CustomAddress;

