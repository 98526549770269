import React from 'react';
import './LandingPage.css'; // Make sure to create this CSS file
import { useTranslation } from 'react-i18next';
import lxwxh from '../../../image/lxwxh.png';
import lxwxh_en from '../../../image/lxwxh_en.png';
import hktv_landing_img from '../../../image/hktv_landing_img.jpeg';
import hktv_landing_img_en from '../../../image/hktv_landing_img_en.jpeg';
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import cover_zh from '../../../image/cover_zh.jpeg';
import cover_en from '../../../image/cover_en.jpeg';
import { Quotation0910Data } from '../../MainForm';

interface ILandingPage{
  IFhandleHideDialog:()=>void,
  sendLocal:string,
  shipment_number:string
}

function LandingPage(props:ILandingPage) {

  const { t } = useTranslation();
  const params = useParams();
  let language: string = params.language === 'zh' ? "zh" : "en";
  const imageSrc = language === 'zh' ? lxwxh : lxwxh_en;
  const NewImgSrc = language === 'zh' ? hktv_landing_img : hktv_landing_img_en;
  let GetOrderDate = Number(props.shipment_number.substring(1, 7));
  if (isNaN(GetOrderDate)) {
    GetOrderDate = 0; 
  }

  return (
    <>
        <div className=" ps-3 pe-3" id="LandingMainPage">
          { GetOrderDate < Quotation0910Data ? <div onClick={() => {props.IFhandleHideDialog()}}>
          <div className='HexagonBG fw-bold text-center font-size-t8 mb-3 mt-2'>{t('TitleP0')}</div>
          <div className='text-center font-size-t8 ps-3 pe-3'>{t('SubTitlePart1P0')}<span className='font-size-24 color-blue'>{t('SubTitlePart2P0')}</span>{t('SubTitlePart3P0')}<span className='font-size-24 color-blue'>{t('SubTitlePart4P0')}</span>{t('SubTitlePart5P0')}</div>

          <fieldset className="border radius-10 text-start p-4 border-primary mt-3 mb-3 landing-box">
                          <legend  className="float-none w-auto"></legend>
                          <div className='text-start'>
                            <div className='font-size-t8 mb-2'>{t('WeightTitleP0')}</div>
                            <span className='font-size-t8 color-white backcolor-blue p-1'>{t('ActualWeightTitleP0')}</span>
                            <div className='font-size-t8 mt-2 mb-2 fw-bold color-blue'>{t('ActualWeightContentP0')}</div>
                            <span className='font-size-t8 color-white backcolor-blue p-1'>{t('ValWeightTitleP0')}</span>
                            <span className="formula-container color-blue font-size-t8">
                              <span className="formula">{t('ValWeightContentP0')}</span>
                              <span className="divider"></span>
                              <span className="divider-number">5,000</span>
                            </span>
                            <img src={imageSrc} className='float-start phone-size' />
                          </div>
                        </fieldset>

                        <div className='text-center font-size-t8 color-red fw-bold'>{t('RemindP0')}</div>

                        <div className='text-center font-size-t9 color-green fw-bold underlined-text'>{t('TipsTitleP0')}</div>
                        <div className='text-start font-size-t9 color-green fw-bold underlined-text'>{t(
                          props.sendLocal === 'CA' ? 'TipsCAP0' : 
                          props.sendLocal === 'UK' ? 'TipsUKP0' : 
                          'TipsP0'
                        )}</div>
          </div> : 
            <img src={NewImgSrc} className='landing-img-size mt-5 mb-2' />
          }
          

          <Button variant="primary" className='d-block mx-auto mt-3 font-size-t8 mb-3' onClick={() => {props.IFhandleHideDialog()}}>{t('SubmitBtnP0')}</Button>{' '}
        </div>
    </>
  );
}

export default LandingPage;
