import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import {Container, Row,Col,Button} from "react-bootstrap";
import { IFormFiveFormVal, IPromoCode, Quotation0910Data } from '../../MainForm';
import ConsignmentList from './FormItem/ConsignmentList';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import AlertComponent from '../BlockAlert/AlertComponent';
import { useParams } from 'react-router-dom';
import { log } from 'console';
import SizeWeightComponent from './FormItem/SizeWeight/SizeWeightComponent';



function FifthPage(props:IFormFiveFormVal) {
    const params = useParams();
    //para
    const [showAlert,setShowAlert] = useState(false);
    const [alertType,setAlertType] = useState("");
    const [alertMsg,setAlertMsg] = useState("");

    let shipment_period = parseInt(props.shipment_number.substring(1, 7));

    //lan
    const { t } = useTranslation();

    let getlan = localStorage.getItem("lng");

    useEffect(() => {
      let totalPrice = 0;
      props.itemLists.forEach((item) => {
        totalPrice += item.itemQty * item.itemPrice;
      });
      props.IFsetTotalPriceF5(totalPrice);
    }, [props.itemLists]);

    //InterfaceFunction
    let IFsetShowOff = () => {
      setShowAlert(false);
    }

    const totalSKUPromoQty = Array.isArray(props.SKUPromoCodeList)
    ? props.SKUPromoCodeList.reduce((total, SKUPromo) => {
        return total + (SKUPromo.promo_code_minus_kg_qty || 0); // Ensure the property exists
      }, 0)
    : 0;

    //PromoFun
    const CheckPromoData = () => {
      axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/promo-code/${props.couponCodeF5}?membership_level=${props.membership_level_str}&shipid=${props.shipment_number}&FinalPrice=${props.finalPrice65}&FinalKG=${props.finalKGF6}&autoapply=0&TaxType=${props.selectedPaymentMethodF3}&totalSKUPromoQty=${totalSKUPromoQty}&totalSKUPromoQty=${props.cumulativeKgQtyMinus}`)
      .then((res) => {
          let JsonData:IPromoCode  = res.data.data;
          props.IFsetPromoObjF5(JsonData);
          setShowAlert(false);
          const discountPer:number = JsonData.promo_code_multi;
          const discountMoney:number = JsonData.promo_code_minus;
          const discountKG:number = JsonData.promo_code_minus_kg;
          const couponPoint:number = JsonData.promo_code_point;
          props.IFsetAutoPromoObjF5(null);
          props.IFsetAutoPromoPriceF6(0);
          props.IFsetPromoPriceF6(props.finalPrice65 - ((props.finalPrice65 - discountMoney - discountKG) * discountPer));
          props.setPromoPoint(couponPoint);
      }).catch((err) => {
          if(err.response.data.error == "check_once_account_error"){
            setAlertMsg(t("PromoObjF5ErrorCheckOnce")!!);
          }else{
            setAlertMsg(t("PromoObjF5Error")!!);
          }
          setAlertType("warning");
          setShowAlert(true);
          props.IFsetPromoObjF5(null);
          props.IFsetPromoPriceF6(0);
          props.setPromoPoint(0);
          console.error(err.response.data.error);
          

      });
    };

    const SetNonPromoCode  = () => {
      props.IFsetPromoObjF5(null);
      props.IFsetPromoPriceF6(0);
      props.setPromoPoint(0);
      setShowAlert(false);
    };

    const SetNonAutoPromoCode  = () => {
      props.IFsetAutoPromoObjF5(null);
      props.IFsetAutoPromoPriceF6(0);
      props.setPromoPoint(0);
      setShowAlert(false);
    };

  return (
    <div className="mb-5">

      <Container>
        <Row>
          <Col xs="12" md="12" lg="12" className='non-p'>
            <fieldset className="border radius-10 mt-5 p-3 ps-5 pe-5 text-start border-dark">
              <legend  className="float-none w-auto ">{t("MainTitleF5")}</legend>
              <div className=''>
                <div className="border-bottom border-dark p-3">
                  <div className='float-start fw-bold'>{t("ProductTitleF5")}</div>
                  <div className='text-end fw-bold'>{t("ProductPriceTitleF5")}</div>
              </div>

                {props.itemLists.map((value,index) => {
                  let indexState = props.itemLists.length - index;
                  var getClass="";
                  if(indexState==1){
                    getClass = "radius-bottom ";
                  }else if(indexState == props.itemLists.length){
                    getClass = "radius-top ";
                  }

                  return <ConsignmentList   key={index}
                                          itemName={getlan === 'en' ? value.itemNameen : value.itemNamezh}
                                          itemQty={value.itemQty}
                                          itemTotalPrice={value.itemQty * value.itemPrice}
                                          classNames={getClass}></ConsignmentList>
                })}
                <h5 className='fw-bold text-end mt-3 me-3'>{t("TotalValueLabelF5")}{props.totalPriceF5.toLocaleString()}{t("TotalCurrencybelF5")}</h5>
              </div>
            </fieldset>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="12" lg="12" className='non-p'>
            <fieldset className="border radius-10 mt-5 p-3 ps-5 pe-5 text-start border-dark">
              <legend  className="float-none w-auto ">{t('WeightTitleF5')}</legend>
              {props.jsonData.packages.map((value,index) => {
                  return <SizeWeightComponent 
                            key={index}
                            getIndex={index+1}
                            length={value.dimension.length}
                            width={value.dimension.width}
                            height={value.dimension.height}
                            weight={value.weight.value}
                            shipment_period={shipment_period}
                  />

                })}
            </fieldset>
                
            
            {
              shipment_period < Quotation0910Data
              ?
                <>{(props.selectedPaymentMethodF3 == 4 || props.selectedPaymentMethodF3 == 5) ?
                <div className='color-red text-start mt-3 fw-bold'>{t('SeaWeightTipsF5')}</div>
                : <div className='color-gray text-start mt-3 fw-bold'>{t('WeightTipsF5')}</div>
                }</>
              :
              <div className='color-gray text-start mt-3 fw-bold'>{t('NewWeightTipsF5')}</div>
            }
            
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="12" lg="6" className='text-start mt-5 non-p'>

            <h5 className='fw-bold'>{t("CouponTitleF5")}</h5>
            
            <Container>
              <Row>
                <Col xs="6" md="6" lg="8" className='non-p'>
                  <TextField
                      id="couponCodeF5"
                      name="couponCodeF5"
                      className='mt-3 mb-3'
                      fullWidth
                      label={t("CouponLabelF5")}
                      onChange={props.IFsetCouponCodeF5} 
                      value={props.couponCodeF5} 
                      type='text'
                      inputProps={{ maxLength: 31 }}
                  />

                  {showAlert && <AlertComponent alertMsg={alertMsg} alertType={alertType} IFsetShowOff={IFsetShowOff} alertCustom='' /> }
                  
                  {props.promoObjF5 != null && <div>{t('UsePromoCodeF5')}({props.promoObjF5.promo_code_string})
                  <Button  className='ms-2 backcolor-41b8a2' onClick={() => {SetNonPromoCode()}}>{t("DeleteUsePromoCodeF5")}</Button>{' '}</div>}

                  {props.autoPromoObjF5 != null && <div className='mt-3'>{t('UseAutoPromoCodeF5')}({props.autoPromoObjF5.promo_code_string})
                  <Button  className='ms-2 backcolor-41b8a2' onClick={() => {SetNonAutoPromoCode()}}>{t("DeleteUsePromoCodeF5")}</Button>{' '}</div>}

                </Col>
                <Col xs="6" md="6" lg="4" className=''>
                  <Button  className='mt-4 backcolor-41b8a2' onClick={() => {CheckPromoData()}}>{t("CouponBtnLabelF5")}</Button>{' '}
                  
                </Col>
              </Row>
            </Container>

            <div className='CouponResult'></div>

          </Col>
        </Row>
        <Row>
                
        </Row>
      </Container>

      <div className='text-start mt-3'>{t('CustomPointTitle')}{props.customPoint} {t('CustomPointSubTitle')}</div>
      
  </div>
  );
}

export default FifthPage;

