import React from 'react';
import { useTranslation } from 'react-i18next';
import { Quotation0910Data } from '../../../../MainForm';

interface ISizeWeightComponent {
  getIndex: number,
  length: number,
  width: number,
  height: number,
  weight: number,
  shipment_period:number
}

function SizeWeightComponent(props: ISizeWeightComponent) {
  let LWHSum = Math.round((props.length * props.width * props.height) / 5000 * 100) / 100;
  //lan
  const { t } = useTranslation();

  let setFloat = ""
  if(props.shipment_period < Quotation0910Data){
    setFloat = "float-end"
  }

  return (
    <div className="box">
      <div className="fw-bold color-gray">BOX {props.getIndex}</div>
      <div className="fw-bold color-gray">
        {props.shipment_period < Quotation0910Data && <>
          <span>{t('WeightLengthF5')}{props.length} cm</span>
          <span>{t('WeightWidthF5')}{props.width} cm</span>
          <span>{t('WeightHeightF5')}{props.height} cm/5000 </span>
          <span>= {LWHSum}KG</span>
          </>
        }
        <span className={setFloat}>{t('WeightF5')}{props.weight}KG</span>
      </div>
      <hr/>
    </div>
  );
}

export default SizeWeightComponent;
