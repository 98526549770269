import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface IConsignmentList{
  itemName:string,
  itemQty:number,
  itemTotalPrice:number,
  classNames:string,
}

function ConsignmentList(props:IConsignmentList) {
    //lan
    const { t } = useTranslation();

    const Product = props.itemName + " x " + props.itemQty;

  return (
    <div className="border-bottom border-dark p-3">
        <div className='float-start fw-bold'>{Product}</div>
        <div className='text-end fw-bold'>{t("CurrencyTitle1F5C")} {props.itemTotalPrice.toLocaleString()} {t("CurrencyTitle2F5C")}</div>
    </div>
  );
}

export default ConsignmentList;


/*
<Label>{Product}</Label>
<Label>價值</Label>
<Label>{props.itemTotalPrice}元</Label>

*/