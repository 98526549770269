import { useEffect, useState } from "react";
import { JSONType } from "../MainForm";
import StatusComponent, { IStatusComponent } from "./StatusComponent/StatusComponent";
import { useTranslation } from "react-i18next";
import { IStatusJsonType, IStatusPackageJsonType } from "../StatusForm";
import { json } from "stream/consumers";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CompanyImg from "../../image/company_logo.jpg";

export interface IStatusDetail {
    JsonData:IStatusPackageJsonType,
}


function StatusDetail(props:IStatusDetail) {

    //lan
    const { t } = useTranslation();

    const [sendLocal,setSendLocal] = useState("UK");

    const [FirstLoad,setFirstLoad] = useState(true);
    const [packageCount, setPackageCount] = useState(0);

    const [shipment_number, setShipmentNumber] = useState("");

    const [shipmentStatus, setShipmentStatus] = useState<IStatusComponent[]>([]);

    let shipment_title = t("SubTitle1F1") + shipment_number;

    const [fieldsetCss, setFieldsetCss] = useState(" radius-10 mt-4 backcolor-gray" );

    useEffect(() => {

        if(FirstLoad){
          SetData();
          setFieldsetCss(fieldsetCss + (getWindowDimensions().width < 768 ? " p-3": " p-5"));
          setFirstLoad(false);
        }
        
      });

    const SetData = () => {
        let jsonData:IStatusPackageJsonType  = props.JsonData;
        let jsonDataArr:IStatusJsonType[] = jsonData.shipment_status.slice().reverse();
        if(jsonDataArr === undefined){return;}
        setShipmentNumber(jsonData.shipment_number);
        setSendLocal(jsonData.request_location);
        setPackageCount(jsonData.package_count);
        if(jsonDataArr.length <= 0){
            setShipmentStatus(shipmentStatus => [...shipmentStatus,{statusTitle:"正在準備發貨"
                ,statusContext:""
                ,statusDate:"----/----/----"
                ,statusCheck:true
                ,statusDetail:""}])
        }
        for(let i=0;i<jsonDataArr.length;i++){
            let setDate = jsonDataArr[i].shipment_datetime;
            let setchecked = false;
            if(i === 0){
                setchecked = true;
            }
            setShipmentStatus(shipmentStatus => [...shipmentStatus,{statusTitle:t("Status_" + jsonDataArr[i].shipment_step)
                ,statusContext:t("StatusContext_" + jsonDataArr[i].shipment_step)
                ,statusDate:setDate
                ,statusCheck:setchecked
                ,statusDetail:jsonDataArr[i].shipment_details ?? ""}])
        }
    }

    return (
        <div >

            <div className="text-start">

            <div>
                <div className='fw-bold small-phone-txt float-start'><span  className="p-1 color-white backcolor-green">{shipment_title}</span>
                    <span className="ms-2 p-1 ps-3 pe-3 backcolor-8fc240"></span>
                    <span className="ms-2 p-1 ps-3 pe-3 backcolor-3ab54a"></span>
                    <span className="ms-2 p-1 ps-3 pe-3 backcolor-135b56"></span>
                </div>

                <div className='text-end mt-5'>
                    <img src={CompanyImg}  className='img-size mt-3'/>
                </div>

            </div>

            <span className="small-phone-txt fw-bold mt-4 mb-4 p-1 pe-5 backcolor-gray">{t("StatusTitle")} {packageCount}</span>

            {props.JsonData.request_transport_company != null && <div className="mt-4 mb-4">
                <span className="small-phone-txt fw-bold  p-1 pe-5 backcolor-gray">{t('StatusTransportCompany')} {props.JsonData.request_transport_company}</span>
            </div>}

            {props.JsonData.request_waybill_number != null && <div className="mt-4 mb-4">
                <span className="small-phone-txt fw-bold p-1 pe-5 backcolor-gray">{t('StatusWaybillNumber')} {props.JsonData.request_waybill_number}</span>
            </div>}
                <div className="font-size-t4 mt-4 "><img src="/image/Flag_of_Hong_Kong.svg" className="img-fluid ms-2 " width="50"/> {t("HK")} 
                        <KeyboardArrowRightIcon className="backcolor-gray radius-10 ms-2"/> 
                        <img 
                            src={
                                sendLocal === 'UK' 
                                ? "/image/Flag_of_the_United_Kingdom.svg" 
                                : sendLocal === 'CA' 
                                ? "/image/Flag_of_Canada.svg"
                                : "/image/Flag_of_Australia.svg"
                            } 
                            className="img-fluid ms-2 me-2" 
                            width="50" 
                        />
                        {t(sendLocal === 'UK' ? "UK" : sendLocal === 'CA' ? "CA" : "AU")}
                </div>
            </div>

            <fieldset className={fieldsetCss}>
            <div className='text-start'>
                <table className="w-100">
                    <tbody>
                        {   shipmentStatus.map((item, index) => {
                                return <StatusComponent key={index} 
                                statusTitle={item.statusTitle} statusDate={item.statusDate} 
                                statusCheck={item.statusCheck} statusContext={item.statusContext}
                                statusDetail={item.statusDetail}
                                 />
                            })
                        }
                    </tbody>
                </table>
            </div>
            </fieldset>
            
        </div>
    );
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

export default StatusDetail;

