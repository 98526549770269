import React from 'react';
import { IFormFourFormVal } from '../../MainForm';
import {Container, Row,Col} from "react-bootstrap";
import CompanyImg from "../../../image/company_logo.jpg";
import { useTranslation } from 'react-i18next';
import RecordButton from '../../../component/RecordButton';

function FourthPage(props : IFormFourFormVal) {
    //lan
    const { t } = useTranslation();

  //Label
    const recipientLabel = props.contactNameF2 + ", " + props.contactAddress1F2 + props.contactAddress2F2 + props.contactAddress3F2 + "\n" +
    props.areaCodeF2 + " " + props.contactPhoneF2 + "\n\n" + props.contactEmailF2;
    const senderLabel = props.contactNameF1 + ",\n" + props.areaCodeF1 + " " + props.contactPhoneF1 + "\n\n" + t("senderSubTotalF4") + "\n" + props.contactEmailF1;
  
  return (
    <div className="mb-5">
        
        <Container>
          <Row>
            <Col xs="12" md="12" lg="12" className='non-p'>

              <div className='float-end mt-5'>
                  <img src={CompanyImg} className={`img-size ${props.sendLocal === 'UK' ? '' : 'half-opacity'}`} />
              </div>
          
              <div className="float-stat text-start">
                  <h2 className='fw-bold mt-5'>{t("MainTitleF4")}</h2>
                  <h4 className='fw-bold mt-3'>{t("SubTitle1F4")}</h4>
                  <h4 className='fw-bold'>{t("SubTitle2F4")}</h4>
                  <h4 className='fw-bold mt-5 mb-5 small-phone-txt'><span  className="p-1 color-white backcolor-green">{t("SubTitle3F4")}{props.shipment_number}</span>
                  <span className="ms-2 p-1 ps-3 pe-3 backcolor-8fc240"></span>
                  <span className="ms-2 p-1 ps-3 pe-3 backcolor-3ab54a"></span>
                  <span className="ms-2 p-1 ps-3 pe-3 backcolor-135b56"></span>
                </h4>
                <RecordButton custompKey={props.customPKey} />

              </div>

              <Container>
                <Row>
                  <Col xs="12" md="12" lg="4" className='non-p'>
                    <fieldset className="border radius-10 text-start ps-5 border-dark">
                      <legend  className="float-none w-auto">{t("recipientTitleF4")}</legend>
                      <div className='text-start'>
                        <div className='fw-bold'>{props.contactNameF2},</div>
                        <span className=''> {props.contactAddress1F2}</span>
                        <span className='ms-1'> {props.contactAddress2F2}</span>
                        <span className='ms-1'> {props.contactAddress3F2}</span>
                        <span className='ms-1'>{props.zipCodeF2}</span>
                        <div className='mt-2 mb-2'>
                        {t(props.sendLocal === 'UK' ? "recipientSubTotalF4" : props.sendLocal === 'CA' ? "recipientSubTotalF4CA" : "recipientSubTotalF4AU")}
                        </div>
                        <div className=' mt-2 mb-2'>{props.areaCodeF2} - {props.contactPhoneF2}</div>
                        <div className=' mt-2 mb-2'>{props.contactEmailF2}</div>
                      </div>
                    </fieldset>

                    <fieldset className="border radius-10 mt-5 text-start ps-5 border-dark">
                      <legend  className="float-none w-auto">{t("senderTitleF4")}</legend>
                      {/* <h4 className="fw-bold">{t("senderSubTotalF4")}</h4> */}
                      <div className='text-start'>
                        <div className='fw-bold'>{props.contactNameF1},</div>
                        <div className=' fw-bold mb-3'>{props.areaCodeF1} - {props.contactPhoneF1}</div>
                        <div className='fw-bold'>{t("senderSubTotalF4")}</div>
                        <div className='mb-3'>{props.contactEmailF1}</div>
                      </div>
                    </fieldset>
                  </Col>
                </Row>
              </Container>

            </Col>
          </Row>
        </Container>
      
  </div>
  );
}

export default FourthPage;
