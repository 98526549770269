import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import React, { useEffect } from 'react';
import {  Button,ButtonGroup , Container, Form, FormGroup, Row,Col} from "react-bootstrap";
import { IFormOneFormVal } from '../../MainForm';
import Box from '@mui/material/Box';
import GrayTextField from "../../MainForm";
import { useTranslation } from 'react-i18next';
import CompanyImg from "../../../image/company_logo.jpg";
import RecordButton from '../../../component/RecordButton';


function FirstPage(props : IFormOneFormVal) {

  //lan
  const { t } = useTranslation();

  let shipment_title = t("SubTitle1F1") + props.shipment_number;

  return (
    <div className="">

      <Container>
        <Row>
          <Col xs="12" md="12" lg="12"  className='non-p'>

          <div className="text-start">

                <h2 className='fw-bold mt-3 float-start'>{t("MainTitleF1")}</h2>
                <div className='text-end'>
                <img src={CompanyImg} className={`img-size ${props.sendLocal === 'UK' ? '' : 'half-opacity'}`} />

                </div>
                
                <div className='fw-bold mt-5 mb-3 small-phone-txt'><span  className="p-1 color-white backcolor-green">{shipment_title}</span>
                  <span className="ms-2 p-1 ps-3 pe-3 backcolor-8fc240"></span>
                  <span className="ms-2 p-1 ps-3 pe-3 backcolor-3ab54a"></span>
                  <span className="ms-2 p-1 ps-3 pe-3 backcolor-135b56"></span>
                </div>


                {/* <div className='mb-4 font-size-t4 color-coral'>{t('RequiredTitleF1')}</div> */}

                <RecordButton custompKey={props.customPKey} />

                <div className={`mb-4 font-size-t4 ${props.sendLocal === 'UK' ? 'color-coral' : 'color-gray'}`}>
                  {t(props.sendLocal === 'UK' ? 'RequiredTitleF1' : 'RequiredTitleF1AU')}
                </div>

                {props.fillAllMsg != "" && <div className='color-red fw-bold font-size-t4 mb-4'>{props.fillAllMsg}</div>}

                <h3><span className='fw-bold mt-3 mb-3 p-1 radius-5 color-white backcolor-black'>{t("SubTitle5F1")}</span> {t("SubTitle4F1")}</h3>
            </div>



            <TextField
              className='mt-3 '
              required
              error={props.contactNameF1Err}
              fullWidth
              id="contactNameF1"
              name="contactNameF1"
              label={t("contactNameLabelF1")}
              onChange={props.IFsetContactNameF1} 
              value={props.contactNameF1} 
              type='text'
              inputProps={{ maxLength: 127 }}
            />

            <Container>
              <Row>
                <Col xs="6" md="6" lg="2" className='non-p'>
                  <TextField
                      required
                      error={props.areaCodeF1Err}
                      id="areaCodeF1"
                      name="areaCodeF1"
                      className='mt-3 pe-2'
                      fullWidth
                      label={t("areaCodeLabelF1")}
                      onChange={props.IFsetAreaCodeF1} 
                      value={props.areaCodeF1 === 0? '' :props.areaCodeF1} 
                      type='text'
                      inputProps={{ maxLength: 3 }}
                  />
                </Col>
                <Col xs="6" md="6" lg="4" className='non-p'>
                  <TextField
                      required
                      
                      error={props.contactPhoneF1Err}
                      id="contactPhoneF1"
                      name="contactPhoneF1"
                      className='mt-3'
                      fullWidth
                      label={t("contactPhoneLabelF1")}
                      onChange={props.IFsetContactPhoneF1} 
                      value={props.contactPhoneF1} 
                      type='text'
                      inputProps={{ maxLength: 15 }}
                  />
                </Col>
                <Col xs="12" md="12" lg="6" className='non-p'>
                  <TextField
                      
                      error={props.contactEmailF1Err}
                      id="contactEmailF1"
                      name="contactEmailF1"
                      className='mt-3'
                      fullWidth
                      label={t("contactEmailLabelF1")}
                      onChange={props.IFsetContactEmailF1} 
                      value={props.contactEmailF1} 
                      type='email'
                      inputProps={{ maxLength: 127 }}
                  />
                </Col>
              </Row>
            </Container>

          </Col>

        </Row>
      </Container>
          
  </div>
  );
}

export default FirstPage;

