import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import {Container, Row,Col, Button} from "react-bootstrap";
import { useTranslation } from 'react-i18next';

import axios from 'axios';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import i18n from '../../../i18n';
import MaintenancePage from '../MaintenancePage';
import { IShipmentRecord } from '../../ShipmentRecord';
import CompanyImg from "../../../image/company_logo.jpg";
import { ShipmentDataRecord, TaxTypeNumber } from './ShipmentRecordList';
import SizeWeightComponent from '../Form/FormItem/SizeWeight/SizeWeightComponent';

interface IShipmentRecordDetail{
    ShipmentDataRecord?:ShipmentDataRecord;
}

function ShipmentRecordDetail() {
    const params = useParams();
    const navigate = useNavigate();
    //lan
    const { t } = useTranslation();

    //Page Control
    const [FirstLoad,setFirstLoad] = useState(true);    

    const location = useLocation();
    const state = location.state as { shipmentDataRecord: ShipmentDataRecord }; // Typecasting for safety
    const shipmentDataRecord = state?.shipmentDataRecord;

    let shipment_period = parseInt(shipmentDataRecord.shipment_detail.shipment_number.substring(1, 7));

    let SKUPromoCodeTitle = "";


    if (shipmentDataRecord.promo_codes != null) {
        {shipmentDataRecord.promo_codes
            .filter(code =>  code.promo_code_type === "SKU")
            .map((code, index) => (
                SKUPromoCodeTitle = SKUPromoCodeTitle + code.promo_code_detail + "-"
        ))}
        SKUPromoCodeTitle = SKUPromoCodeTitle.substring(0, SKUPromoCodeTitle.length - 1);
    }

    useEffect(() => {

        if(FirstLoad){
          setLanguage();
              //LoadData();
          setFirstLoad(false);
        }
        
    });
    
    const setLanguage = () => {
        let language :string = params.language === 'zh' ? "zh" : "en";
        handleChangeLng(language);
        document.documentElement.lang = language;
    }

    const handleChangeLng = (lng: string) => {
		i18n.changeLanguage(lng);
		localStorage.setItem("lng", lng);
	};

    
    const LoadData =  async () => {
        
    }


    return (
        <div className="">


            <Container className='mt-5 mb-5 App'>
                <Row>
                    <Col xs="12" md="12" lg="12"  className='non-p'>
                        <Button variant="outline-dark float-start" className='me-2' onClick={() =>  navigate(-1) }>{t("previousStepMF")}</Button>
                        <div className='fw-bold font-size-20 '>{t('ShipmentRecordBtn')} : {shipmentDataRecord && shipmentDataRecord.shipment_detail.shipment_number}</div>
                        <img src={CompanyImg} className={`img-size half-opacity float-end`} />
                    </Col>
                </Row>
            </Container>
            
            {shipmentDataRecord && (
                <>
                <Container className='mt-5 mb-5 border radius-10 mt-5 p-3 ps-5 pe-5 text-start border-dark'>
                    <Row>
                        <Col xs={12} md={6}>{t('ShipmentNumberTitleRecord')}{shipmentDataRecord.shipment_detail.shipment_number}</Col>
                        <Col xs={12} md={6}>{t('OrderNumberTitleRecord')}{shipmentDataRecord.shipment_detail.order_number}</Col>
                        <Col xs={12} md={6}>{t('InsuranceTitleRecord')}{shipmentDataRecord.shipment_detail.request_has_insurance ? t('InsuranceTitleRecordTrue') : t('InsuranceTitleRecordFalse')}</Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>{t('TotalPriceTitleRecord')}{shipmentDataRecord.shipment_detail.total_price_value}</Col>
                        <Col xs={12} md={6}>{t('StatusTitleRecord')}{t(`Status${shipmentDataRecord.shipment_detail.request_status}`)}</Col>
                        <Col xs={12} md={6}>{t("TaxTypeTitleRecord")}{t(TaxTypeNumber[shipmentDataRecord.shipment_detail.request_paytax])}</Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col xs={12} md={6}>{t('SenderNameTitleRecord')}{shipmentDataRecord.shipment_detail.sender_contact_name}</Col>
                        <Col xs={12} md={6}>{t('SenderEmailTitleRecord')}{shipmentDataRecord.shipment_detail.sender_contact_email}</Col>
                        <Col xs={12} md={6}>{t('SenderPhoneTitleRecord')}{shipmentDataRecord.shipment_detail.sender_contact_phone}</Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col xs={12} md={6}>{t('ReceiverNameTitleRecord')}{shipmentDataRecord.shipment_detail.receiver_contact_name}</Col>
                        <Col xs={12} md={6}>{t('ReceiverPhoneTitleRecord')}{shipmentDataRecord.shipment_detail.receiver_phone}</Col>
                        <Col xs={12} md={6}>{t('ReceiverEmailTitleRecord')}{shipmentDataRecord.shipment_detail.receiver_email}</Col>
                        <Col xs={12} md={12} className='mt-2 mb-2'>{t('ReceiverAddressTitleRecord')}{shipmentDataRecord.shipment_detail.receiver_address}</Col>
                        <Col xs={12} md={6}>{t('ReceiverZIPCodeTitleRecord')}{shipmentDataRecord.shipment_detail.receiver_zip_code}</Col>
                        <Col xs={12} md={6}>{t('ReceiverCityTitleRecord')}{shipmentDataRecord.shipment_detail.receiver_city}</Col>
                        <Col xs={12} md={6}>{t('ReceiverProvinceTitleRecord')}{shipmentDataRecord.shipment_detail.receiver_province}</Col>
                        <Col xs={12} md={6}>{t('ReceiverCountryTitleRecord')}{shipmentDataRecord.shipment_detail.receiver_country}</Col>
                    </Row>

                    {shipmentDataRecord.shipment_detail.payment_record && (
                        <>
                            <Row className='mb-3 mt-3'>
                                <Col><strong>{t('PaymentDetailsTitleRecord')}</strong></Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>{t('PaymentAmountTitleRecord')}{shipmentDataRecord.shipment_detail.payment_record.payment_currency_code} {shipmentDataRecord.shipment_detail.payment_record.payment_amount}</Col>
                            </Row>
                            <Row>
                                {shipmentDataRecord.shipment_detail.payment_record?.payment_used_points >= 0 && <Col xs={12} md={6}><p>{t("UsedPointTitleRecord")}{t('PointTitleRecord')}{shipmentDataRecord.shipment_detail.payment_record?.payment_used_points}</p></Col>}
                                {shipmentDataRecord.shipment_detail.payment_record?.payment_get_points >= 0  && <Col xs={12} md={6}><p>{t("AddPointTitleRecord")}{t('PointTitleRecord')}{shipmentDataRecord.shipment_detail.payment_record?.payment_get_points}</p></Col>}
                                
                                


                                {shipmentDataRecord.promo_codes
                                    .filter(code => !code.promo_code_auto_apply && code.promo_code_type === "Normal")
                                    .map((code, index) => (
                                        <React.Fragment key={index}>
                                            <Col xs={12} md={6}>
                                                <div>{t("ManualCouponTitleRecord")}{code.promo_code_string}
                                                    {code.promo_code_point > 0 && (
                                                        <span className="float-end">{t("PromoPointF6")}{code.promo_code_point}</span>
                                                    )}
                                                </div>
                                            </Col>
                                        </React.Fragment>
                                ))}

                                {shipmentDataRecord.promo_codes
                                    .filter(code => code.promo_code_auto_apply && code.promo_code_type === "Normal")
                                    .map((code, index) => (
                                        <React.Fragment key={index}>
                                            <Col xs={12} md={6}>
                                                <div>{t("AutoCouponTitleRecord")}{code.promo_code_string}
                                                    {code.promo_code_point > 0 && (
                                                        <span className="float-end">{t("PromoPointF6")}{code.promo_code_point}</span>
                                                    )}
                                                </div>
                                            </Col>
                                        </React.Fragment>
                                ))} 

                            </Row>
                            <Row>
                                {SKUPromoCodeTitle != "" && <Col xs={12} md={8}><div>{t("BundleCouponTitleRecord")} {SKUPromoCodeTitle}</div></Col>}
                            </Row>
                        </>
                    )}
                    
            </Container>
            <Container className='mt-5 mb-5 border radius-10 mt-5 p-3 ps-5 pe-5 text-start border-dark'>
                    <Row className='mb-3'>
                        <Col><strong>{t('PackageDetailsTitleRecord')}</strong></Col>
                    </Row>
                    {shipmentDataRecord.packages.map((pkg, index) => (
                        <SizeWeightComponent 
                            key={index}
                            getIndex={index+1}
                            length={pkg.dimension_length}
                            width={pkg.dimension_width}
                            height={pkg.dimension_height}
                            weight={pkg.weight}
                            shipment_period={shipment_period}
                        />
                    ))}
            </Container>
            <Container className='mt-5 mb-5 border radius-10 mt-5 p-3 ps-5 pe-5 text-start border-dark'>
                    <Row className='mb-3'>
                        <Col><strong>{t('ItemDetailsTitleRecord')}</strong></Col>
                    </Row>
                    {shipmentDataRecord.items.map((item, index) => (
                        <Row key={index} className='mt-2 mb-2'>
                            <Col xs={12} md={6}>{t('ItemNameTitleRecord')}{item.items_name_zh}</Col>
                            <Col xs={6} md={3}>{t('ItemQtyTitleRecord')}{item.items_qty}</Col>
                            <Col xs={6} md={3}>{t('ItemPriceTitleRecord')}{item.items_unit_price_value}</Col>
                        </Row>
                    ))}

                    
                </Container>
                </>
            )}
        </div>
    );
}

export default ShipmentRecordDetail;


