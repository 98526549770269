
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingBlock from '../page/Block/LoadingBlock';
import ResultPage2 from '../page/Block/ResultPage2';
import ResultPage from '../page/Block/ResultPage';


import axios, { AxiosRequestConfig } from 'axios';
import Button from '@mui/material/Button';
import { useLocation, useParams } from 'react-router-dom';
import React from 'react';
import MessagePage from '../page/Block/messagePage';


interface IGlobePaymentPage {
  shipmentNum: string,
  customer_pkey: string,
  payment_record_id: number,
  refence_num: string,
  return_msg: string,
}


function GlobePaymentPage(): JSX.Element {
    const urlParams = new URLSearchParams(window.location.search);
    const [FirstLoad,setFirstLoad] = useState(true);

    const [data, setData] = useState<IGlobePaymentPage | null>(null);
    const [showMsg, setShowMsg] = useState("");

    //Page Control
    const [activePage,setActivePage] = useState(0);
    //Page Control

    // Get the value of a specific parameter

    const token = urlParams.get('token');
    const result = urlParams.get('result');
    const { t } = useTranslation();
    const params = useParams();

    let language :string = params.language === 'zh' ? "zh" : "en";

    useEffect(() => {
        if(FirstLoad){
          setActivePage(8);
          if(result==="CANCELLED"){
            setShowMsg(t("PaymentCanceledMsg"));
            setActivePage(13);
            return;
          }
          fetch(`${process.env.REACT_APP_BACKEND_URL}/getJWTResult?token=${token}`)
          .then(response => {
              // Check if response is ok (status code 200-299)
              if (!response.ok) {
                  throw new Error('Network response was not ok');
              }
              return response.json();
          })
          .then(jsonData => {
              // Set the jsonData to state
              setData(jsonData);

              if (result === 'SUCCESS') {
                  sendPaySuccess(jsonData!!.shipmentNum,jsonData!!.payment_record_id);
              } else {
                  if(jsonData!!.return_msg === "Not sufficient funds"){
                    setShowMsg(`${t('CardNotEnoughMsg')}\nReference Number: ${jsonData!!.refence_num}`);
                  }else{
                    setShowMsg(`${t("PayFailedMsg")}\nReference Number: ${jsonData!!.refence_num}`);
                  }
                  setActivePage(13);
              }
          })
          .catch(error => {
            //   console.error('There was a problem with the fetch operation:', error.message);
              setShowMsg(t("UnknownFailedMsg"));
              setActivePage(13);
          });
          setFirstLoad(false);
        }
      });


      const sendPaySuccess = async (shipmentNum: string,payment_record_id:string) => {
        const jwtValue = getCookie("jwt");
        const updatePaymentEndpoint = `${process.env.REACT_APP_BACKEND_URL}/globe-payment/update_payment`;
        const sendJson = {
            shipmentNum: shipmentNum,
            payment_record_id: payment_record_id,
        }
        try {
            // Send data to your Django backend to generate a signature
            const updatePaymentResponse = await axios.post(updatePaymentEndpoint, sendJson, {
                headers: { Authorization: `Bearer ${jwtValue}` } 
            });
            if (updatePaymentResponse.status === 200) {
                // If the request is successful, set the signature to state
                setActivePage(4);
            } else {
                setShowMsg(t("SystemFailedMsg"));
                setActivePage(13);
            }
        } catch (error) {
            setShowMsg(t("SystemFailedMsg"));
            setActivePage(13);
        }

    }



    return (
        <>
            {activePage === 8 &&  <LoadingBlock />}

            {activePage === 5 &&  <ResultPage2 />}

            {activePage === 4 &&  <ResultPage ShipmentNum={data!!.shipmentNum} customPKey={data!!.customer_pkey} />}

            {activePage === 13 && <MessagePage msg={showMsg} />}
        </>
    );
        
}

export default GlobePaymentPage;


export const getCookie = (name: string): string | undefined => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';'); // Split document.cookie on semicolons
    for(let i=0;i < ca.length;i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1,c.length); // Trim leading whitespace
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length); // Check if the cookie's name matches and return its value
    }
    return undefined; // Return undefined if the cookie is not found
  };