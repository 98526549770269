
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingBlock from '../page/Block/LoadingBlock';
import ResultPage2 from '../page/Block/ResultPage2';
import ResultPage from '../page/Block/ResultPage';
import axios, { AxiosRequestConfig } from 'axios';
import Button from '@mui/material/Button';
import { useLocation, useParams } from 'react-router-dom';
import React from 'react';
import { Update } from '@mui/icons-material';
import MessagePage from '../page/Block/messagePage';
import { getCookie } from './GlobePayment';


interface ReconResponse {
  shipmentNum: string,
  payment_record_id:string,
  result: string,
  exp: string,
}


function Recon(): JSX.Element {
    const urlParams = new URLSearchParams(window.location.search);
    const [FirstLoad,setFirstLoad] = useState(true);
    const [showMsg, setShowMsg] = useState("");

    const [data, setData] = useState<ReconResponse | null>(null);

    //Page Control
    const [activePage,setActivePage] = useState(0);
    //Page Control

    // Get the value of a specific parameter

    const token = urlParams.get('token');
    const { t } = useTranslation();
    const params = useParams();

    let language :string = params.language === 'zh' ? "zh" : "en";


    useEffect(() => {
        if(FirstLoad){
          setActivePage(8);
          fetch(`${process.env.REACT_APP_BACKEND_URL}/getJWTResult?token=${token}`)
          .then(response => {
              // Check if response is ok (status code 200-299)
              if (!response.ok) {
                  throw new Error('Network response was not ok');
                  setActivePage(5);
              }
              return response.json();
          })
          .then(jsonData => {
              // Set the jsonData to state
              setData(jsonData);
              
              // Example: Based on the "result" value in jsonData, you might set active page
              if (jsonData.result === 'success') {
                sendPaySuccess(jsonData.shipmentNum,jsonData.payment_record_id);
              } else {
                setActivePage(5);
              }
          })
          .catch(error => {
              console.error('There was a problem with the fetch operation:', error.message);
              setActivePage(5);
          });
          setFirstLoad(false);
        }
      });



      const sendPaySuccess = async (shipmentNum: string,payment_record_id:string) => {
        const jwtValue = getCookie("jwt");
        const updatePaymentEndpoint = `${process.env.REACT_APP_BACKEND_URL}/globe-payment/update_payment`;
        const sendJson = {
            shipmentNum: shipmentNum,
            payment_record_id: payment_record_id,
        }
        console.log(sendJson);
        console.log(jwtValue);
        try {
            // Send data to your Django backend to generate a signature
            const updatePaymentResponse = await axios.post(updatePaymentEndpoint, sendJson, {
                headers: { Authorization: `Bearer ${jwtValue}` } 
            });
            if (updatePaymentResponse.status === 200) {
                // If the request is successful, set the signature to state
                setActivePage(4);
            } else {
                setShowMsg(t("SystemFailedMsg"));
                setActivePage(13);
            }
        } catch (error) {
            setShowMsg(t("SystemFailedMsg"));
            setActivePage(13);
        }

    }

    return (
        <>
            {activePage === 8 &&  <LoadingBlock />}

            {activePage === 5 &&  <ResultPage2 />}

            {activePage === 4 &&  <ResultPage ShipmentNum={data!!.shipmentNum} customPKey='' />}

            {activePage === 13 && <MessagePage msg={showMsg} />}
        </>
      );
        
}

export default Recon;


