import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Outlet } from 'react-router-dom';


import {
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import MainForm from './page/MainForm';


function App() {
  let navigate = useNavigate();
  


  return (
    <>

      <div className='container'>

          <Outlet/>
 
      </div>
  </>
  );
}

export default App;
