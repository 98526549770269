import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import React, { useCallback, useEffect, useState } from 'react';
import { Container, Row, Col, Button, Alert } from "react-bootstrap";
//import Paypal from '../../../component/PaypalForC0501';
import { AutoSKUPromoCodeRequest, AutoSKUPromoCodeResponse, IFormSixFormVal, IPromoCode, JSONTypePackages, NewAutoSKUPromoCodeResponse, Quotation0910Data } from '../../MainForm';

import ConsignmentList from './FormItem/ConsignmentList';
import { useTranslation } from 'react-i18next';
import PriceListItem from './FormItem/PriceListItem';
import axios from 'axios';
import AlertComponent from '../BlockAlert/AlertComponent';
import GiftProduct from "../../../image/gift_product.jpeg";




function SixthPage(props: IFormSixFormVal) {
  //lan
  const { t } = useTranslation();

  const Terms1Label = <div className='font-size-5'>{t("Terms1LabelPart1F6")}
    <a className='color-red' target='_blank' href={t("ConsignmentServiceAgreementPDF")!!}>{t("Terms1LabelPart2F6")}</a>
    {t("Terms1LabelPart3F6")}<a className='color-red' target='_blank' href={t("TermOfServicePDF")!!}>{t("Terms1LabelPart4F6")}</a>{t("Terms1LabelPart5F6")}
    <a className='color-red' target='_blank' href={t("PrivacyPolicyPDF")!!}>{t("Terms1LabelPart6F6")}</a>{t("Terms1LabelPart7F6")}</div>;

  const [totalKG, setTotalKG] = useState(0.0);
  const [totalKGPrice65, setTotalKGPrice65] = useState(0.0);
  const [totalKGPrice92, setTotalKGPrice92] = useState(0.0);

  const [totalKGPrice65First, setTotalKGPrice65First] = useState(0.0);
  const [totalKGPrice65Second, setTotalKGPrice65Second] = useState(0.0);
  const [FirstKG, setFirstKG] = useState(0.0);
  const [SecondKG, setSecondKG] = useState(0.0);

  // const [FirstLoad, setFirstLoad] = useState(true);
  const [insuranceCom, setInsuranceCom] = useState(<></>);

  let shipment_period = parseInt(props.shipment_number.substring(1, 7));

  const [SKUPromoCodeTitle,setSKUPromoCodeTitle] = useState("");

  
  useEffect (() => {
    if(shipment_period < 250401){
      if (props.SKUPromoCodeList != null) {
        let SKUStr = "";
        props.SKUPromoCodeList!!.matching_promo_codes.map((value, index) => {
          SKUStr = SKUStr + value.promo_code_detail + "-";
        });
        setSKUPromoCodeTitle(SKUStr.substring(0, SKUStr.length - 1));
      }
    }
  },[props.SKUPromoCodeList]);
  

  let Price1Title = t("ShippingFee3F6") + totalKG + " kg)"/*t("ShippingFee2F6") + props.jsonData.packages.length*/;

  let Tax1Title = props.sendLocal === 'UK' ? t("Tax1TitleUK") : (props.sendLocal === 'CA' ? t("Tax1TitleCA") : t("Tax1TitleAU"));

  let Tax2Title = props.sendLocal === 'UK' ? t("Tax2TitleUK") : (props.sendLocal === 'CA' ? t("Tax2TitleCA") : t("Tax2TitleAU"));

  let Tax3Title = t("Tax3TitleCA");

  let GetOrderDate = Number(props.shipment_number.substring(1, 7));
  if (isNaN(GetOrderDate)) {
    GetOrderDate = 0; 
  }

  let overCharge1F6 = 0;
  let overCharge2F6 = 0;

  useEffect(() => {
    
    if (props.pageSixFirstLoad) {
      CalcTotalKG();
      props.setPageSixFirstLoad(false);
    }
  });



  const CalcTotalKG = () => {

    let SaveTotalKGPrice65 = 0;
    let SaveTotalKGPrice65First = 0;
    let SaveTotalKGPrice65Second = 0;
    let SaveTotalKGPrice92 = 0;
    let SaveTotalKG = 0;
    let SaveAutoKG = 0;

    
    // let FarPostalCodeFee = 0;
    props.jsonData.packages.map((value, index) => {
      let KGunit = value.weight.unit;
      let CMunit = value.dimension.unit;
      let rawKG = 0.0;
      let cmToKG = 0.0;

      //CheckOverCharge
      if(shipment_period < Quotation0910Data){
        CheckOverCharge(value);
      }else{
        NewCheckOverCharge(value);
      }
      //CheckOverCharge

      switch (KGunit) {
        case "kg":
          rawKG = value.weight.value;
          break;
        case "g":
          rawKG = value.weight.value / 1000;
          break;
        default:
          console.log("Error KG!");
          break;
      }
      switch (CMunit) {
        case "cm":
          cmToKG = value.dimension.length * value.dimension.width * value.dimension.height;
          break;
        case "m":
          cmToKG = value.dimension.length * 100 * value.dimension.width * 100 * value.dimension.height * 100;
          break;
        default:
          console.log("Error CM!");
          break;
      }

      

      if(props.selectedPaymentMethodF3 != 4 && props.selectedPaymentMethodF3 != 5){ // 2024-06-17
        let RealKG = 0;
        if(shipment_period < Quotation0910Data){
          RealKG = rawKG >= cmToKG / 5000 ? rawKG : cmToKG / 5000;
        }else{
          RealKG = rawKG;
        }
        SaveTotalKG = (Math.round((SaveTotalKG + RealKG) * 100) / 100);
        setTotalKG(SaveTotalKG);
        let FirstKG = SaveTotalKG >= 15 ? 15 : SaveTotalKG;
        let SecondKG = SaveTotalKG >= 15 ? SaveTotalKG - 15 : 0;
        FirstKG = FirstKG % 0.5 === 0 ? FirstKG : (FirstKG - (FirstKG % 0.5) + 0.5);
        SecondKG = SecondKG % 0.5 === 0 ? SecondKG : (SecondKG - (SecondKG % 0.5) + 0.5);

        setFirstKG(FirstKG);
        setSecondKG(SecondKG);

        SaveAutoKG = FirstKG + SecondKG;

        props.IFsetFinalKGF6(FirstKG + SecondKG);

        //Calc by 65
        SaveTotalKGPrice65 = Math.round((totalKGPrice65 + FirstKG * props.membership_level + SecondKG * props.membership_after15kg) * 100) / 100;
        setTotalKGPrice65(SaveTotalKGPrice65);
        setTotalKGPrice65First(Math.round(totalKGPrice65First + FirstKG * props.membership_level));
        setTotalKGPrice65Second(Math.round(totalKGPrice65Second + SecondKG * props.membership_after15kg));
        props.IFsetFinalPrice65((Math.round((SaveTotalKGPrice65) * 100) / 100));

        //Calc by 92
        SaveTotalKGPrice92 = Math.round((totalKGPrice65 + FirstKG * 90 + SecondKG * 88) * 100) / 100;
        setTotalKGPrice92(SaveTotalKGPrice92);
        props.IFsetFinalPrice92((Math.round((SaveTotalKGPrice92) * 100) / 100));

      }else{

        let RealKG = rawKG;
        SaveTotalKG = (Math.round((SaveTotalKG + RealKG) * 100) / 100);
        setTotalKG(SaveTotalKG);
        let FirstKG = SaveTotalKG >= 8 ? 8 : SaveTotalKG;
        let SecondKG = SaveTotalKG >= 8 ? SaveTotalKG - 8 : 0;
        FirstKG = FirstKG % 0.5 === 0 ? FirstKG : (FirstKG - (FirstKG % 0.5) + 0.5);
        SecondKG = SecondKG % 0.5 === 0 ? SecondKG : (SecondKG - (SecondKG % 0.5) + 0.5);

        setFirstKG(FirstKG);
        setSecondKG(SecondKG);

        SaveAutoKG = FirstKG + SecondKG;

        props.IFsetFinalKGF6(FirstKG + SecondKG);

        //Calc by 65
        SaveTotalKGPrice65 = Math.round((totalKGPrice65 + props.membership_level + SecondKG * props.membership_after15kg) * 100) / 100;
        setTotalKGPrice65(SaveTotalKGPrice65);
        setTotalKGPrice65First(Math.round(totalKGPrice65First + props.membership_level));
        setTotalKGPrice65Second(Math.round(totalKGPrice65Second + SecondKG * props.membership_after15kg));
        props.IFsetFinalPrice65((Math.round((SaveTotalKGPrice65) * 100) / 100));

        //Calc by 92 
        SaveTotalKGPrice92 = Math.round((totalKGPrice65 + props.membership_level + SecondKG * 88) * 100) / 100;
        setTotalKGPrice92(SaveTotalKGPrice92);
        props.IFsetFinalPrice92((Math.round((SaveTotalKGPrice92) * 100) / 100));
      }
    })

    let insurance = 0;
    if (props.insuranceF3) {
      insurance = Math.round(props.totalPriceF3 * 3) / 100;

      setInsuranceCom(<PriceListItem itemSubTitle='' itemTitle={t("InsuranceF6")} itemPrice={insurance} itemColor='' />);

    }

    props.IFsetInsuranceVal(insurance);

    //Far Postal Code Fee

    // if(props.far250fee){
    //   FarPostalCodeFee = 250;
    // }else{
    //   FarPostalCodeFee = 0;
    // }
    // props.IFsetFarPostalCodeFee(FarPostalCodeFee)
    //Far Postal Code Fee

    // if (props.firstLoadPage6) {
      props.setOverCharge1(overCharge1F6);
      props.setOverCharge2(overCharge2F6);

      let GetOrderDate = Number(props.shipment_number.substring(1, 7));
      
      if(GetOrderDate >= 250401){
        NewAutoCallSKUPromoCode(SaveAutoKG).then(({ totalMinus,totalMinusKG}) => {
          // Now that you have AutoCallSKUMinus, you can use it
          if(props.promoObjF5 == null){
            NewAutoCallPromoCode(SaveTotalKGPrice65, SaveAutoKG, totalMinus,totalMinusKG);
          }  
          props.setSKUPromoPriceF6(totalMinus);
          setSKUPromoCodeTitle(`${t('SKUPromoCodeTitleP6')} - ${totalMinusKG}KG`);
          // Any other code that depends on AutoCallSKUMinus should go here
        });
      }else{
        AutoCallSKUPromoCode(SaveAutoKG).then(({ totalMinus, getSKUPromoCodeList }) => {
          // Now that you have AutoCallSKUMinus, you can use it
          if(props.promoObjF5 == null){
            AutoCallPromoCode(SaveTotalKGPrice65, SaveAutoKG, totalMinus,getSKUPromoCodeList);
          }  
          props.setSKUPromoPriceF6(totalMinus);
          // Any other code that depends on AutoCallSKUMinus should go here
        });
      }
    //   props.IFsetFirstLoadPage6();
    // }
  }

  const SetpointDiscountMax = () => {
    let pointDiscountMax = parseInt((props.customPoint / 20  < (totalKGPrice65First + totalKGPrice65Second) ? props.customPoint / 20 : (totalKGPrice65First + totalKGPrice65Second)).toString());
    props.IFsetPointDiscountH(pointDiscountMax);
  }

  const NewAutoCallSKUPromoCode = async (finalKG: number) => {
    let totalMinus = 0;
    let totalMinusKG = 0;
  
    try {
      const postData: AutoSKUPromoCodeRequest = {
        shipid: props.shipment_number, // Ensure props.shipment_number is defined and available
        TaxType: props.selectedPaymentMethodF3, // Ensure props.selectedPaymentMethodF3 is defined and available
        FinalKG: finalKG,
      };
  
      const res = await axios.post<NewAutoSKUPromoCodeResponse>(`${process.env.REACT_APP_BACKEND_URL}/NewAutoSKUPromoCode`, postData);
  
      // Extracting the data from the response
      if (res.data) {
        totalMinus = res.data.getDiscount;
        totalMinusKG = res.data.getDiscountKG;
      }
    } catch (err) {
      console.error('Error calling AutoSKUPromoCode:', err);
    }
  
    return { totalMinus, totalMinusKG };
  };


  const NewAutoCallPromoCode = async (totalKGPrice65Value: number, FinalKGValue: number,AutoCallSKUMinus:number,AutoCallSKUMinusKG:number) => {
    try {
      let AfterMinusBySKU = (totalKGPrice65Value - AutoCallSKUMinus);
      let AfterMinusBySKUKG = FinalKGValue - AutoCallSKUMinusKG;

      const postData = {
        shipid: props.shipment_number,
        FinalKG: FinalKGValue,
        TotalPrice: AfterMinusBySKU,
        TaxType: props.selectedPaymentMethodF3,
        TotalPriceKG: AfterMinusBySKUKG,
      };

      const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/NewAutoPromoCode`, postData);
      const JsonData: IPromoCode = res.data.data;
      props.IFsetAutoPromoObjF5(JsonData);
      const discountPer: number = JsonData.promo_code_multi;
      const discountMoney: number = JsonData.promo_code_minus;
      const discountKG: number = JsonData.promo_code_minus_kg;
      
      const couponPoint: number = JsonData.promo_code_point;
      props.IFsetAutoPromoPriceF6((AfterMinusBySKU) - ((AfterMinusBySKU - discountMoney - discountKG) * discountPer));
      props.setPromoPoint(couponPoint);
    } catch (err) {
      props.IFsetAutoPromoObjF5(null);
      props.IFsetAutoPromoPriceF6(0);
      props.setPromoPoint(0);
      console.error(err);
    }
  };



  const AutoCallSKUPromoCode = async (finalKG:number) => {
    let totalMinus = 0;
    let getSKUPromoCodeList: AutoSKUPromoCodeResponse = {matching_promo_codes:[]};
    try {
      const postData: AutoSKUPromoCodeRequest = {
        shipid: props.shipment_number,
        TaxType: props.selectedPaymentMethodF3,
        FinalKG: finalKG,
      };
  
      const res = await axios.post<AutoSKUPromoCodeResponse>(`${process.env.REACT_APP_BACKEND_URL}/AutoSKUPromoCode`, postData);
  
      // Assuming you want to do something with the response data
      if (res.data && res.data.matching_promo_codes.length > 0) {
        getSKUPromoCodeList = res.data;
        props.setSKUPromoCodeList(res.data);

        if(props.selectedPaymentMethodF3 == 4 || props.selectedPaymentMethodF3 == 5){
          let MaxQty = finalKG - 8 > 0 ? finalKG - 8 : 0; 
          
          let cumulativeKgQtyMinus = 0;

          getSKUPromoCodeList.matching_promo_codes.forEach(code => {
            let adjustedKgMinus = code.promo_code_minus_kg;
            let adjustedKgQtyMinus = code.promo_code_minus_kg_qty;

            // Calculate the new cumulative minus kg qty
            cumulativeKgQtyMinus += code.promo_code_minus_kg_qty;
            

            // If cumulative minus kg qty exceeds MaxQty, adjust the current code's minus kg and kg qty
            if (cumulativeKgQtyMinus > MaxQty) {
              adjustedKgQtyMinus = code.promo_code_minus_kg_qty - (cumulativeKgQtyMinus - MaxQty);
              adjustedKgMinus = (adjustedKgQtyMinus / code.promo_code_minus_kg_qty) * code.promo_code_minus_kg;
              cumulativeKgQtyMinus = MaxQty; // Ensure cumulative does not exceed MaxQty
            }

            // Update totalMinus with the adjusted values
            totalMinus += code.promo_code_minus + adjustedKgMinus;
            props.setCumulativeKgQtyMinus(cumulativeKgQtyMinus);
          });
        }else{
          totalMinus = res.data.matching_promo_codes.reduce((accumulator, currentCode) => accumulator + currentCode.promo_code_minus + currentCode.promo_code_minus_kg, 0);
        }
      } else {
        console.log('No matching promo codes found.');
      }
    } catch (err) {
      console.error('Error calling AutoSKUPromoCode:', err);
      // Depending on your error handling strategy, you might want to return or throw here
    }
    //console.log('getSKUPromoCodeList:', getSKUPromoCodeList);

    return {totalMinus,getSKUPromoCodeList};
  };

  const AutoCallPromoCode = async (totalKGPrice65Value: number, FinalKGValue: number,AutoCallSKUMinus:number,SKUPromoCodeList:AutoSKUPromoCodeResponse) => {
    try {
      const AfterMinusBySKU = (totalKGPrice65Value - AutoCallSKUMinus);



      const postData = {
        shipid: props.shipment_number,
        FinalKG: FinalKGValue,
        TotalPrice: AfterMinusBySKU,
        TaxType: props.selectedPaymentMethodF3,
        SKUPromoCodeList: SKUPromoCodeList.matching_promo_codes,
      };

      const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/AutoPromoCode`, postData);
      const JsonData: IPromoCode = res.data.data;
      props.IFsetAutoPromoObjF5(JsonData);
      const discountPer: number = JsonData.promo_code_multi;
      const discountMoney: number = JsonData.promo_code_minus;
      const discountKG: number = JsonData.promo_code_minus_kg;
      
      const couponPoint: number = JsonData.promo_code_point;
      props.IFsetAutoPromoPriceF6((AfterMinusBySKU) - ((AfterMinusBySKU - discountMoney - discountKG) * discountPer));
      props.setPromoPoint(couponPoint);
    } catch (err) {
      props.IFsetAutoPromoObjF5(null);
      props.IFsetAutoPromoPriceF6(0);
      props.setPromoPoint(0);
      console.error(err);
    }
  };


  const CheckOverCharge = (
    value: JSONTypePackages
  ) => {
    
    let rawKG = 0;

    // Convert weight to kg
    switch (value.weight.unit) {
      case "kg":
        rawKG = value.weight.value;
        break;
      case "g":
        rawKG = value.weight.value / 1000;
        break;
      default:
        console.log("Error KG!");
        break;
    }

    const dimensionsInCM = [value.dimension.height, value.dimension.length, value.dimension.width].map(d => value.dimension.unit === "m" ? d * 100 : d);
    const sortedDimensions = dimensionsInCM.sort((a, b) => b - a);

    const longestDimension = sortedDimensions[0];
    const secondLongestDimension = sortedDimensions[1];
    

    const girth = value.dimension.length * (value.dimension.unit === "m" ? 100 : 1) 
    + 2 * value.dimension.width * (value.dimension.unit === "m" ? 100 : 1) 
    + 2 * value.dimension.height * (value.dimension.unit === "m" ? 100 : 1);
    
    if(props.selectedPaymentMethodF3 >= 4){
      overCharge1F6 = 0;
      overCharge2F6 = 0;
    }else if(props.sendLocal === 'UK'){
      let exceeds76cm = secondLongestDimension > 76 && longestDimension > 76;
      let newCharge = longestDimension > 121 || exceeds76cm || girth > 266 || rawKG > 31 ? 275 : 0;
      overCharge1F6 = Math.max(overCharge1F6, newCharge);
    }else if (props.sendLocal === 'AU') {
      let newCharge = longestDimension > 121 || secondLongestDimension > 76 || girth > 266 || rawKG > 31 ? 275 : 0;
      overCharge1F6 = Math.max(overCharge1F6, newCharge);
    } else if (props.sendLocal === 'CA') {
      let newCharge1 = longestDimension > 121 || secondLongestDimension > 76 || girth > 266 ? 550 : 0;
      let newCharge2 = rawKG > 31 ? 950 : 0;
      overCharge1F6 = Math.max(overCharge1F6, newCharge1);
      overCharge2F6 = Math.max(overCharge2F6, newCharge2);
    }
    
  };

  const NewCheckOverCharge = (
    value: JSONTypePackages
  ) => {

    let rawKG = 0;

    // Convert weight to kg
    switch (value.weight.unit) {
      case "kg":
        rawKG = value.weight.value;
        break;
      case "g":
        rawKG = value.weight.value / 1000;
        break;
      default:
        console.log("Error KG!");
        break;
    }

    const dimensionsInCM = [value.dimension.height, value.dimension.length, value.dimension.width].map(d => value.dimension.unit === "m" ? d * 100 : d);
    const sortedDimensions = dimensionsInCM.sort((a, b) => b - a);

    const longestDimension = sortedDimensions[0];
    const secondLongestDimension = sortedDimensions[1];
    const shortestDimension = sortedDimensions[2];
    
    
    if(props.selectedPaymentMethodF3 >= 4){
      overCharge1F6 = 0;
      overCharge2F6 = 0;
    }else if(props.sendLocal === 'UK'){
      let checkSize1 = longestDimension > 65;
      let checkSize2 = secondLongestDimension > 45;
      let checkSize3 = shortestDimension > 45;
      let OverCount = 0;
      if(checkSize1){OverCount++;};
      if(checkSize2){OverCount++;};
      if(checkSize3){OverCount++;};

      let newCharge = OverCount >= 2 || rawKG > 20 ? 275 : 0;
      overCharge1F6 = Math.max(overCharge1F6, newCharge);
    }
    
  };

  let promoTitle = t("PromoCodeF6") + (props.promoObjF5 != null ? props.promoObjF5!!.promo_code_detail ?? "" : "");

  let autoPromoTitle = t("AutoPromoCodeF6") + (props.autoPromoObjF5 != null ? props.autoPromoObjF5!!.promo_code_detail ?? "" : "");

  let FirstKGStr = FirstKG > 0 ? props.membership_level_str + " - " + FirstKG + "kg x $" + (props.membership_level / 2) + "/0.5kg" : "";
  if(props.selectedPaymentMethodF3 == 4 || props.selectedPaymentMethodF3 == 5){
    FirstKGStr =  props.membership_level_str;
  }

  return (
    <div className="">
        


      <Container>
        <Row>
          <Col xs="12" md="12" lg="12" className='non-p  mt-3'>
            <h5 className='text-start fw-bold text-decoration-underline'><span className=''>{t("SubTitle1F6")}</span><span className='float-end text-decoration-underline'>{t("SubTitle2F6")}</span></h5>
            <hr />
            <PriceListItem itemSubTitle='' itemTitle={Price1Title} itemPrice={null} itemColor='' />

            {FirstKG > 0 && <PriceListItem itemSubTitle='' itemTitle={FirstKGStr} itemPrice={totalKGPrice65First} itemColor='' />}

            {SecondKG > 0 && <PriceListItem itemSubTitle='' itemTitle={props.membership_level_str + " - " + SecondKG + "kg x $" + (props.membership_after15kg / 2) + "/0.5kg"} itemPrice={totalKGPrice65Second} itemColor='' />}

            {/* {props.far250fee && <PriceListItem itemTitle={t("250FreeF6")} itemPrice={props.farPostalCodeFee} itemColor='color-red' />} */}

            {/* {props.SKUPromoCodeList && props.SKUPromoCodeList.matching_promo_codes.length > 0 && props.SKUPromoCodeList.matching_promo_codes.map((promoCode, index) => (
                <PriceListItem
                  key={index}
                  itemTitle={`${promoCode.promo_code_string} - ${promoCode.promo_code_detail}`}
                  itemPrice={-promoCode.promo_code_minus}
                  itemColor=""
                />
              ))
            } */}

            {props.SKUPromoPriceF6 > 0 && <PriceListItem itemTitle={SKUPromoCodeTitle} itemSubTitle='' itemPrice={-props.SKUPromoPriceF6} itemColor='' />}

            {props.promoObjF5 != null && <PriceListItem itemTitle={promoTitle} itemSubTitle='' itemPrice={-props.promoPriceF6} itemColor='' promoPoint={props.promoPoint} />}

            {props.autoPromoObjF5 != null && <PriceListItem itemTitle={autoPromoTitle} itemSubTitle='' itemPrice={-props.autoPromoPriceF6} itemColor='' promoPoint={props.promoPoint} />}

            {insuranceCom}

            {props.selectedPaymentMethodF3 == 1 && props.tax1F6 > 0 && <PriceListItem itemSubTitle='' itemTitle={`${Tax1Title} ${props.totalPriceF3} x ${props.tax1Rate}%`} itemPrice={props.tax1F6} itemColor='' />}

            {props.selectedPaymentMethodF3 == 1 && props.tax2F6 > 0 && <PriceListItem itemSubTitle='' itemTitle={`${Tax2Title} ${props.totalPriceF3} x ${props.tax2Rate}%`} itemPrice={props.tax2F6} itemColor='' />}

            {props.selectedPaymentMethodF3 == 1 && props.tax3F6 > 0 && <PriceListItem itemSubTitle='' itemTitle={`${Tax3Title} ${props.totalPriceF3} x ${props.tax3Rate}%`} itemPrice={props.tax3F6} itemColor='' />}

            {props.pointDiscount > 0 && <PriceListItem itemSubTitle='' itemTitle={`${t('CustomPointUseTitle')} : ${props.pointDiscount * 20}`} itemPrice={-props.pointDiscount} itemColor='' />}

            {props.overCharge1 > 0 && 
                <PriceListItem 
                    itemSubTitle=''
                    itemTitle={t(props.sendLocal === 'CA' ? "OverCharge1CATitleF6" : "OverCharge1TitleF6")} 
                    itemPrice={props.overCharge1} 
                    itemColor='' 
                />
            }

            {props.overCharge2 > 0 && props.sendLocal == 'CA' && <PriceListItem itemSubTitle='' itemTitle={t("OverCharge2CATitleF6")} itemPrice={props.overCharge2} itemColor='' />}

            {/* {(shipment_period >= 240304 && shipment_period <= 240324) && 
              <div className='text-align-left'>
                  <PriceListItem itemTitle={t("GiftProductTitleF6")} itemPrice={0} itemColor='' itemSubTitle={t("GiftProductSubTitleF6")} />
                  <img src={GiftProduct} className='img-fluid-shy' />
              </div>
            } */}

            <h4 className='text-end color-green fw-bold'>{t("TotalPriceLabelF6")} <span className='text-decoration-underline'>{t("PriceCurrency1F6")} {
              (Math.round((props.finalPrice65 + props.insuranceVal - props.SKUPromoPriceF6 - props.promoPriceF6 - props.autoPromoPriceF6 /*+ props.farPostalCodeFee*/ + props.tax1F6 + props.tax2F6 + props.tax3F6 + props.overCharge1 + props.overCharge2 - props.pointDiscount) * 100) / 100) >= 0
                ? (Math.round((props.finalPrice65 + props.insuranceVal - props.SKUPromoPriceF6 - props.promoPriceF6 - props.autoPromoPriceF6 /*+ props.farPostalCodeFee*/ + props.tax1F6 + props.tax2F6 + props.tax3F6 + props.overCharge1 + props.overCharge2 - props.pointDiscount) * 100) / 100).toLocaleString()
                : "0"
            }
              {t("PriceCurrency2F6")}</span></h4>

            <div className='float-start'>
              <label htmlFor="pointDiscount">{t('CustomPointUseTitle')} {props.pointDiscount * 20} {t('CustomPointUseSubTitle')} HKD : </label>
              <input
                type="number"
                id="pointDiscount"
                name="pointDiscount"
                className='ms-2'
                value={props.pointDiscount === 0 ? '' : props.pointDiscount}
                onChange={props.IFsetPointDiscount}
                min="0"
                max={props.customPoint / 20  < (totalKGPrice65First + totalKGPrice65Second) ? props.customPoint / 20 : (totalKGPrice65First + totalKGPrice65Second)}
              />
              <Button  className='ms-4 backcolor-41b8a2' onClick={() => {SetpointDiscountMax()}}>{t("Max")}</Button>{' '}
            </div>

            <div className='color-red text-end'>
              <FormControlLabel
                value="start"
                control={<Checkbox
                  checked={props.showCancelBoxP6}
                  onChange={props.handleCheckboxChangeP6}
                />}
                label={t('CancelBoxF6')}
                labelPlacement="start"
                style={{ textDecoration: 'underline' }}
              />
            </div>

            {props.showCancelBoxP6 && <Alert variant="info" className="text-start p-4" onClose={() => props.IFsetHideMsgBoxP6()} dismissible>
              <div>
                <div className=''>{t('CancelAlertTitleF6')}</div>
                <div className='mt-3'>{t('CancelContactNoTitleF6')}<u>{t('CancelContactNoF6')}</u></div>
                <span className=''>{t('CancelWhatsappTitleF6')}<u>{t('CancelWhatsappF6')}</u></span>
                <a href='https://wa.me/85293111291'>{t('CancelWhatsappClickF6')}</a><span className='color-dodgerblue font-size-t7'>{t('CancelWhatsappClickSubF6')}</span>
              </div>
            </Alert>}

            <div className='text-start'>

              <div>

                {/* {
                  isWithinRange(props.jsonData.request.create_on) ? (
                    <>
                      <div className='float-end'>
                        <img src='/image/Banner_alright_mooncake.jpg' className='img-fluid mobile-image' style={{ width: 150, height: 150 }} />
                        <img src='/image/Banner_alright_mooncake_desk.jpg' className='img-fluid desktop-image' style={{ width: 350, height: 100 }} />
                      </div>
                    </>
                  )
                    : null
                } */}


                {props.sendLocal === 'UK' && (
                  <div className='color-red mt-3'>
                    {t("PriceTips3LabelF6")}
                  </div>
                )}

                {
                  shipment_period < Quotation0910Data
                  ? <div className='color-gray mt-2'>{t("PriceTips1LabelF6")}</div>
                  : <div className='color-gray mt-2'>{t("NewPriceTips1LabelF6")}</div>
                }
                
                
                {
                  shipment_period < Quotation0910Data
                  ? <div className='color-gray mt-2'>
                  {props.selectedPaymentMethodF3 ==4 ? t("SeaPriceTips2LabelF6") :
                    t(
                      props.sendLocal === 'UK' ? "PriceTips2LabelF6" :
                        (props.sendLocal === 'CA' ? "PriceTips2LabelF6CA" : "PriceTips2LabelF6AU")
                    )
                  }
                </div>
                : <div className='color-gray mt-2'>{t("NewPriceTips2LabelF6")}</div>
                }

                { shipment_period < Quotation0910Data
                  ? <div className='color-gray mt-2'>
                    {t((props.sendLocal === 'CA' ? "PackageLimitLabel1CAF6" : "PackageLimitLabel1F6"))}
                  </div>
                  : <div className='color-gray mt-2'>{t("NewPackageLimitLabel1F6")}</div>
                }
                {props.sendLocal === 'CA' && <div className='color-gray mt-2'>
                  {t("PackageLimitLabel2CAF6")}
                </div>}

                {(props.selectedPaymentMethodF3 == 4 || props.selectedPaymentMethodF3 == 5) && <div className='color-gray mt-2'>
                  {t("SeaPackageLimitLabel2F6")}
                </div>}

              </div>

              <hr />

              <div className='color-gray mt-3'>{t("RemarksTitleF6")}</div>

              { shipment_period < Quotation0910Data
                    ? <div className='color-gray mt-2'>
                  {t(
                    props.sendLocal === 'UK' ? "Remarks1LabelF6" :
                      (props.sendLocal === 'CA' ? "Remarks1LabelF6CA" : "Remarks1LabelF6AU")
                  )}
                </div>
                : <div className='color-gray mt-2'>{t("NewRemarks1LabelF6")}</div>
              } 
             { shipment_period < Quotation0910Data
                ? <div className='color-gray mt-2'>
                  {t(
                    props.sendLocal === 'UK' ? "Remarks2LabelF6" :
                      (props.sendLocal === 'CA' ? "Remarks2LabelF6CA" : "Remarks2LabelF6AU")
                  )}
                </div>
                : <div className='color-gray mt-2'>{t("NewRemarks2LabelF6")}</div>
              }


              <FormControlLabel control={<Checkbox onChange={props.IFsetTerm1F6} disabled={props.showStripeForm} checked={props.term1F6} />} label={Terms1Label} className="mt-3  " />
              <FormControlLabel control={<Checkbox onChange={props.IFsetTerm2F6} disabled={props.showStripeForm} checked={props.term2F6} />} label={<div className='font-size-5'>{t("Terms2LabelF6")}</div>} className=" mb-3 " />

            </div>

          </Col>
        </Row>
      </Container>

    </div>
  );
}

export default SixthPage;

