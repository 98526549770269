import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import {Container, Row,Col} from "react-bootstrap";
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';



export interface IStatusComponent {
    statusTitle:string,
    statusContext:string,
    statusDate:string,
    statusCheck:boolean,
    statusDetail:string,
}


function StatusComponent(props:IStatusComponent) {
    //lan
    const { t } = useTranslation();
    
    let getDate = props.statusDate;

    let date: Date | null = null;
    let displayDate1 = "";
    let displayDate2 = "";
    if(getDate !== "" && getDate !== "----/----/----"){
        date= new Date(getDate);
        let weekday = date.toLocaleString(t('StatusWeekday').toString(), {  weekday: 'long' });
        //get day of month and month and year ;
        let day = date.getDate();
        let month = date.getMonth()+1;
        let year = date.getFullYear();
        displayDate1 = weekday + " , " + day + "/" + month + "/" + year;
        let getHours = date.getHours() > 12 ? date.getHours() - 12: date.getHours();
        let getAMPM = date.getHours() > 12 ? t('StatusAM'):t('StatusPM');
        displayDate2 = getHours + ":" + date.getMinutes().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}) + " " + getAMPM;
    }else{
        displayDate2 = getDate;
    }

    let checkcss = "non-p ps-3 border-bottom border-dark fw-bold ";
    checkcss += props.statusCheck ? " color-red" : " color-gray";
    return (
        <tr>
            <td className={checkcss} width="45%"> 
                <div className='font-size-t4 '>{displayDate1 === ""?"\n":displayDate1}</div>
                <div className='text-end font-size-t4 mb-2'>{displayDate2 === ""?"\n":displayDate2}</div> 
            </td>
            <td className="text-center" width="10%" > 
                <Checkbox  icon={<RadioButtonUnchecked />} checkedIcon={<RadioButtonCheckedIcon color="success"/>} checked={props.statusCheck} />
            </td>
            <td className="ps-3 pe-3 border-bottom border-dark" width="45%"> 
                <div className='text-start fw-bold font-size-t4'>{props.statusTitle}</div> 
                <div className='text-start fw-bold color-gray font-size-t4 mb-2'>{props.statusContext}</div> 
                <div className='text-start fw-bold color-gray font-size-t7 mb-2'>{props.statusDetail}</div> 
            </td>
        </tr>
    );
}

export default StatusComponent;

