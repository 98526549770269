

import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import axios, { AxiosRequestConfig } from 'axios';
import RecordButton from '../../component/RecordButton';

export interface IFResultPage {
  ShipmentNum:string,
  customPKey:string,
}

function ResultPage(props:IFResultPage) {

  const { t } = useTranslation();

  const queryParameters = new URLSearchParams(window.location.search);
  const jwt = queryParameters.get("token");

  const IFToStatusPage = async () => {
    const axiosConfig: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${jwt}` }
    }

    await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/GenStatusToken`,
      { shipment_number: props.ShipmentNum },
      axiosConfig
    ).then((res) => {
      window.location.href = `/c0501/zh/p/shipmentstatus?token=${res.data.token}`;
    }).catch((err) => {
    });
  };

  return (
    <div className="">

                    <fieldset className="border radius-10 mt-5 text-start p-5 backcolor-gray">
                      <div className='text-start'>
                        <div className='next-line-if-lf fw-bold '>{t("PaySuccessMsg")}</div>

                      </div>
                    </fieldset>

                  {props.ShipmentNum !== '' &&
                    <div className='text-center'>
                      <Button variant="contained" color="info" className='float-center mt-5' onClick={() => {IFToStatusPage()}}>
                        {t("ToStatus")}
                      </Button>
                    </div>
                  }
   
                  {props.customPKey !== '' &&
                    <RecordButton custompKey={props.customPKey} />
                  }
  </div>
  );
}

export default ResultPage;

