import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { Container, FormGroup, Row, Col, Button } from "react-bootstrap";
import { IFormTwoFormVal, provinceCAList } from '../../MainForm';
import { useTranslation } from 'react-i18next';
import CustomAddress from './FormItem/CustomAddress';
import Autocomplete from '@mui/material/Autocomplete';
import { title } from 'process';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

function SecondPage(props: IFormTwoFormVal) {
  //lan
  const { t } = useTranslation();

  const [isOnCustomAddress, setIsOnCustomAddress] = useState(false);
  const [isOnMore, setIsOnMore] = useState(false);
  const [activeIndex, setActiveIndex] = useState(1);
  const [maxlength, setMaxlength] = useState(0);

  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(2);

  const setAddressNoteMinMax = (getActiveIndex: number) => {
    // let maxPage = Math.round(props.customAddressNoteF2.length / 3);
    if (getActiveIndex <= 0) {
      getActiveIndex = 1;
    } else if (getActiveIndex >= maxlength) {
      getActiveIndex = maxlength;
    }
    let min = (getActiveIndex - 1) * 3;
    let max = getActiveIndex * 3 - 1;
    setMinIndex(min);
    setMaxIndex(max);
    setActiveIndex(getActiveIndex);
  }

  useEffect(() => {
    setMaxlength(Math.round(props.customAddressNoteF2.length / 3) + 1);
  });

  // const far250freeContainer = <Container>
  //   <Row>
  //     <Col xs="6" md="6" lg="6" className='non-p'>
  //       <div className='color-red text-start'>{t("250FreeF2")}</div>
  //     </Col>
  //   </Row>
  // </Container>

  function translate(key: string): string {
    return t(key) as string; // or handle the type conversion here
  }

  return (
    <div className="App">

      <Container>
        <Row>
          <Col xs="12" md="12" lg="12" className='non-p'>

            <div className="float-stat text-start">
              <h3><span className='fw-bold mt-3 mb-3 p-1 color-white radius-5 backcolor-red'>{t("MainTitle2F2")}</span> {t("MainTitle1F2")}</h3>
              <h5 className='color-gray fw-bold mt-3 mb-3'>{t("SubTitle1F2")}</h5>
              <h5 className='color-gray fw-bold mt-3 mb-3'>{t("SubTitle2F2")}</h5>
            </div>

            {props.customAddressNoteF2.length > 0 && <div className='mt-3 text-start font-size-t4 fw-bold color-green mb-3 fw-bold underline'>
              {t('AddressNoteOffF2')}
            </div>}

            {/* {props.customAddressNoteF2.map((value,index) => {
                        return (isOnMore || index < 5) && <CustomAddress   key={index}
                                                customIndex={index}
                                                customAddress={value.contact_name!! + " , " + value.phone!! +  " , " +  value.address_line_1!!}
                                                IFsetSaveCustomAddress={props.IFsetSaveCustomAddress}
                                                IFsetSingeCustomAddress={props.IFsetSingeCustomAddress}
                                              />
                    })}

                  {props.customAddressNoteF2.length > 5 && !isOnMore &&
                  <div className="text-start">
                    <span className='mt-2  cursor-pointer' onClick={() => {setIsOnMore(true)}}>
                        { t('MoreF2')}
                    </span>
                  </div>}  */}

            <Container>
              <Row>

                {props.customAddressNoteF2.map((value, index) => {
                  return (index >= minIndex && index <= maxIndex) && <CustomAddress key={index}
                    customIndex={index}
                    customAddress={
                      value.contact_name!! + "\n"
                      + "聯絡電話 : " + value.area_code!! + " - " + + value.phone!! + "\n"
                      + "地址 : \n" + value.address_line_1!! + "\n"
                      + value.address_line_2!! + "\n"
                      + value.address_line_3!! + "\n"}
                    IFsetSaveCustomAddress={props.IFsetSaveCustomAddress}
                    IFsetSingeCustomAddress={props.IFsetSingeCustomAddress}
                  />
                })}

              </Row>
            </Container>
            {props.customAddressNoteF2.length > 3 && <div className='text-end'>
              {activeIndex > 1 && <Button variant="outline-secondary" className='float-start  mt-3  ' onClick={() => { setAddressNoteMinMax(activeIndex - 1) }}>{"🡄"}</Button>}
              {activeIndex < maxlength && <Button variant="outline-secondary" className=' mt-3 ' onClick={() => { setAddressNoteMinMax(activeIndex + 1) }}>{"🡆"}</Button>}
            </div>}
            {/* {props.customAddressNoteF2.length > 3 &&
                  <div className="text-start">
                    <span className='mt-2  ' >
                      {props.customAddressNoteF2.map((value,index) => {
                          let pageNum = Math.round(index / 3) + 1;
                          let setMin = (pageNum-1) * 3;
                          let setMax = (pageNum) * 3 - 1;
                          return (index % 3 === 0) && <span key={index}><span className='cursor-pointer' onClick={() => {setAddressNoteMinMax(setMin,setMax)}}>
                            {pageNum}
                            </span> , </span>
                      })}
                    </span>
                  </div>} */}

            <TextField
              className='visibility-hidden test-height'
              required
              fullWidth
              id=""
            />

            <div className=' text-start font-size-t4 fw-bold color-green mb-3 fw-bold underline'>
              {t('AddAddressNoteF2')}
            </div>

            <TextField
              className='mt-3'
              error={props.contactNameF2Err}
              required
              fullWidth
              id="contactNameF2"
              name="contactNameF2"
              label={t("contactNameLabelF2")}
              onChange={props.IFsetContactNameF2}
              value={props.contactNameF2}
              type='text'
              inputProps={{ maxLength: 127 }}
            />

            <Container>
              <Row>
                <Col xs="12" md="12" lg="6" className='non-p'>
                  <TextField

                    id="companyNameF2"
                    name="companyNameF2"
                    className='mt-3 cpe-2'
                    fullWidth
                    label={t("companyNameLabelF2")}
                    onChange={props.IFsetCompanyNameF2}
                    placeholder={t("companyNamePlaceholderF2") ?? ""}
                    value={props.companyNameF2}
                    type='text'
                    inputProps={{ maxLength: 35 }}
                  />
                </Col>

                <Col xs="12" md="12" lg="6" className='non-p'>
                  <TextField

                    id="taxNumberF2"
                    name="taxNumberF2"
                    className='mt-3'
                    fullWidth
                    label={t("taxNumberLabelF2")}
                    onChange={props.IFsetTaxNumberF2}
                    placeholder={t("taxNumberLabelPlaceholderF2") ?? ""}
                    value={props.taxNumberF2}
                    type='text'
                    inputProps={{ maxLength: 15 }}
                  />
                </Col>
              </Row>
            </Container>

            <Container>
              <Row>
                <Col xs="6" md="6" lg="2" className='non-p'>
                  <TextField
                    required
                    error={props.areaCodeF2Err}
                    id="areaCodeF1"
                    name="areaCodeF1"
                    className='mt-3 pe-2'
                    fullWidth
                    label={t("areaCodeLabelF2")}
                    onChange={props.IFsetAreaCodeF2}
                    value={props.areaCodeF2 === 0 ? '' : props.areaCodeF2}
                    type='text'
                    inputProps={{ maxLength: 3 }}
                  />
                </Col>
                <Col xs="6" md="6" lg="4" className='non-p'>
                  <TextField
                    required
                    error={props.contactPhoneF2Err}
                    id="contactPhoneF1"
                    name="contactPhoneF1"
                    className='mt-3'
                    fullWidth
                    label={t("contactPhoneLabelF2")}
                    onChange={props.IFsetContactPhoneF2}
                    value={props.contactPhoneF2}
                    type='text'
                    inputProps={{ maxLength: 15 }}
                  />
                </Col>
                <Col xs="12" md="12" lg="6" className='non-p'>
                  <TextField
                    error={props.contactEmailF2Err}
                    id="contactEmailF1"
                    name="contactEmailF1"
                    className='mt-3'
                    fullWidth
                    label={t("contactEmailLabelF2")}
                    onChange={props.IFsetContactEmailF2}
                    value={props.contactEmailF2}
                    type='email'
                    inputProps={{ maxLength: 127 }}
                  />
                </Col>
              </Row>
            </Container>

            <TextField
              className='mt-3'
              required
              error={props.contactAddress1F2Err}
              fullWidth
              id="contactAddress1F2"
              name="contactAddress1F2"
              label={t("contactAddress1LabelF2")}
              onChange={props.IFsetContactAddress1F2}
              placeholder={t("contactAddress1F2PlaceholderF2") ?? ""}
              value={props.contactAddress1F2}
              type='text'
              inputProps={{ maxLength: 255 }}
            />

            <TextField
              className='mt-3'

              fullWidth
              id="contactAddress2F2"
              name="contactAddress2F2"
              label={t("contactAddress2LabelF2")}
              onChange={props.IFsetContactAddress2F2}
              placeholder={t("contactAddress2F2PlaceholderF2") ?? ""}
              value={props.contactAddress2F2}
              type='text'
              inputProps={{ maxLength: 29 }}
            />

            <TextField
              className='mt-3'

              fullWidth
              id="contactAddress3F2"
              name="contactAddress3F2"
              label={t("contactAddress3LabelF2")}
              onChange={props.IFsetContactAddress3F2}
              placeholder={t("contactAddress3F2PlaceholderF2") ?? ""}
              value={props.contactAddress3F2}
              type='text'
              inputProps={{ maxLength: 29 }}
            />

            <Container>
              <Row>
                <Col xs="6" md="6" lg="6" className='non-p'>
                  <TextField
                    required
                    error={props.zipCodeF2Err}
                    id="zipCodeF2"
                    name="zipCodeF2"
                    className='mt-3 pe-2'
                    fullWidth
                    label={t("zipCodeLabelF2")}
                    onChange={props.IFsetZipCodeF2}
                    value={props.zipCodeF2}
                    type='text'
                    inputProps={{ maxLength: 15 }}
                  />
                </Col>

                <Col xs="6" md="6" lg="6" className='non-p'>
                  <TextField
                    required
                    error={props.cityF2Err}
                    id="cityF2"
                    name="cityF2"
                    className='mt-3'
                    fullWidth
                    label={t("cityLabelF2")}
                    onChange={props.IFsetCityF2}
                    value={props.cityF2}
                    type='text'
                    inputProps={{ maxLength: 31 }}
                  />
                </Col>
              </Row>
            </Container>

            {/* {props.far250fee && far250freeContainer} */}

            <Container>
              <Row>
                <Col xs="6" md="6" lg="6" className='non-p'>
                  {props.sendLocal != "CA" &&
                    <TextField
                      id="provinceF2"
                      name="provinceF2"
                      className='mt-3 pe-2'
                      fullWidth
                      label={t("provinceLabelF2")}
                      onChange={props.IFsetProvinceF2}
                      value={props.provinceF2}
                      type='text'
                      inputProps={{ maxLength: 63 }}
                    />}

                  {props.sendLocal == "CA" &&
                    <FormControl fullWidth className='mt-3 pe-2 text-start'>
                      <InputLabel id="demo-simple-select-label">{t("provinceLabelF2")}</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={props.provinceF2}
                        className='text-start'
                        onChange={props.IFsetProvinceSelectF2}
                        label={t("provinceLabelF2")}
                      >
                        {provinceCAList.map(item => (
                          <MenuItem key={item.title} value={item.title}>
                            {item.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  }
                </Col>

                <Col xs="6" md="6" lg="6" className='non-p'>
                  <TextField
                    required
                    error={props.countryF2Err}
                    id="countryF2"
                    name="countryF2"
                    className='mt-3'
                    fullWidth
                    label={t("countryLabelF2")}
                    onChange={props.IFsetCountryF2}
                    value={props.countryF2}
                    type='text'
                    inputProps={{ maxLength: 63 }}
                  />
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SecondPage;
