
import { useTranslation } from 'react-i18next';

function LoadUrlFailed() {

  const { t } = useTranslation();

  return (
    <div className="">

                    <fieldset className="border radius-10 mt-5 text-start p-5 backcolor-FF9999">
                      <div className='text-center'>
                        <div className='next-line-if-lf fw-bold font-size-t4 fw-bold '>{t("LoadUrlFailedMsg")}</div>
                      </div>
                    </fieldset>
   
          
  </div>
  );
}

export default LoadUrlFailed;

