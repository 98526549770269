import TextField from '@mui/material/TextField';
import React, { useCallback, useEffect, useState } from 'react';
import MultiStep from 'react-multistep'

import { alpha, styled } from '@mui/material/styles';
import Block1 from './Block/Block1';
import Block2, { IPayFree, setCookie } from './Block/Block2';

import { useParams } from 'react-router-dom';
import axios, { Axios, AxiosRequestConfig } from 'axios';
import { ExecException } from 'child_process';
import { useTranslation } from "react-i18next";
import i18n from '../i18n';
import { Alert } from 'react-bootstrap';
import LoadUrlFailed from './Block/LoadUrlFailed';
import ResultPage from './Block/ResultPage';
import ResultPage2 from './Block/ResultPage2';
import { CheckCoupon, StripeForm } from '../component/StripeCreditCard';
import LoadDateFailed from './Block/LoadDateFailed';
import ShipmentPaid from './Block/ShipmentPaid';
import LoadingBlock from './Block/LoadingBlock';
import PaidButErrorPage from './Block/PaidButErrorPage';
import CouponError from './Block/CouponError';
import { log } from 'console';
import MaintenancePage from './Block/MaintenancePage';
import { SelectChangeEvent } from '@mui/material';
import LandingPage from './Block/LandingPage/LandingPage';
import CancelPage from './Block/CancelPage';
import { uuid } from 'uuidv4';

export const Quotation0910Data = 240920;

interface UploadJSONItemObject {
  package_no: number,
  items_no: number,
  unit_price: UploadJSONItemUnitPriceObject,
}

interface UploadJSONItemUnitPriceObject {
  value?: number,
}

type MembershipLevels = "DIAMONDVIP" | "GOLDVIP" | "VIP" | "NORMAL" | "SENIOR";
type Locations = "UK" | "AU" | "CA";


const membershipFees: Record<Locations, Record<MembershipLevels, number>> = {
  "UK": {
    "DIAMONDVIP": 48,
    "GOLDVIP": 48,
    "VIP": 72,
    "NORMAL": 96,
    "SENIOR": 96
  },
  "AU": {
    "DIAMONDVIP": 90,
    "GOLDVIP": 90,
    "VIP": 98,
    "NORMAL": 118,
    "SENIOR": 118
  },
  "CA": {
    "DIAMONDVIP": 90,
    "GOLDVIP": 90,
    "VIP": 98,
    "NORMAL": 118,
    "SENIOR": 118
  }
};

const membershipFeesNew: Record<Locations, Record<MembershipLevels, number>> = {
  "UK": {
    "DIAMONDVIP": 48,
    "GOLDVIP": 48,
    "VIP": 72,
    "NORMAL": 96,
    "SENIOR": 96
  },
  "AU": {
    "DIAMONDVIP": 78,
    "GOLDVIP": 78,
    "VIP": 98,
    "NORMAL": 118,
    "SENIOR": 118
  },
  "CA": {
    "DIAMONDVIP": 78,
    "GOLDVIP": 78,
    "VIP": 98,
    "NORMAL": 118,
    "SENIOR": 118
  }
};

const membershipFeesType2: Record<Locations, Record<MembershipLevels, number>> = {
  "UK": {
    "DIAMONDVIP": 400,
    "GOLDVIP": 400,
    "VIP": 400,
    "NORMAL": 600,
    "SENIOR": 600
  },
  "AU": {
    "DIAMONDVIP": 400,
    "GOLDVIP": 400,
    "VIP": 400,
    "NORMAL": 600,
    "SENIOR": 600
  },
  "CA": {
    "DIAMONDVIP": 400,
    "GOLDVIP": 400,
    "VIP": 400,
    "NORMAL": 600,
    "SENIOR": 600
  }
};

export const defaultTaxRate = {
  "UK": 0.1,
  "AU": 0.2,
  "CA": 0.18
};

const defaultTaxLimit = {
  "UK": 135,
  "AU": 1000,
  "CA": 20
};

 export const defaultCurrencyName = {
  "UK": "GBP",
  "AU": "AUD",
  "CA": "CAD"
};

function calculateFee(location: Locations | undefined, level: MembershipLevels | undefined,ShipmentOrderDate:number,selectedPaymentMethodF3:number): number {
  if (location && level) {
    if(ShipmentOrderDate<240101){ //2024/01/01
      return membershipFees[location][level];
    }
    if(selectedPaymentMethodF3 == 4 || selectedPaymentMethodF3 == 5){ //2024/06/17
      return membershipFeesType2[location][level];
    }
    return membershipFeesNew[location][level];
  }
  return 0;
}

const additionalFeesPerKg: Record<Locations, number> = {
  "UK": 96,
  "AU": 100,
  "CA": 100
};

const additionalFeesPerKgType2: Record<Locations, number> = {
  "UK": 50,
  "AU": 50,
  "CA": 50
};

export const provinceCAList = [{ title: 'Alberta',tax:0},
{ title: 'British Columbia' ,tax : 0.07},
{ title: 'Manitoba',tax : 0.07 },
{ title: 'New Brunswick' ,tax : 0.1},
{ title: 'Newfoundland and Labrador',tax : 0.1 },
{ title: 'Northwest Territories',tax : 0 },
{ title: 'Nova Scotia',tax : 0.1 },
{ title: 'Nunavut' ,tax : 0},
{ title: 'Ontario',tax : 0.08 },
{ title: 'Prince Edward Island' ,tax : 0.1},
{ title: 'Quebec' ,tax : 0.09975},
{ title: 'Saskatchewan' ,tax: 0.06},
{ title: 'Yukon' ,tax : 0}];

function MainForm() {


  //Main Control
  const params = useParams();
  const [jsonData, setJsonData] = useState<JSONType>();
  const [shipment_number, setShipment_number] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [cancelMsg, setCancelMsg] = useState("");
  // const [exist, set] = useState("");
  const { t } = useTranslation();
  const [membership_level, setMembership_level] = useState(0.0);
  const [membership_after15kg, setMembership_after15kg] = useState(0.0);
  const [membership_level_str, setMembership_level_str] = useState("");
  const [sendLocal, setSendLocal] = useState("");
  // const [farPostalCodeFee, setFarPostalCodeFee] = useState(0.0);
  const [exchangeRate,setExchangeRate] = useState(0.0);
  const [customPoint, setCustomPoint] = useState(0.0);
  const [pointDiscount, setPointDiscount] = useState(0);

  const handleChangeLng = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  };
  //Main Control

  //Page Control
  const [activePage, setActivePage] = useState(0);
  //Page Control

  //Page1
  const [contactNameF1, setContactNameF1] = useState("");
  const [areaCodeF1, setAreaCodeF1] = useState(0);
  const [contactPhoneF1, setContactPhoneF1] = useState("");
  const [contactEmailF1, setContactEmailF1] = useState("");
  //Page1

  //Page2
  const [contactNameF2, setContactNameF2] = useState("");
  const [companyNameF2, setCompanyNameF2] = useState("");
  const [taxNumberF2, setTaxNumberF2] = useState("");
  const [areaCodeF2, setAreaCodeF2] = useState(0);
  const [contactPhoneF2, setContactPhoneF2] = useState("");
  const [contactEmailF2, setContactEmailF2] = useState("");
  const [contactAddress1F2, setContactAddress1F2] = useState("");
  const [contactAddress2F2, setContactAddress2F2] = useState("");
  const [contactAddress3F2, setContactAddress3F2] = useState("");
  const [zipCodeF2, setZipCodeF2] = useState("");
  const [cityF2, setCityF2] = useState("");
  const [provinceF2, setProvinceF2] = useState("");
  const [countryF2, setCountryF2] = useState("");
  const [customAddressNoteF2, setCustomAddressNoteF2] = useState<JSONTypeRequestReceiver[]>([]);
  //Page2

  //Page3
  const [itemlist, setItemlist] = useState<IItemList[]>([]);
  const [totalPriceF3, setTotalPriceF3] = useState(0);
  const [FirstLoad, setFirstLoad] = useState(true);
  const [insuranceF3, setInsuranceF3] = useState(false);
  const [demoPrice, setDemoPrice] = useState<number>(0);
  const [selectedPaymentMethodF3, setSelectedPaymentMethodF3] = useState<number>(0);
  //PayWithoutTax = 0 , PayWithTax = 1 , UKFreeTax = 2
  const [showMsg1F3, setShowMsg1F3] = useState(false);
  const [showMsg2F3, setShowMsg2F3] = useState(false);
  //page3

  //page5
  const [promoObjF5, setPromoObjF5] = useState<IPromoCode | null>();
  const [couponCodeF5, setCouponCodeF5] = useState("");
  const [autoPromoObjF5, setAutoPromoObjF5] = useState<IPromoCode | null>();
  const [autoCouponCodeF5, setAutoCouponCodeF5] = useState("");
  const [totalPriceF5, setTotalPriceF5] = useState(0);
  //page5

  //page6
  const [firstLoadPage6, setFirstLoadPage6] = useState(true);
  // const [far250fee, setFar250fee] = useState(false);
  const [promoPriceF6, setPromoPriceF6] = useState(0.0);
  const [promoPoint, setPromoPoint] = useState(0);
  const [autoPromoPriceF6, setAutoPromoPriceF6] = useState(0.0);
  const [term1F6, setTerm1F6] = useState(false);
  const [term2F6, setTerm2F6] = useState(false);
  const [showStripeForm, setShowStripeForm] = useState(false);
  const [finalKGF6, setFinalKGF6] = useState(0);
  const [finalPrice65, setFinalPrice65] = useState(0);
  const [finalPrice92, setFinalPrice92] = useState(0);
  const [insuranceVal, setInsuranceVal] = useState(0);
  const [agreementMsg, setAgreementMsg] = useState(<></>)
  const [stripeForm, setStripeForm] = useState(<></>)
  const [stripeClientSecret, setStripeClientSecret] = useState('')
  const [showCancelBoxP6, setShowCancelBoxP6] = useState(false);
  const [SKUPromoCodeList, setSKUPromoCodeList] = useState<AutoSKUPromoCodeResponse | null>(null);
  //SKU promo
  const [SKUPromoPriceF6, setSKUPromoPriceF6] = useState(0.0);
  const [NewSKUPromoQtyF6, setNewSKUPromoQtyF6] = useState(0.0);
  const [NewSKUPromoPriceF6, setNewSKUPromoPriceF6] = useState(0.0);
  //SKU promo
  const [pageSixFirstLoad, setPageSixFirstLoad] = useState(true);
  const [cumulativeKgQtyMinus, setCumulativeKgQtyMinus] = useState(0);
  //Tax
  const [tax1F6, setTax1F6] = useState<number>(0);
  const [tax2F6, setTax2F6] = useState<number>(0);
  const [tax3F6, setTax3F6] = useState<number>(0);

  const [tax1Rate, setTax1Rate] = useState<number>(0);
  const [tax2Rate, setTax2Rate] = useState<number>(0);
  const [tax3Rate, setTax3Rate] = useState<number>(0);
  //Tax
  //Over Charge
  const [overCharge1, setOverCharge1] = useState<number>(0);
  const [overCharge2, setOverCharge2] = useState<number>(0);
  //Over Charge
  //page6

  //ErrorPage
  const [errorNumber, setErrorNumber] = useState("");
  //ErrorPage

  //MaintainPage
  const [maintainMsg, setMaintainMsg] = useState("");
  //MaintainPage

  useEffect(() => {

    if (FirstLoad) {
      setLanguage();
      CheckMaintenance().then((isUnderMaintenance) => {
        if (!isUnderMaintenance) {
          LoadData();
        }
      });
      setFirstLoad(false);
    }

  });


  // useEffect(() => {
  //   if (AUCodeStrSet.has(zipCodeF2) && sendLocal === "AU") {
  //     setFar250fee(true);
  //   } else {
  //     setFar250fee(false);
  //   }
  // }, [zipCodeF2]);

  const handleCheckboxChangeP6 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowCancelBoxP6(event.target.checked);
  };

  const IFsetHideMsgBoxP6 = () => {
    setShowCancelBoxP6(false);
  }

  const getExchangeRate = async (getLocal: string) => {
      const queryParameters = new URLSearchParams(window.location.search);
      const jwt = queryParameters.get("token");

      try {
          const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/getExchangeRate`, 
              {
                exchange_rate_location: getLocal
              }, 
              { 
                  headers: { Authorization: `Bearer ${jwt}` } 
              }
          );

          // Handle the response here
          setExchangeRate(response.data.exchange_rate_value);

      } catch (error) {
          // Handle the error here
          console.error("Error fetching exchange rate:", error);
      }
  }

  const CheckMaintenance = async () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const jwt = queryParameters.get('token');

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/CheckMaintenance`,
        {},
        { headers: { Authorization: `Bearer ${jwt}` } }
      );

      if (response.data.maintenance_msg) {
        setMaintainMsg(response.data.maintenance_msg);
        setActivePage(11);
        return true;
      }
      return false;
    } catch (error) {
      console.error('An error occurred while checking for maintenance:', error);
      // Handle the error according to your needs
      return false;
    }
  };

  const setLanguage = () => {
    let language: string = params.language === 'zh' ? "zh" : "en";
    handleChangeLng(language);
    document.documentElement.lang = language;
  }



  const LoadData = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const jwt = queryParameters.get("token");
    //console.log(jwt);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/shipment-number`, { headers: { Authorization: `Bearer ${jwt}` } })
      .then((res) => {
        let JsonData: JSONType = res.data.data;
        setJsonData(JsonData);
        SetAllData(JsonData);
        setTimeout(() => {
      }, 1000);
        if (JsonData.request.request_status === "PAID") {
          setActivePage(7);
          return;
        }else if(JsonData.request.request_status === "CANCEL"){
          setActivePage(12);
          return;
        }
        handleShowDialog();
        setActivePage(1);
      }).catch((err) => {
        if (err.response.data.err_code === "TOKEN_EXPIRED") {
          setActivePage(6);
        } else {
          setActivePage(3);
        }
        console.error(err);

      });
  }


  const SetAllData = (jsonData: JSONType) => {
    if (jsonData == undefined) {
      return;
    }
    let getLocal = jsonData.request.request_location ;
    setSendLocal(getLocal);
    getExchangeRate(getLocal);
    setSelectedPaymentMethodF3(jsonData.request.request_paytax);
    setShipment_number(jsonData.request.shipment_number);
    SetSenderData(jsonData);
    SetReceiverData(jsonData);
    setItemlistsData(jsonData);
    setaddressNoteData(jsonData);
    
  }

  const SetSenderData = (jsonData: JSONType) => {
    setContactNameF1(jsonData.request.sender.name);
    setAreaCodeF1(parseInt(jsonData.request.sender.country_code, 10));
    setContactPhoneF1(jsonData.request.sender.number);
    setContactEmailF1(jsonData.request.sender.email);
    setCustomPoint(jsonData.customer_user.sender.point);
    setInsuranceF3(jsonData.request.insurance.has_insurance ?? true);
    setDemoPrice(jsonData.request.insurance.sum_assured ?? 0);
    setMembership_level_str(jsonData.request.membership_level);
    const location = jsonData.request.request_location as Locations;
    const originalLevel = jsonData.request.membership_level;
    let effectiveLevel = originalLevel as MembershipLevels;

    let GetOrderDate = Number(jsonData.request.shipment_number.substring(1, 7));
    if (isNaN(GetOrderDate)) {
      GetOrderDate = 0; 
    }

    setMembership_level(calculateFee(location, effectiveLevel,GetOrderDate,selectedPaymentMethodF3));

    //If the order date is after 2024/06/17, use additionalFeesPerKgType2 , if not use additionalFeesPerKg
    if(selectedPaymentMethodF3 == 4 || selectedPaymentMethodF3 == 5){
      setMembership_after15kg(additionalFeesPerKgType2[location]);
    }else{
      setMembership_after15kg(additionalFeesPerKg[location]);
    }

  }
  const SetReceiverData = (jsonData: JSONType) => {
    setContactNameF2(jsonData.request.receiver.contact_name ?? "");
    setCompanyNameF2(jsonData.request.receiver.company_name ?? "");
    setTaxNumberF2(jsonData.request.receiver.tax_number ?? "");
    setAreaCodeF2(jsonData.request.receiver.area_code ?? 0);
    setContactPhoneF2((jsonData.request.receiver.phone ?? "").toString());
    setContactEmailF2(jsonData.request.receiver.email ?? "");
    setContactAddress1F2(jsonData.request.receiver.address_line_1 ?? "");
    setContactAddress2F2(jsonData.request.receiver.address_line_2 ?? "");
    setContactAddress3F2(jsonData.request.receiver.address_line_3 ?? "");
    setZipCodeF2(jsonData.request.receiver.zip_code ?? "");
    setCityF2(jsonData.request.receiver.city ?? "");
    setProvinceF2(jsonData.request.receiver.province ?? "");
    const location = jsonData.request.request_location as Locations;
    let country = jsonData.request.receiver.country;
    if (!country || country === "") {
      switch (location) {
        case "UK":
          country = "United Kingdom";
          break;
          case "AU":
            country = "Australia";
            break;
          case "CA":
            country = "Canada";
            break;
        // You can add more cases here if needed
        default:
          break;
      }
    }
    setCountryF2(country ?? "");
  }
  const setItemlistsData = (jsonData: JSONType) => {
    let totalItemFreeDeliveryWeight = 0;
    jsonData.items.map((value, index) => {
      setItemlist(itemlist => [...itemlist, {
        "itemID": value.items_id,
        "item_no": value.items_no,
        "itemNamezh": value.name.zh,
        "itemNameen": value.name.en,
        "itemQty": value.items_qty,
        "itemPrice": value.unit_price.value, "itemTotalPrice": 0,
        "package_no": value.package_no,
        "itemPriceErr": false,
        "item_free_delivery_weight": value.item_free_delivery_weight
      }])
      totalItemFreeDeliveryWeight += Number(value.item_free_delivery_weight) || 0;
    });
    setNewSKUPromoQtyF6(totalItemFreeDeliveryWeight);

  }
  const setaddressNoteData = (jsonData: JSONType) => {
    jsonData.customer_address.forEach((value, index) => {
      setCustomAddressNoteF2(customAddressNoteF2 => [...customAddressNoteF2,
      {
        "address_line_1": value.address_line_1 ?? "",
        "address_line_2": value.address_line_2 ?? "",
        "address_line_3": value.address_line_3 ?? "",
        "area_code": value.area_code ?? 0,
        "city": value.city ?? "",
        "company_name": value.company_name ?? "",
        "contact_name": value.contact_name ?? "",
        "country": value.country ?? "",
        "email": value.email ?? "",
        "phone": value.phone ?? "",
        "province": value.province ?? "",
        "tax_number": value.tax_number ?? "",
        "zip_code": value.zip_code ?? "",
      }])
    });

  }

  const handleShowDialog = () => {
    setShowDialog(true);
  };

  const handleHideDialog = () => {
    setShowDialog(false);
  };

  //SaveData
  async function SaveData() {
    const queryParameters = new URLSearchParams(window.location.search);
    const jwt = queryParameters.get("token");
  
    let block1Data = getBlock1Data();
  
    let FormJson = {
      "request": {
        "paytax": selectedPaymentMethodF3,
        "total_price": {
          "value": block1Data.request.total_price.value
        },
        "sender": {
          "name": block1Data.request.sender.name,
          "email": block1Data.request.sender.email,
          "country_code": block1Data.request.sender.country_code,
          "number": block1Data.request.sender.number
        },
        "receiver": {
          "contact_name": block1Data.request.receiver.contact_name,
          "company_name": block1Data.request.receiver.company_name,
          "tax_number": block1Data.request.receiver.tax_number,
          "area_code": block1Data.request.receiver.area_code,
          "phone": block1Data.request.receiver.phone,
          "email": block1Data.request.receiver.email,
          "address_line_1": block1Data.request.receiver.address_line_1,
          "address_line_2": block1Data.request.receiver.address_line_2,
          "address_line_3": block1Data.request.receiver.address_line_3,
          "zip_code": block1Data.request.receiver.zip_code,
          "city": block1Data.request.receiver.city,
          "province": block1Data.request.receiver.province,
          "country": block1Data.request.receiver.country
        },
        "insurance": {
          "has_insurance": block1Data.request.insurance.has_insurance,
          "sum_assured": block1Data.request.insurance.sum_assured
        }
      },
      "items": block1Data.items
    };
  
    try {
      await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/shipment-number/${shipment_number}`, FormJson, { headers: { Authorization: `Bearer ${jwt}` } });
      // console.log("success");
    } catch (err) {
      console.error(err);
    }
  }
  
  //SaveData
  //PageControl
  useEffect(() => {
    const root = document.getElementById('top');
    root?.scrollIntoView(true);
  }, [activePage]);

  let IFsetActivePage = (e: number) => {
    setActivePage(e);
  }
  //PageControl

  let IFsetPointDiscount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    if (isNaN(value) || value < 0) {
      setPointDiscount(0);
    }else if(value * 20 <= customPoint){
      setPointDiscount(value);
    }
  }

  //Page1
  let IFsetContactNameF1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContactNameF1(e.target.value);
  }

  let IFsetAreaCodeF1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value === '' ? 0 : parseInt(e.target.value, 10);
    if (isNaN(value)) {
      setAreaCodeF1(0);
    } else {
      if (value <= 999) {
        setAreaCodeF1(value);
      }
    }
  }

  let IFsetContactPhoneF1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = parseInt(e.target.value);
    if (isNaN(val)) {
      setContactPhoneF1("");
    } else {
      setContactPhoneF1(val.toString());
    }
  }

  let IFsetContactEmailF1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContactEmailF1(e.target.value);
  }
  //Page1


  //Page2
  let IFsetContactNameF2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContactNameF2(e.target.value);
  }
  let IFsetCompanyNameF2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyNameF2(e.target.value);
  }
  let IFsetTaxNumberF2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTaxNumberF2(e.target.value);
  }
  let IFsetAreaCodeF2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value === '' ? 0 : parseInt(e.target.value, 10);
    if (isNaN(value)) {
      setAreaCodeF2(0);
    } else {
      if (value <= 999) {
        setAreaCodeF2(value);
      }
    }
  }
  let IFsetContactPhoneF2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = parseInt(e.target.value);
    if (isNaN(val)) {
      setContactPhoneF2("");
    } else {
      setContactPhoneF2(val.toString());
    }
  }
  let IFsetContactEmailF2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContactEmailF2(e.target.value);
  }
  let IFsetContactAddress1F2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContactAddress1F2(e.target.value);
  }
  let IFsetContactAddress2F2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContactAddress2F2(e.target.value);
  }
  let IFsetContactAddress3F2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContactAddress3F2(e.target.value);
  }
  let IFsetZipCodeF2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valueStr = e.target.value;
    setZipCodeF2(valueStr);
  }

  let IFsetCityF2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCityF2(e.target.value);
  }
  let IFsetProvinceF2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProvinceF2(e.target.value);
  }

  const IFsetProvinceSelectF2 = (event: SelectChangeEvent) => {
    setProvinceF2(event.target.value as string);
  };
  let IFsetCountryF2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCountryF2(e.target.value);
  }
  let IFsetSingeCustomAddress = (e: number) => {
    setContactAddress1F2(customAddressNoteF2[e].address_line_1 ?? "");
    setContactAddress2F2(customAddressNoteF2[e].address_line_2 ?? "");
    setContactAddress3F2(customAddressNoteF2[e].address_line_3 ?? "");
    setContactNameF2(customAddressNoteF2[e].contact_name ?? "");
    setCompanyNameF2(customAddressNoteF2[e].company_name ?? "");
    setTaxNumberF2(customAddressNoteF2[e].tax_number ?? "");
    setAreaCodeF2(customAddressNoteF2[e].area_code ?? 0);
    setContactPhoneF2(customAddressNoteF2[e].phone ?? "");
    setContactEmailF2(customAddressNoteF2[e].email ?? "");
    setZipCodeF2(customAddressNoteF2[e].zip_code ?? "");
    setCityF2(customAddressNoteF2[e].city ?? "");
    setProvinceF2(customAddressNoteF2[e].province ?? "");
    setCountryF2(customAddressNoteF2[e].country ?? "");
  }
  let IFsetSaveCustomAddress = (e: number) => {
    const myNextList = [...customAddressNoteF2];
    const item = myNextList[e];
    if (item !== undefined) {
      item.address_line_1 = contactAddress1F2;
      item.address_line_2 = contactAddress2F2;
      item.address_line_3 = contactAddress3F2;
    }
    setCustomAddressNoteF2(myNextList);
  }
  //Page2

  //page3
  useEffect(() => {
    let shipment_period = parseInt(shipment_number.substring(1, 7));
    if (
        ((shipment_period >= 231206 && totalPriceF3 > 2500) || 
        (shipment_period < 231206 && totalPriceF3 > 1500)) && 
        selectedPaymentMethodF3 == 2 &&
        sendLocal === 'UK'
    ) {
        setSelectedPaymentMethodF3(0);
    } else if (sendLocal === 'CA' && 
    ((shipment_period >= 231218 && totalPriceF3 > 800) || 
        (shipment_period < 231218 && totalPriceF3 > 600))
    && selectedPaymentMethodF3 == 2) {
        setSelectedPaymentMethodF3(0);
    }

      if(totalPriceF3 > 5000){
        setSelectedPaymentMethodF3(0);
      }
      
      if(totalPriceF3 > 2500 && sendLocal === 'UK' && selectedPaymentMethodF3 != 3){
        setSelectedPaymentMethodF3(0);
      }

    // default Fee tax UK 
    if((sendLocal === 'UK' && (shipment_period >= 231206 && totalPriceF3 <= 2500) && selectedPaymentMethodF3 != 4) && shipment_period < Quotation0910Data){
      setSelectedPaymentMethodF3(2);
    }

    if((sendLocal === 'CA' && totalPriceF3 <= 800  && selectedPaymentMethodF3 != 4) && shipment_period < Quotation0910Data){
      setSelectedPaymentMethodF3(2);
    }
    let location = sendLocal as Locations;
    let GetOrderDate = Number(shipment_number.substring(1, 7));
    if (isNaN(GetOrderDate)) {
      GetOrderDate = 0; 
    }
    let effectiveLevel =  membership_level_str as MembershipLevels;
    setMembership_level(calculateFee(location, effectiveLevel,GetOrderDate,selectedPaymentMethodF3));

    //If the order date is after 2024/06/17, use additionalFeesPerKgType2 , if not use additionalFeesPerKg
    if(selectedPaymentMethodF3 == 4 || selectedPaymentMethodF3 == 5){
      setMembership_after15kg(additionalFeesPerKgType2[location]);
    }else{
      setMembership_after15kg(additionalFeesPerKg[location]);
    }
    
  }, [totalPriceF3]);

  useEffect(() => {
    let location = sendLocal as Locations;
    let GetOrderDate = Number(shipment_number.substring(1, 7));
    if (isNaN(GetOrderDate)) {
      GetOrderDate = 0; 
    }
    let effectiveLevel =  membership_level_str as MembershipLevels;
    setMembership_level(calculateFee(location, effectiveLevel,GetOrderDate,selectedPaymentMethodF3));

    //If the order date is after 2024/06/17, use additionalFeesPerKgType2 , if not use additionalFeesPerKg
    if(selectedPaymentMethodF3 == 4 || selectedPaymentMethodF3 == 5){
      setMembership_after15kg(additionalFeesPerKgType2[location]);
    }else{
      setMembership_after15kg(additionalFeesPerKg[location]);
    }
    
  }, [selectedPaymentMethodF3]);

  let IFItemListsF3 = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number) => {
    const myNextList = [...itemlist];
    const item = myNextList.find(a => a.itemID === index);
    const value = e.target?.value ?? '';
    if (item !== undefined) {
      const newVal = value.length <= 7 ? parseFloat(value) : parseFloat(value.toString().substring(0, 7));
      let setNum = newVal;
      if (isNaN(setNum) || setNum <= 0) {
        item.itemPrice = 0;
      } else {
        item.itemPrice = setNum;
      }
    }
    setItemlist(myNextList);
    IFsetTotalPriceF3(itemlist);
  }

  let IFsetTotalPriceF3 = (list: IItemList[]) => {
    var totalPrice = 0;
    list.map((value, index) => {
      totalPrice += (value.itemPrice * value.itemQty);
    })
    setTotalPriceF3(Math.round(totalPrice * 100) / 100);
  }

  let IFsetInsuranceF3 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInsuranceF3(e.target.checked);
  }
  let IFsetDemoPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    var val = e.target.value === '' || e.target.value === '.' ? "0" : e.target.value;
    const newVal = val.length <= 10 ? parseFloat(val) : parseFloat(val.toString().substring(0, 10));
    const roundVal = Math.round(newVal * 100) / 100;
    setDemoPrice(roundVal);
  }

  const IFsetSelectedPaymentMethodF3 = (val: number) => {
    setSelectedPaymentMethodF3(val);
  };

  const IFsetShowMsg1 = () => {
    setShowMsg1F3(!showMsg1F3);
  }

  const IFsetShowMsg2 = () => {
    setShowMsg2F3(!showMsg2F3);
  }

  useEffect(() => {
    if (selectedPaymentMethodF3 == 1) {
      IFsetShowMsg1();
    } else if (selectedPaymentMethodF3 == 0){
      IFsetShowMsg2();
    }
  }, [selectedPaymentMethodF3]);

  //page3

  //page5
  let IFsetCouponCodeF5 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCouponCodeF5(e.target.value);
  }
  let IFsetAutoCouponCodeF5 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAutoCouponCodeF5(e.target.value);
  }
  let IFsetTotalPriceF5 = (e: number) => {
    setTotalPriceF5(e);
  }
  let IFsetPromoObjF5 = (e: IPromoCode | null) => {
    setShowStripeForm(false);
    IFClearStripeForm()
    stripeClientSecret !== "" ? handleStripeUnmount() : void (0);
    setStripeClientSecret("")
    setPromoObjF5(e);
  }
  let IFsetAutoPromoObjF5 = (e: IPromoCode | null) => {
    setShowStripeForm(false);
    IFClearStripeForm()
    stripeClientSecret !== "" ? handleStripeUnmount() : void (0);
    setStripeClientSecret("")
    setAutoPromoObjF5(e);
  }
  //page5

  //page6

  // const IFsetFarPostalCodeFee = (e: number) => {
  //   setFarPostalCodeFee(e);
  // }

  const handleStripeUnmount = () => {
    let orderId = stripeClientSecret.split("_secret_")[0]
    let jsonData = { payment_id: orderId }
    const jwt = new URL(window.location.href).searchParams.get('token')
    const axiosConfig: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${jwt}` }
    }
    axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/stripe-payment/${shipment_number}/cancelled`,
      jsonData,
      axiosConfig
    ).then((res) => {
      console.log("OK")
      // console.log(res.data)
    }).catch((err) => {
      console.log("error")
      console.log(err)
    })
  }

  let IFsetTerm1F6 = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked) {
      IFClearStripeForm();
      stripeClientSecret !== "" ? handleStripeUnmount() : void (0);
      setShowStripeForm(false);
    }
    setTerm1F6(e.target.checked);
  }
  let IFsetTerm2F6 = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked) {
      IFClearStripeForm();
      stripeClientSecret !== "" ? handleStripeUnmount() : void (0);
      setShowStripeForm(false);
    }
    setTerm2F6(e.target.checked);
  }
  let IFsetStripeButton = async () => {
    if(showCancelBoxP6){
      CancelThisShipment(shipment_number);
      return ;
    }
    if (term1F6 && term2F6) {
      IFClearAgreementMsg();
      if (showStripeForm) {
        IFClearStripeForm()
        stripeClientSecret !== "" ? handleStripeUnmount() : void (0);
        setStripeClientSecret("")
      }

      let getOrginAmount = Math.round((finalPrice92 + insuranceVal - SKUPromoPriceF6 - promoPriceF6 - autoPromoPriceF6 /*+ farPostalCodeFee*/ + tax1F6 + tax2F6 + tax3F6 + overCharge1 + overCharge2 - pointDiscount) * 100) / 100;

      let shippingfee = Math.round((finalPrice65 + insuranceVal - SKUPromoPriceF6 - promoPriceF6 - autoPromoPriceF6 /*+ farPostalCodeFee*/) * 100) / 100;

      let taxfee = Math.round((tax1F6 + tax2F6 + tax3F6) * 100) / 100;

      let OverChargefee = overCharge1 + overCharge2;

      let getAmount = shippingfee + taxfee + OverChargefee - pointDiscount;

      let getAddPoint = Math.round((finalPrice65 - SKUPromoPriceF6 - promoPriceF6 - autoPromoPriceF6) * 100) / 100 - pointDiscount;

      if (getAmount < 0) {
        getAmount = 0;
        getOrginAmount = 0;
      }

      if (getAmount < 4) {
        setActivePage(8);
        let isSuccessful = false;
        const jwt = new URL(window.location.href).searchParams.get('token');

        let promoCode = ""
        let autoPromoCode = ""
        if (promoObjF5 != null) {
          promoCode = promoObjF5.promo_code_string;
          isSuccessful = await CheckCoupon(promoCode,membership_level_str,shipment_number,finalPrice65,finalKGF6,0,selectedPaymentMethodF3);
        } else {
          isSuccessful = true;
        }

        if(isSuccessful){
          if (autoPromoObjF5 != null) {
            autoPromoCode = autoPromoObjF5.promo_code_string;
            isSuccessful = await CheckCoupon(autoPromoCode,membership_level_str,shipment_number,finalPrice65,finalKGF6,1,selectedPaymentMethodF3);
          } else {
            isSuccessful = true;
          }
        }
        

        if (isSuccessful) {
          const axiosConfig: AxiosRequestConfig = {
            headers: { Authorization: `Bearer ${jwt}` }
          }
          let language: string = params.language === 'zh' ? "zh" : "en";
          const insertPaymentData = {
            "request": jsonData!!.request.request_id,
            "payment_order_id": jsonData!!.request.shipment_number,
            "payment_currency_code": "hkd",
            "payment_amount": getAmount,
            "payment_net_amount": getAmount,
            "payment_orgin_amount": getOrginAmount,
            "payment_shipping_fee": shippingfee,
            "payment_tax_fee": taxfee,
            "payment_over_charge":OverChargefee,
            "payment_status": "SUCCESS",
            "usedPoint": pointDiscount * 20,
            "couponCode": promoCode,
            "autoCouponCode": autoPromoCode,
            "lang": language,
            "SKUPromoCodeList" : SKUPromoCodeList?.matching_promo_codes ?? null,
          }
          
          axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/insertPaymentRecord`,
            insertPaymentData,
            axiosConfig
          ).then((res) => {
            SaveData();
            setActivePage(4);
          }).catch((err) => {
            SaveData();
            setActivePage(5);
          });

          return;
        } else {
          SaveData();
          setActivePage(5);
          return;
        }
      }

      let promoCode = (promoObjF5 === null) || (promoObjF5 === undefined) ? "" : promoObjF5.promo_code_string
      let autoPromoCode = (autoPromoObjF5 === null) || (autoPromoObjF5 === undefined) ? "" : autoPromoObjF5.promo_code_string
      IFgetPaymentSecret().then((client_secret) => {
        setStripeForm(<StripeForm shipment_number={shipment_number} clientSecret={client_secret} IFsetActivePage={IFsetActivePage} 
          getBlock1Data={getBlock1Data} promoCode={promoCode} autoPromoCode={autoPromoCode} membership_level_str={membership_level_str} setErrorNumber={setErrorNumber} 
          shippingfee={shippingfee}  taxfee={taxfee}  finalPrice65={finalPrice65} finalKGF6={finalKGF6} overCharge={OverChargefee} usedPoint={pointDiscount} getPoint={getAddPoint}
          SKUPromoCodeList={SKUPromoCodeList}/>)
        setStripeClientSecret(client_secret)
      }).catch((e) => {
        console.log(e)
        setStripeForm(<>{t('FinalPayError')}</>)
      })
      setShowStripeForm(!showStripeForm)
    } else {
      setShowStripeForm(false);
      IFClearStripeForm()
      stripeClientSecret === "" ? handleStripeUnmount() : void (0);
      setAgreementMsg(<Alert variant='warning' dismissible onClose={IFClearAgreementMsg}>{t('FinalSubmitVaildF6')}</Alert>)
    }
  }

  const CancelThisShipment = async (shipmentNumber: string) => {
    setActivePage(8);
    const jwt = new URL(window.location.href).searchParams.get('token');
    const axiosConfig: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${jwt}` }
    };
    
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/cancel_shipment`, 
        { shipment_number: shipmentNumber },
        axiosConfig
      );
  
      setCancelMsg(t("OrderCancelMsg"));
      setActivePage(12);
    } catch (error) {
      console.error("Error cancelling shipment:", error);
      setCancelMsg(t("OrderCancelErrorMsg"));
      setActivePage(12);
    }
  };
  

  let IFgetPaymentSecret = async () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const jwt = queryParameters.get("token");
    const axiosConfig: AxiosRequestConfig = { headers: { Authorization: `Bearer ${jwt}` } }

    let getAmount = Math.round((finalPrice65 + insuranceVal - SKUPromoPriceF6 - promoPriceF6 - autoPromoPriceF6 /*+ farPostalCodeFee*/ + tax1F6 + tax2F6 + tax3F6 + overCharge1 + overCharge2 - pointDiscount) * 100) / 100;

    let getAmountOrgin = Math.round((finalPrice92 + insuranceVal - SKUPromoPriceF6 - promoPriceF6 - autoPromoPriceF6 /*+ farPostalCodeFee*/ + tax1F6 + tax2F6 + tax3F6 + overCharge1 + overCharge2 - pointDiscount) * 100) / 100;

    let jsonData = { amount: getAmount >= 4 ? getAmount : 0, amountOrgin: getAmountOrgin >= 4 ? getAmountOrgin : 0 }
    let axiosObj = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/stripe-payment/${shipment_number}`,
      jsonData, axiosConfig
    )
    return axiosObj.data.data.client_secret ?? '';
  }

  let IFsetFinalKGF6 = (e: number) => {
    setFinalKGF6(e);
  }

  let IFsetFinalPrice65 = (e: number) => {
    const absVal = Math.abs(e);
    setFinalPrice65(absVal);
  }
  let IFsetFinalPrice92 = (e: number) => {
    const absVal = Math.abs(e);
    setFinalPrice92(absVal);
  }
  let IFsetInsuranceVal = (e: number) => {
    const absVal = Math.abs(e);
    setInsuranceVal(absVal);
  }
  let IFsetPromoPriceF6 = (e: number) => {
    const Val = Math.round(e * 100) / 100;
    setPromoPriceF6(Val);
  }
  let IFsetAutoPromoPriceF6 = (e: number) => {
    const Val = Math.round(e * 100) / 100;
    setAutoPromoPriceF6(Val);
  }
  let IFsetFirstLoadPage6 = () => {
    setFirstLoadPage6(false);
  }

  let IFClearAgreementMsg = () => { setAgreementMsg(<></>) }

  let IFClearStripeForm = () => { setStripeForm(<></>) }

  //Tax
  useEffect(() => {
    let taxRate1 = 0;
    let taxRate2 = 0;
    let taxRate3 = 0;
    let getDefaultTaxRate = exchangeRate > 0 ?  exchangeRate : defaultTaxRate[sendLocal as keyof typeof defaultTaxRate];
    let getDefaultTaxLimit = defaultTaxLimit[sendLocal as keyof typeof defaultTaxRate];

    if (selectedPaymentMethodF3 == 1) {
      if ((totalPriceF3 * getDefaultTaxRate) > getDefaultTaxLimit) {
        if (sendLocal === 'UK') {
          taxRate1 = 0.2; // UK Tax Rate for Tax1
          //taxRate2 = 0.2; // UK Tax Rate for Tax2
        } else if (sendLocal === 'AU') {
          taxRate1 = 0.1; // AU Tax Rate for Tax1
          taxRate2 = 0.05;
        } else if (sendLocal === 'CA') {
          taxRate1 = 0.05;
          const province = provinceCAList.find(province => province.title === provinceF2);
          taxRate2 = province ? province.tax : 0;
          taxRate3 = 0.25;
        }
      }else{
        if (sendLocal === 'UK') {
          taxRate1 = 0.2;
        }
      }
      setTax1F6(Math.round(totalPriceF3 * taxRate1 * 100) / 100);
      setTax2F6(Math.round(totalPriceF3 * taxRate2 * 100) / 100);
      setTax3F6(Math.round(totalPriceF3 * taxRate3 * 100) / 100);
      setTax1Rate(Math.round(taxRate1 * 100 * 100) / 100);
      setTax2Rate(Math.round(taxRate2 * 100 * 100) / 100);
      setTax3Rate(Math.round(taxRate3 * 100 * 100) / 100);
    } else {
      setTax1F6(0);
      setTax2F6(0);
      setTax3F6(0);
      setTax1Rate(0);
      setTax2Rate(0);
      setTax3Rate(0);
    }
  }, [selectedPaymentMethodF3, totalPriceF3,exchangeRate,provinceF2]);
  //Tax
  //page6


  //Recon Payment
  const IFReconPay = useCallback(async () => {
    if (term1F6 && term2F6) {
      IFClearAgreementMsg();
      SaveData();
      //if (await checkCouponCanUse()) {
      IFReconPayment();
      //}
    } else {
      setShowStripeForm(false);
      IFClearStripeForm()
      stripeClientSecret === "" ? handleStripeUnmount() : void (0);
      setAgreementMsg(<Alert variant='warning' dismissible onClose={IFClearAgreementMsg}>{t('FinalSubmitVaildF6')}</Alert>)
    }
  }, [finalPrice65, insuranceVal, promoPriceF6,autoPromoPriceF6, term1F6, term2F6]);

  const checkCouponCanUse = async () => {
    let isSuccessful = false;
    if (promoObjF5 != null) {
      await axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/promo-code/${promoObjF5.promo_code_string}?membership_level=${membership_level_str}&shipid=${shipment_number}&FinalPrice=${finalPrice65}&FinalKG=${finalKGF6}&TaxType=${selectedPaymentMethodF3}&totalSKUPromoQty=${0}`)
        .then((res) => {
          //console.log("coupon success")
          isSuccessful = true;
        }).catch((err) => {
          //console.log("failed to get coupon")
          isSuccessful = false;
        });

      if (isSuccessful) {
        const jwt = new URL(window.location.href).searchParams.get('token')
        const axiosConfig: AxiosRequestConfig = {
          headers: { Authorization: `Bearer ${jwt}` }
        }
        await axios
          .post(`${process.env.REACT_APP_BACKEND_URL}/update-coupon`, { promo_code_id: promoObjF5.promo_code_string }, axiosConfig)
          .then((res) => {
            isSuccessful = true;
          }).catch((err) => {
            isSuccessful = false;
          });
      }
    } else {
      isSuccessful = true;
    }
    return isSuccessful;
  }

  const IFReconPayment = useCallback(async () => {
    setActivePage(8);
    let language: string = params.language === 'zh' ? "zh" : "en";
    try {
      const orderId = shipment_number;
      const timestampStr = Date.now().toString();

      // Calculate how many characters from the timestamp we can use
      const charsNeeded = 23 - orderId.length;

      // Append the last `charsNeeded` characters from the timestamp to the order ID
      const uniqueId = orderId + timestampStr.slice(-charsNeeded);

      let getOrginAmount = Math.round((finalPrice92 + insuranceVal - SKUPromoPriceF6 - promoPriceF6 - autoPromoPriceF6 /*+ farPostalCodeFee*/ + tax1F6 + tax2F6 + tax3F6 + overCharge1 + overCharge2 - pointDiscount) * 100) / 100;

      let shippingfee = Math.round((finalPrice65 + insuranceVal - SKUPromoPriceF6 - promoPriceF6 - autoPromoPriceF6 /*+ farPostalCodeFee*/) * 100) / 100;

      let taxfee = Math.round((tax1F6 + tax2F6 + tax3F6) * 100) / 100;

      let OverChargefee = overCharge1 + overCharge2;

      let getAmount = shippingfee + taxfee + OverChargefee - pointDiscount;

      let getAddPoint = Math.round((finalPrice65 - SKUPromoPriceF6 - promoPriceF6 - autoPromoPriceF6) * 100) / 100 - pointDiscount;


      let isSuccessful = false;
      let promoCode = ""
      if (promoObjF5 != null) {
        promoCode = promoObjF5.promo_code_string;
        isSuccessful = await CheckCoupon(promoCode,membership_level_str,shipment_number,finalPrice65,finalKGF6,0,selectedPaymentMethodF3);
      } else {
        isSuccessful = true;
      }

      let autoPromoCode = ""
      if(isSuccessful){
        if (autoPromoObjF5 != null) {
          autoPromoCode = autoPromoObjF5.promo_code_string;
          isSuccessful = await CheckCoupon(autoPromoCode,membership_level_str,shipment_number,finalPrice65,finalKGF6,1,selectedPaymentMethodF3);
        } else {
          isSuccessful = true;
        }
      }
      if(!isSuccessful){
        setActivePage(10);
        return;
      }

      if(getAmount <= 0){
        getAmount = 0;
        getOrginAmount = 0;
        getAddPoint = 0;
        const PayData:IPayFree = {
          getAmount:getAmount,
          getOrginAmount:getOrginAmount,
          shippingfee:shippingfee,
          taxfee:taxfee,
          OverChargefee:OverChargefee,
          getAddPoint:getAddPoint,
          promoCode:promoCode,
          autoPromoCode:autoPromoCode,
          language:language,
        }
        PayByFree(PayData);
        return;
      }


      const postJson = {
        getOrginAmount : getOrginAmount,
        getAmount : getAmount,
        taxfee : taxfee,
        shippingfee : shippingfee,
        OverChargefee : OverChargefee,
        getAddPoint : getAddPoint,
        promoCode : promoCode,
        autoPromoCode : autoPromoCode,
      }

      const TotalPrice = Math.round((finalPrice65 + insuranceVal - SKUPromoPriceF6 - promoPriceF6 - autoPromoPriceF6 /*+ farPostalCodeFee*/ + tax1F6 + tax2F6 + tax3F6 + overCharge1 + overCharge2 - pointDiscount) * 100);
      const merRef = uniqueId;

      //const currentUrl = window.location.href;
      const returnUrl = `${process.env.REACT_APP_BACKEND_URL}/Recon/payment_notification`;
      const notifyUrl = `${process.env.REACT_APP_BACKEND_URL}/Recon/payment_result?shipmentNum=${shipment_number}`;
      // Your Django Endpoint to get the `sign` field
      const endpoint = `${process.env.REACT_APP_BACKEND_URL}/Recon/CreatePay`;
      // The parameters (excluding `sign` and `signType`)
      const params = {
        merCode: "",
        merRef: `${merRef}`,
        amt: TotalPrice,
        curr: "HKD",
        desc: `${shipment_number}`,
        lang: "en",
        notifyUrl: notifyUrl,
        returnUrl: returnUrl,
        sign: "",
        signType: "SHA-256",
        timeout: 20,
        ver: 1,
      };

      // Fetch the `sign` from the Django server
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
      });

      if (!response.ok) throw new Error('Network response was not ok' + response.statusText);

      const data = await response.json();

      await CreateReconPayment(postJson,merRef);

      // Now `data` should contain the `sign` field from the server.
      const form = document.createElement('form');
      document.body.appendChild(form);
      form.method = 'post';
      form.action = `${process.env.REACT_APP_RECON_URL}/ws/b2cPay`;

      // Adding the returned `sign` to the params
      params.amt = data.amt;
      params.merCode = data.merCode;
      params.sign = data.sign;
      params.signType = "SHA-256";

      // Append the fields to the form
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const hiddenField = document.createElement('input');
          hiddenField.type = 'hidden';
          hiddenField.name = key;
          hiddenField.value = params[key as keyof typeof params].toString();
          form.appendChild(hiddenField);
        }
      }
      
      form.submit();
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
    }
  }, [finalPrice65, insuranceVal, promoPriceF6,autoPromoPriceF6,pointDiscount]);

  const PayByFree = async (PayData:IPayFree) => {
    IFsetActivePage(8);
    const jwt = new URL(window.location.href).searchParams.get('token');

      const axiosConfig: AxiosRequestConfig = {
        headers: { Authorization: `Bearer ${jwt}` }
      }
      let language: string = params.language === 'zh' ? "zh" : "en";
      const insertPaymentData = {
        "request": jsonData!!.request.request_id,
        "payment_order_id": jsonData!!.request.shipment_number,
        "payment_currency_code": "hkd",
        "payment_amount": PayData.getAmount,
        "payment_net_amount": PayData.getAmount,
        "payment_orgin_amount": PayData.getOrginAmount,
        "payment_shipping_fee": PayData.shippingfee,
        "payment_tax_fee": PayData.taxfee,
        "payment_over_charge":PayData.OverChargefee,
        "payment_status": "SUCCESS",
        "usedPoint": pointDiscount * 20,
        "couponCode": PayData.promoCode,
        "autoCouponCode": PayData.autoPromoCode,
        "lang": language,
        "SKUPromoCodeList" : SKUPromoCodeList?.matching_promo_codes ?? null,
      }
      
      axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/insertPaymentRecord`,
        insertPaymentData,
        axiosConfig
      ).then((res) => {
        IFsetActivePage(4);
      }).catch((err) => {
        IFsetActivePage(5);
      });
      return;
}


  const CreateReconPayment = async (postJson:IpostJson,merRef:string) => {

    
    const createPaymentEndpoint = `${process.env.REACT_APP_BACKEND_URL}/globe-payment/create_payment`;
    const paymentJson = {
      reference_number: merRef,
      request_id: `${jsonData!!.request.request_id}`,
      currency: "HKD",
      payment_amount: postJson.getAmount,
      payment_orgin_amount: postJson.getOrginAmount,
      payment_shipping_fee: postJson.shippingfee,
      payment_tax_fee: postJson.taxfee,
      payment_over_charge:postJson.OverChargefee,

      get_add_point : postJson.getAddPoint,
      promo_code: postJson.promoCode,
      auto_promo_code: postJson.autoPromoCode,
      used_point: pointDiscount * 20,
      SKUPromoCodeList:SKUPromoCodeList?.matching_promo_codes ?? null,
      language: params.language === 'zh' ? "zh" : "en",
    };
    try {
      const createGlobeResponse = await axios.post(createPaymentEndpoint, paymentJson, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if(createGlobeResponse.status >= 200 && createGlobeResponse.status < 300){
        setCookie("jwt", createGlobeResponse.data.token, 1);
        return;
      }
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
    }
  }

  //Recon Payment

  let prevStyle = {
    style: {
      border: '1px solid #000',
      borderRadius: '5px',
      color: '#000',
      padding: '10px 20px',
      outline: 'none',
    }, title: t("previousStepMF")
  };

  let nextStyle = {
    style: {
      border: '1px solid #000',
      borderRadius: '5px',
      color: '#000',
      padding: '10px 20px',
      outline: 'none',
    }, title: t("nextStepMF")
  };

  function getBlock1Data(): Record<string, any> {

    let itemList: UploadJSONItemObject[] = [];

    itemlist.map((value, index) => {
      let jsonObj: UploadJSONItemObject = {
        "items_no": value.item_no
        , "package_no": value.package_no
        , "unit_price": { "value": value.itemPrice }
      };
      itemList = [...itemList, jsonObj];
    })

    return {
      "request": {
        "paytax": selectedPaymentMethodF3,
        "total_price": {
          "value": totalPriceF3
        },
        "sender": {
          "name": contactNameF1,
          "email": contactEmailF1,
          "country_code": areaCodeF1,
          "number": contactPhoneF1,
        },
        "receiver": {
          "contact_name": contactNameF2,
          "company_name": companyNameF2,
          "tax_number": taxNumberF2,
          "area_code": areaCodeF2,
          "phone": contactPhoneF2,
          "email": contactEmailF2,
          "address_line_1": contactAddress1F2,
          "address_line_2": contactAddress2F2,
          "address_line_3": contactAddress3F2,
          "zip_code": zipCodeF2,
          "city": cityF2,
          "province": provinceF2,
          "country": countryF2,

        },
        "insurance": {
          "has_insurance": insuranceF3,
          "sum_assured": demoPrice
        }
      },
      "items": itemList
    }
  }


  return (
    <div className="" >
      {/* <div id="top"></div> */}

      <div className=''>
        {/* <MultiStep activeStep={0}   showNavigation={true}  prevButton={prevStyle} nextButton={nextStyle}> */}

        {showDialog && (
        <div className="backdrop">
          <LandingPage IFhandleHideDialog={handleHideDialog} 
                        sendLocal={sendLocal}
                        shipment_number={shipment_number}
                        />

        </div>
      )}
      
        {activePage === 1 && <Block1 title={t("FirstStep")}
          titleF1='寄件人'
          contactNameF1={contactNameF1}
          areaCodeF1={areaCodeF1}
          contactPhoneF1={contactPhoneF1}
          contactEmailF1={contactEmailF1}
          IFsetContactNameF1={IFsetContactNameF1}
          IFsetAreaCodeF1={IFsetAreaCodeF1}
          IFsetContactPhoneF1={IFsetContactPhoneF1}
          IFsetContactEmailF1={IFsetContactEmailF1}
          titleF2='收件人地址'
          contactNameF2={contactNameF2}
          companyNameF2={companyNameF2}
          taxNumberF2={taxNumberF2}
          areaCodeF2={areaCodeF2}
          contactPhoneF2={contactPhoneF2}
          contactEmailF2={contactEmailF2}
          contactAddress1F2={contactAddress1F2}
          contactAddress2F2={contactAddress2F2}
          contactAddress3F2={contactAddress3F2}
          zipCodeF2={zipCodeF2}
          cityF2={cityF2}
          provinceF2={provinceF2}
          countryF2={countryF2}
          IFsetContactNameF2={IFsetContactNameF2}
          IFsetCompanyNameF2={IFsetCompanyNameF2}
          IFsetTaxNumberF2={IFsetTaxNumberF2}
          IFsetAreaCodeF2={IFsetAreaCodeF2}
          IFsetContactPhoneF2={IFsetContactPhoneF2}
          IFsetContactEmailF2={IFsetContactEmailF2}
          IFsetContactAddress1F2={IFsetContactAddress1F2}
          IFsetContactAddress2F2={IFsetContactAddress2F2}
          IFsetContactAddress3F2={IFsetContactAddress3F2}
          IFsetZipCodeF2={IFsetZipCodeF2}
          IFsetCityF2={IFsetCityF2}
          IFsetProvinceF2={IFsetProvinceF2}
          IFsetCountryF2={IFsetCountryF2}
          titleF3='貨品清單'
          itemListsF3={itemlist}
          insuranceF3={insuranceF3}
          IFsetInsuranceF3={IFsetInsuranceF3}
          IFItemListsF3={IFItemListsF3}
          totalPriceF3={totalPriceF3}
          IFsetTotalPriceF3={IFsetTotalPriceF3}
          shipment_number={shipment_number}
          demoPrice={demoPrice}
          IFsetDemoPrice={IFsetDemoPrice}
          IFsetActivePage={IFsetActivePage}
          customAddressNoteF2={customAddressNoteF2}
          IFsetSingeCustomAddress={IFsetSingeCustomAddress}
          IFsetSaveCustomAddress={IFsetSaveCustomAddress}
          sendLocal={sendLocal}
          // far250fee={far250fee}
          selectedPaymentMethodF3={selectedPaymentMethodF3}
          IFsetSelectedPaymentMethodF3={IFsetSelectedPaymentMethodF3}
          exchangeRate={exchangeRate}
          showMsg1F3={showMsg1F3}
          showMsg2F3={showMsg2F3}
          IFsetShowMsg1={IFsetShowMsg1}
          IFsetShowMsg2={IFsetShowMsg2}
          IFsetProvinceSelectF2={IFsetProvinceSelectF2}
          handleHideDialog={handleHideDialog}
          customPKey={jsonData?.request.customer_pkey!!}
        />}

        {activePage === 2 && <Block2 title={t("SecondStep")}
          titleF4='確認訂單資料'
          contactNameF2={contactNameF2}
          contactAddress1F2={contactAddress1F2}
          contactAddress2F2={contactAddress2F2}
          contactAddress3F2={contactAddress3F2}
          areaCodeF2={areaCodeF2}
          contactPhoneF2={contactPhoneF2}
          contactEmailF2={contactEmailF2}
          zipCodeF2={zipCodeF2}
          contactNameF1={contactNameF1}
          areaCodeF1={areaCodeF1}
          contactPhoneF1={contactPhoneF1}
          contactEmailF1={contactEmailF1}
          titleF5='托運物品詳情'
          couponCodeF5={couponCodeF5}
          IFsetCouponCodeF5={IFsetCouponCodeF5}
          autoCouponCodeF5={autoCouponCodeF5}
          IFsetAutoCouponCodeF5={IFsetAutoCouponCodeF5}
          itemListsF5={itemlist}
          totalPriceF5={totalPriceF5}
          IFsetTotalPriceF5={IFsetTotalPriceF5}
          titleF6='結算'
          term1F6={term1F6}
          term2F6={term2F6}
          showStripeForm={showStripeForm}
          IFsetTerm1F6={IFsetTerm1F6}
          IFsetTerm2F6={IFsetTerm2F6}
          IFsetStripeButton={IFsetStripeButton}
          stripeForm={stripeForm}
          shipment_number={shipment_number}
          insuranceF3={insuranceF3}
          totalPriceF3={totalPriceF3}
          jsonData={jsonData!!}
          getBlock1Data={getBlock1Data}
          IFsetFinalPrice65={IFsetFinalPrice65}
          IFsetFinalPrice92={IFsetFinalPrice92}
          finalPrice65={finalPrice65}
          finalPrice92={finalPrice92}
          insuranceVal={insuranceVal}
          IFsetInsuranceVal={IFsetInsuranceVal}
          agreementMsg={agreementMsg}
          IFsetActivePage={IFsetActivePage}
          promoObjF5={promoObjF5 ?? null}
          autoPromoObjF5={autoPromoObjF5 ?? null}
          IFsetPromoObjF5={IFsetPromoObjF5}
          IFsetAutoPromoObjF5={IFsetAutoPromoObjF5}
          promoPriceF6={promoPriceF6}
          autoPromoPriceF6={autoPromoPriceF6}
          IFsetPromoPriceF6={IFsetPromoPriceF6}
          IFsetAutoPromoPriceF6={IFsetAutoPromoPriceF6}
          membership_level={membership_level}
          membership_level_str={membership_level_str}
          IFsetFirstLoadPage6={IFsetFirstLoadPage6}
          firstLoadPage6={firstLoadPage6}
          sendLocal={sendLocal}
          membership_after15kg={membership_after15kg}
          finalKGF6={finalKGF6}
          IFsetFinalKGF6={IFsetFinalKGF6}
          IFReconPayment={IFReconPay}
          // far250fee={far250fee}
          // IFsetFarPostalCodeFee={IFsetFarPostalCodeFee}
          // farPostalCodeFee={farPostalCodeFee}
          selectedPaymentMethodF3={selectedPaymentMethodF3}
          tax1F6={tax1F6}
          tax2F6={tax2F6}
          tax3F6={tax3F6}
          tax1Rate={tax1Rate}
          tax2Rate={tax2Rate}
          tax3Rate={tax3Rate}
          showCancelBoxP6={showCancelBoxP6}
          IFsetHideMsgBoxP6={IFsetHideMsgBoxP6}
          handleCheckboxChangeP6={handleCheckboxChangeP6}
          setOverCharge1={setOverCharge1}
          setOverCharge2={setOverCharge2}
          overCharge1={overCharge1}
          overCharge2={overCharge2}
          customPKey={jsonData?.request.customer_pkey!!}
          customPoint={customPoint}
          pointDiscount={pointDiscount}
          IFsetPointDiscount={IFsetPointDiscount}
          IFsetPointDiscountH={setPointDiscount}
          SKUPromoCodeList={SKUPromoCodeList}
          setSKUPromoCodeList={setSKUPromoCodeList}
          SKUPromoPriceF6={SKUPromoPriceF6}
          setSKUPromoPriceF6={setSKUPromoPriceF6}
          SaveData={SaveData}
          setAgreementMsg={setAgreementMsg}
          setPromoPoint={setPromoPoint}
          promoPoint={promoPoint}
          pageSixFirstLoad={pageSixFirstLoad}
          setPageSixFirstLoad={setPageSixFirstLoad}
          cumulativeKgQtyMinus={cumulativeKgQtyMinus}
          setCumulativeKgQtyMinus={setCumulativeKgQtyMinus}
          NewSKUPromoQtyF6={NewSKUPromoQtyF6}
          NewSKUPromoPriceF6={NewSKUPromoPriceF6}
          setNewSKUPromoPriceF6={setNewSKUPromoPriceF6}
        />}

        {activePage === 3 && <LoadUrlFailed />}

        {activePage === 4 && <ResultPage ShipmentNum={shipment_number} customPKey={jsonData?.request.customer_pkey!!} />}

        {activePage === 5 && <ResultPage2 />}

        {activePage === 6 && <LoadDateFailed />}

        {activePage === 7 && <ShipmentPaid ShipmentNum={shipment_number} customPKey={jsonData?.request.customer_pkey!!}/>}

        {activePage === 8 && <LoadingBlock />}

        {activePage === 9 && <PaidButErrorPage msg={errorNumber} />}

        {activePage === 10 && <CouponError />}

        {activePage === 11 && <MaintenancePage maintainMsg={maintainMsg} />}

        {activePage === 12 && <CancelPage Msg={cancelMsg} />}


        {/* </MultiStep> */}
      </div>


    </div>
  );
}

export default MainForm;




export interface IFormOneFormVal {
  title: string,
  contactNameF1: string,
  areaCodeF1: number,
  contactPhoneF1: string,
  contactEmailF1: string,
  IFsetContactNameF1(e: React.ChangeEvent<HTMLInputElement>): void,
  IFsetAreaCodeF1(e: React.ChangeEvent<HTMLInputElement>): void,
  IFsetContactPhoneF1(e: React.ChangeEvent<HTMLInputElement>): void,
  IFsetContactEmailF1(e: React.ChangeEvent<HTMLInputElement>): void,
  shipment_number: string,
  contactNameF1Err: boolean,
  areaCodeF1Err: boolean,
  contactPhoneF1Err: boolean,
  contactEmailF1Err: boolean,
  sendLocal: string,
  fillAllMsg: string,
  customPKey:string
}

export interface IFormTwoFormVal {
  title: string,
  contactNameF2: string,
  companyNameF2: string,
  taxNumberF2: string,
  areaCodeF2: number,
  contactPhoneF2: string,
  contactEmailF2: string,
  contactAddress1F2: string,
  contactAddress2F2: string,
  contactAddress3F2: string,
  zipCodeF2: string,
  cityF2: string,
  provinceF2: string,
  countryF2: string,
  IFsetContactNameF2(e: React.ChangeEvent<HTMLInputElement>): void,
  IFsetCompanyNameF2(e: React.ChangeEvent<HTMLInputElement>): void,
  IFsetTaxNumberF2(e: React.ChangeEvent<HTMLInputElement>): void,
  IFsetAreaCodeF2(e: React.ChangeEvent<HTMLInputElement>): void,
  IFsetContactPhoneF2(e: React.ChangeEvent<HTMLInputElement>): void,
  IFsetContactEmailF2(e: React.ChangeEvent<HTMLInputElement>): void,
  IFsetContactAddress1F2(e: React.ChangeEvent<HTMLInputElement>): void,
  IFsetContactAddress2F2(e: React.ChangeEvent<HTMLInputElement>): void,
  IFsetContactAddress3F2(e: React.ChangeEvent<HTMLInputElement>): void,
  IFsetZipCodeF2(e: React.ChangeEvent<HTMLInputElement>): void,
  IFsetCityF2(e: React.ChangeEvent<HTMLInputElement>): void,
  IFsetProvinceF2(e: React.ChangeEvent<HTMLInputElement>): void,
  IFsetCountryF2(e: React.ChangeEvent<HTMLInputElement>): void,
  contactNameF2Err: boolean,
  areaCodeF2Err: boolean,
  contactPhoneF2Err: boolean,
  contactEmailF2Err: boolean,
  contactAddress1F2Err: boolean,
  zipCodeF2Err: boolean,
  cityF2Err: boolean,
  countryF2Err: boolean,
  customAddressNoteF2: JSONTypeRequestReceiver[],
  IFsetSingeCustomAddress: (e: number) => void,
  IFsetSaveCustomAddress(e: number): void,
  sendLocal: string,
  IFsetProvinceSelectF2:(event: SelectChangeEvent)=>void,
  // far250fee: boolean,
}

export interface IItemList {
  itemID: number,
  item_no: number,
  itemNamezh: string,
  itemNameen: string,
  itemQty: number,
  itemPrice: number,
  itemTotalPrice: number,
  package_no: number,
  itemPriceErr: boolean,
  item_free_delivery_weight:number
}
export interface IItemLists {
  title: string,
  itemLists: IItemList[],
  IFItemLists(e: React.ChangeEvent<HTMLInputElement>, index: number): void,
  insuranceF3: boolean,
  IFsetInsuranceF3(e: React.ChangeEvent<HTMLInputElement>): void,
  totalPriceF3: number,
  IFsetTotalPriceF3(list: IItemList[]): void,
  demoPrice?: number,
  IFsetDemoPrice(e: React.ChangeEvent<HTMLInputElement>): void,
  sendLocal: string,
  selectedPaymentMethodF3: number,
  IFsetSelectedPaymentMethodF3(e: number): void,
  exchangeRate:number,
  showMsg1F3:boolean,
  showMsg2F3:boolean,
  IFsetShowMsg1():void,
  IFsetShowMsg2():void,
  shipment_number: string,
}


export interface IFormFourFormVal {
  title: string,
  contactNameF2: string,
  contactAddress1F2: string,
  contactAddress2F2: string,
  contactAddress3F2: string,
  contactPhoneF2: string,
  areaCodeF2: number,
  zipCodeF2: string,
  contactEmailF2: string,
  contactNameF1: string,
  areaCodeF1: number,
  contactPhoneF1: string,
  contactEmailF1: string,
  shipment_number: string,
  sendLocal: string,
  customPKey:string
}

export interface IFormFiveFormVal {
  title: string,
  couponCodeF5: string,
  IFsetCouponCodeF5(e: React.ChangeEvent<HTMLInputElement>): void,
  autoCouponCodeF5: string,
  IFsetAutoCouponCodeF5(e: React.ChangeEvent<HTMLInputElement>): void,
  itemLists: IItemList[],
  totalPriceF5: number,
  IFsetTotalPriceF5(e: number): void,
  promoObjF5: IPromoCode | null,
  IFsetPromoObjF5(e: IPromoCode | null): void,
  autoPromoObjF5: IPromoCode | null,
  IFsetAutoPromoObjF5(e: IPromoCode | null): void,
  finalPrice65: number,
  IFsetPromoPriceF6(e: number): void,
  IFsetAutoPromoPriceF6(e: number): void,
  membership_level_str: string,
  shipment_number: string,
  sendLocal: string,
  finalKGF6: number,
  jsonData: JSONType,
  customPoint: number,
  setPromoPoint:(e:number)=>void,
  selectedPaymentMethodF3: number,
  SKUPromoCodeList:AutoSKUPromoCodeResponse | null,
  cumulativeKgQtyMinus: number,
}

export interface IFormSixFormVal {
  shipment_number: string,
  title: string,
  term1F6: boolean,
  term2F6: boolean,
  insuranceF3: boolean,
  totalPriceF3: number,
  jsonData: JSONType,
  IFsetFinalPrice65(e: number): void,
  IFsetFinalPrice92(e: number): void,
  finalPrice65: number,
  IFsetTerm1F6(e: React.ChangeEvent<HTMLInputElement>): void,
  IFsetTerm2F6(e: React.ChangeEvent<HTMLInputElement>): void,
  insuranceVal: number,
  IFsetInsuranceVal(e: number): void,
  promoObjF5: IPromoCode | null,
  autoPromoObjF5: IPromoCode | null,
  promoPriceF6: number,
  autoPromoPriceF6:number,
  showStripeForm: boolean,
  membership_level: number,
  membership_level_str: string,
  IFsetPromoObjF5(e: IPromoCode | null): void,
  IFsetAutoPromoObjF5(e: IPromoCode | null): void,
  IFsetPromoPriceF6(e: number): void
  IFsetAutoPromoPriceF6(e: number): void,
  IFsetFirstLoadPage6(): void,
  firstLoadPage6: boolean,
  sendLocal: string,
  membership_after15kg: number,
  IFsetFinalKGF6(e: number): void,
  // far250fee: boolean,
  // IFsetFarPostalCodeFee(e: number): void,
  // farPostalCodeFee: number,
  selectedPaymentMethodF3: number,
  tax1F6: number,
  tax2F6: number,
  tax3F6: number,
  tax1Rate: number,
  tax2Rate: number,
  tax3Rate: number,
  showCancelBoxP6: boolean,
  IFsetHideMsgBoxP6(): void,
  handleCheckboxChangeP6: (event: React.ChangeEvent<HTMLInputElement>) => void,
  setOverCharge1: (e: number) => void,
  setOverCharge2: (e: number) => void,
  overCharge1: number,
  overCharge2: number,
  customPoint: number,
  pointDiscount:number,
  IFsetPointDiscount:(e:React.ChangeEvent<HTMLInputElement>)=>void,
  IFsetPointDiscountH:(e:number)=>void,
  SKUPromoCodeList:AutoSKUPromoCodeResponse | null,
  setSKUPromoCodeList:(e:AutoSKUPromoCodeResponse)=>void,
  SKUPromoPriceF6:number,
  setSKUPromoPriceF6:(e:number)=>void,
  setPromoPoint:(e:number)=>void,
  promoPoint:number,
  pageSixFirstLoad:boolean,
  setPageSixFirstLoad:(e:boolean)=>void,
  setCumulativeKgQtyMinus:(e:number)=>void,
  NewSKUPromoQtyF6:number,
  NewSKUPromoPriceF6:number,
  setNewSKUPromoPriceF6:(e:number)=>void,
}



//PromoCode InterFace
export interface IPromoCode {
  promo_code_string: string,
  promo_code_detail: string,
  promo_code_multi: number,
  promo_code_minus: number,
  promo_code_minus_kg: number,
  promo_code_point: number,
}

export interface AutoSKUPromoCodeRequest {
  shipid: string,
  TaxType: number,
  FinalKG: number,
}

export interface AutoSKUPromoCodeResponse {
  matching_promo_codes: {
    promo_code_id: number;
    promo_code_string: string;
    promo_code_detail: string;
    promo_code_minus: number;
    promo_code_minus_kg: number;
    promo_code_minus_kg_qty: number;
  }[];
}

export interface NewAutoSKUPromoCodeResponse {
  getDiscount: number;
  getDiscountKG: number;
}


//JsonType
export interface JSONType {
  request: JSONTypeRequest,
  packages: JSONTypePackages[],
  items: JSONTypeItems[],
  customer_address: JSONTypeRequestReceiver[],
  customer_user: JSONTypeCustomerUser,
}

export interface JSONTypeCustomerUser {
  customer_user_id: string,
  sender: JSONTypeCustomerUserSender,
}

export interface JSONTypeCustomerUserSender {
  point: number,
}

export interface JSONTypeRequest {
  ac_id: string,
  customer_pkey: string,
  order_number: string,
  membership_level: MembershipLevels | string,
  request_id: number,
  shipment_number: string,
  request_location: Locations | string,
  request_paytax: number,
  receiver: JSONTypeRequestReceiver,
  sender: JSONTypeRequestSender,
  total_price: JSONTypeRequestTotal_price,
  insurance: JSONTypeRequestInsurance
  request_status?: string,
  create_on: string,
}

export interface JSONTypeRequestInsurance {
  has_insurance?: boolean,
  sum_assured?: number,
}

export interface JSONTypeRequestReceiver {
  address_line_1?: string,
  address_line_2?: string,
  address_line_3?: string,
  area_code?: number,
  city?: string,
  company_name?: string,
  contact_name?: string,
  country?: string,
  email?: string,
  phone?: string,
  province?: string,
  tax_number?: string,
  zip_code?: string,
}

export interface JSONTypeRequestSender {
  country_code: string,
  email: string,
  name: string,
  number: string,
}

export interface JSONTypeRequestTotal_price {
  currency: string,
  value: number,
}

export interface JSONTypePackages {
  ac: string,
  package_no: number,
  packages_id: number,
  shipment_number: string,
  dimension: JSONTypePackagesDimension,
  weight: JSONTypePackagesWeight,
}

export interface JSONTypePackagesDimension {
  height: number,
  length: number,
  unit: string,
  width: number,
}

export interface JSONTypePackagesWeight {
  unit: string,
  value: number,
}

export interface JSONTypeItems {
  ac: string,
  items_category_en: string,
  items_id: number,
  items_no: number,
  items_origin: string,
  items_qty: number,
  package_no: number,
  name: JSONTypeItemsName,
  unit_price: JSONTypeItemsUnit_price,
  item_free_delivery_weight:number
}

export interface JSONTypeItemsName {
  zh: string,
  en: string,
}

export interface JSONTypeItemsUnit_price {
  currency: string,
  value: number,
}

// export interface JSONTypeCustomer_address{
//   address_line_1?:string,
//   address_line_2?:string,
//   address_line_3?:string,
// }

export interface JSONTypeCustomer_address {
  receiver: JSONTypeRequestReceiver,
}

// a interface JSONTypeRequestReceiver
// export interface IJSONTypeRequestReceiver{
//   address_line_1:string,
//   address_line_2:string,
//   address_line_3:string,
// }


export interface IpostJson {
  getOrginAmount: number,
  getAmount: number,
  taxfee: number,
  shippingfee: number,
  OverChargefee: number,
  getAddPoint: number,
  promoCode: string,
  autoPromoCode: string
}