import React from 'react';
import TextField from '@mui/material/TextField';
import {Container, Row,Col} from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import InputAdornment from '@mui/material/InputAdornment';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export interface IProductList{
    itemIndex:number,
    itemID:number,
    itemName:string,
    itemQty:number,
    itemPrice:number,
    itemPriceErr:boolean,
    sendLocal:string,
    IFItemLists(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,index:number):void,
  }

function ProductList(props:IProductList) {
  //lan
  const { t } = useTranslation();

  return (
    <div className="App">
        <h4 className='text-start fw-bold mt-3'>{t("productTitleF3P")}{props.itemIndex} : </h4>
        <Container>
                    <Row>
                      <Col xs="12" md="12" lg="6" className='non-p'>
                        <TextField

                            id="productDesc"
                            name="productDesc"
                            className='mt-3 cpe-2'
                            fullWidth
                            aria-readonly
                            label={t("productDescLabelF3P")}
                            value={props.itemName} 
                            type='text'
                        />
                      </Col>
                      <Col xs="6" md="6" lg="2" className='non-p'>
                        <TextField
                            
                            id="productQty"
                            name="productQty"
                            className='mt-3 pe-2 '
                            fullWidth
                            aria-readonly
                            label={t("productQtyLabelF3P")}
                            value={props.itemQty} 
                            type='number'
                        />
                      </Col>
                      <Col xs="6" md="6" lg="4" className='non-p'>

                          <TextField
                            required
                            error={props.itemPriceErr}
                            id="productValue"
                            name="productValue"
                            className='mt-3'
                            fullWidth
                            label={t("productValueLabelF3P")}
                            value={props.itemPrice === 0 ? '':props.itemPrice} 
                            onChange={(e) => {props.IFItemLists(e, props.itemID)}} 
                            type='number'
                            InputProps={{
                              startAdornment: <InputAdornment position="start">HKD</InputAdornment>,
                            }}
                          /> 


                          {props.sendLocal === 'AU'  && <div className={`color-gray text-start mt-2 font-size-t9`}>
                            {<InfoOutlinedIcon fontSize="small"/>}{t("productListRemarksF3AUP")}
                          </div>}
                          

                      </Col>
                    </Row>
                  </Container>

  </div>
  );
}

export default ProductList;

