import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import {Container, Row,Col} from "react-bootstrap";

interface IAlertComponent {
    alertMsg:string,
    alertType:string,
    alertCustom:string,
    IFsetShowOff():void,
}


function AlertComponent(props:IAlertComponent) {

    return (
                    <Alert variant={props.alertType} className={props.alertCustom} onClose={() => props.IFsetShowOff()} dismissible>
                        <div dangerouslySetInnerHTML={{ __html: props.alertMsg }} />
                    </Alert>
    );
}

export default AlertComponent;

