

import { useTranslation } from 'react-i18next';

export interface IPaidButErrorPage{
  msg:string,
}


function PaidButErrorPage(props:IPaidButErrorPage) {

  const { t } = useTranslation();

  return (
    <div className="">

                    <fieldset className="border radius-10 mt-5 text-start p-5 backcolor-gray">
                      <div className='text-start'>
                        <div className='next-line-if-lf fw-bold '>{t("PaidButErrorMsg")}</div>
                        <p>Error Code : {props.msg}</p>
                      </div>
                    </fieldset>
   
          
  </div>
  );
}

export default PaidButErrorPage;

