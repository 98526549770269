import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { Container, FormGroup, Row, Col, Button } from "react-bootstrap";
import { IItemLists, Quotation0910Data, defaultCurrencyName, defaultTaxRate } from '../../MainForm';
import ProductList from './FormItem/ProductList';
import { useTranslation } from 'react-i18next';
import { Grid, Radio } from '@mui/material';
import AlertComponent from '../BlockAlert/AlertComponent';

function ThridPage(props: IItemLists) {
  //lan
  const { t } = useTranslation();

  let getlan = localStorage.getItem("lng");

  const PriceDemoResultLabel = <h5 className='text-start mt-3 fw-bold'>{t("SumInsuredF3")} {t("PriceDemoCurrencyLabelF3")} {props.totalPriceF3.toLocaleString() ?? 0} x 3%
    = <span className='color-red text-decoration-underline'>{t("PriceDemoCurrencyLabelF3")}{(Math.round((props.totalPriceF3 ?? 0) * 0.03 * 100) / 100).toLocaleString()}</span></h5>

  useEffect(() => {
    props.IFsetTotalPriceF3(props.itemLists);
  });

  const checkboxLabelF3 = props.sendLocal === 'UK' ? "checkboxLabelF3" : "checkboxLabelF3AU";
  const checkboxtipLabelF3 = props.sendLocal === 'UK' ? "checkboxtipLabelF3" : "checkboxtipLabelF3AU";

  const showExchangeRate = Math.round(props.exchangeRate * 100) / 100;

  const showAfterExchangeRate = Math.round(props.totalPriceF3 * props.exchangeRate * 100) / 100

  const getCurrencyName = defaultCurrencyName[props.sendLocal as keyof typeof defaultTaxRate];

  let shipment_period = parseInt(props.shipment_number.substring(1, 7)); // Converts '231127' from 'H231127...' to an integer.

  const DDPMSG = {
    "UK": t("DDPUKF3"),
    "CA": t("DDPCAF3"),
    "AU": t("DDPAUF3"),
  }

  const DDUMSG = {
    "UK": t("DDUUKF3"),
    "CA": t("DDUCAF3"),
    "AU": t("DDUAUF3"),
  }


  return (
    <div className="">

      <Container>
        <Row>
          <Col xs="12" md="12" lg="12" className='non-p'>
            <h1 className='fw-bold text-start'>{t("MainTitleF3")}</h1>
            <h4 className='fw-bold text-start mt-3 mb-3'>{t("subTitle1F3")}</h4>

            {props.sendLocal === 'UK' && (
              <div className='fw-bold text-start font-size-t7'>
                {t("currencLabelP3P")}
              </div>
            )}

            {props.itemLists.map((value, index) => {
              return <ProductList key={index + 1}
                itemID={value.itemID}
                itemIndex={index + 1}
                itemName={getlan === 'en' ? value.itemNameen : value.itemNamezh}
                itemQty={value.itemQty}
                itemPrice={value.itemPrice}
                itemPriceErr={value.itemPriceErr}
                IFItemLists={props.IFItemLists}
                sendLocal={props.sendLocal}></ProductList>
            })}

            <hr />
            <div className='float-start color-gray'>{t("WarmReminderF3")}</div>
            <div className='text-end fw-bold'><h3>{t("TotalPriceLabelF3")}</h3></div>
            <div className='text-end fw-bold'><h3>HKD {props.totalPriceF3.toLocaleString()}</h3></div>

            <div className='text-end color-lightblue'>
              <div>預計匯率</div>
              <div>HKD {props.totalPriceF3} (1 HKD = {showExchangeRate} {getCurrencyName}) <span className='fw-bold'>{getCurrencyName} {showAfterExchangeRate}</span></div>
            </div>

            <div className={`text-start mt-3 ${props.sendLocal === 'UK' ? 'color-coral' : 'color-gray fw-bold'}`}>
              {t(props.sendLocal === 'UK' ? "UKTaxTipsLabelF3" : props.sendLocal === 'CA' ? "UKTaxTipsLabelF3CA" : "UKTaxTipsLabelF3AU")}
            
              {props.sendLocal == 'CA' && <div className={` mt-3 color-darkgray fw-bold`}>
                {t("TipsSubSubLabelF3CA")}
              </div>}

              <div className={` mt-3 ${props.sendLocal === 'UK' ? 'color-red' : 'color-gray'}`}>
                {t(props.sendLocal === 'UK' ? "TipsSubLabelF3UK" : props.sendLocal === 'CA' ? "TipsSubLabelF3CA" : "TipsSubLabelF3AU")}
              </div>
            </div>

            {/* {props.sendLocal == 'CA' && <div className='text-start mt-3 color-lightblue'>
              <a href={t("WithTaxSamplePathF3CA")} target='_blank'>
                {t("TaxSampleF3CA")}
              </a>
            </div>} */}

            <hr />

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h3 className='text-start mt-3 fw-bold '>{t("TaxPaymentTitleNew1F3")}</h3>
              <div style={{ flex: 1, display: 'flex' }}>
                {/* <span className='color-red'>{t("TaxPaymentSubTitleF3")}</span> */}
              </div>
            </div>

            <Grid container direction="column" alignItems="flex-start" className='font-size-t9'> {/* Align items to the left */}
              {((props.sendLocal === 'UK' || (props.sendLocal === 'CA' && shipment_period >= 231208 && shipment_period <= 240118)) && shipment_period <= Quotation0910Data) && 
                <>
                <Grid item>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FormControlLabel
                    control={
                      <Radio
                        onChange={() => props.IFsetSelectedPaymentMethodF3(2)}
                        checked={props.selectedPaymentMethodF3 == 2}
                        value={t("PayFreeTaxF3")}
                        disabled={
                          (props.sendLocal === 'UK' && ((shipment_period >= 231206 && props.totalPriceF3 > 2500) || (shipment_period < 231206 && props.totalPriceF3 > 1500))) ||
                          (props.sendLocal === 'CA' && ((shipment_period >= 231218 && props.totalPriceF3 > 800) || (shipment_period < 231218 && props.totalPriceF3 > 600)))
                        }
                      />
                    }
                    label={t("PayFreeTaxF3")}
                    className='font-size-t9'
                  />
                  <span className='flex-start  fw-bold '>
                    {/* {t("PayWithTaxSubUKF3")} */}
                    {props.sendLocal === 'UK' && (shipment_period >= 231206 ? t("PayFreeTaxContentNewF3") : t("PayFreeTaxContentF3"))}
                    {props.sendLocal === 'CA' && (shipment_period >= 231218 ? t("PayFreeTaxContentNewF3CA") : t("PayFreeTaxContentF3CA"))}
                    
                  </span>
                  
                </div>
                
              </Grid>
              <Grid item>
                <div className='color-blue fw-bold ps-5'>
                {t(props.sendLocal === 'UK' ? "PayWithTaxSubUKF3" : props.sendLocal === 'CA' ? "PayWithTaxSubCAF3" : "PayWithTaxSubAUF3")}
                </div>
              </Grid>
              </>
              }

              <Grid item>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FormControlLabel
                    control={
                      <Radio
                        onChange={() => props.IFsetSelectedPaymentMethodF3(3)}
                        checked={props.selectedPaymentMethodF3 == 3}
                        value={t("PayTaxAirFlightF3")}
                        disabled={props.totalPriceF3 > 5000}
                      />
                    }
                    label={t("PayTaxAirFlightF3")}
                  />
                  <span className='flex-start  fw-bold '>
                    {t("PayTaxAirFlightContentF3")}
                  </span>
                </div>

              </Grid>
              <Grid item>
                <div className='color-blue ps-5 fw-bold'>{t("PayTaxAirFlightUKF3")}</div>
              </Grid>

              {shipment_period >= 240617 && <>
                <Grid item>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FormControlLabel
                      control={
                        <Radio
                          onChange={() => props.IFsetSelectedPaymentMethodF3(4)}
                          checked={props.selectedPaymentMethodF3 == 4}
                          value={t("PayTaxShipFlightF3")}
                          disabled={props.totalPriceF3 > 2500}
                        />
                      }
                      label={t("PayTaxShipFlightF3")}
                    />
                    <span className='flex-start fw-bold '>
                      {t("PayTaxShipFlightContentF3")}
                    </span>
                  </div>

                </Grid>
                <Grid item>
                  <div className='color-blue fw-bold ps-5'>{t("PayTaxShipFlightUKF3")}</div>
                </Grid>
                {props.selectedPaymentMethodF3 == 4 && 
                  <Container>
                    <Row >
                      <Col xs="12" md="12" lg="10" className=''>
                        <AlertComponent alertMsg={t('SEAUKF3')} alertType={"info"} IFsetShowOff={props.IFsetShowMsg1} alertCustom='text-start' />
                      </Col>
                    </Row>
                  </Container>
                }
              </>}


              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h3 className='text-start mt-3 fw-bold '>{t("TaxPaymentTitleNew2F3")}</h3>
                <div style={{ flex: 1, display: 'flex' }}>
                  <span className='ps-3 fw-bold'>{t("PayWithoutTaxContentF3")}</span>
                </div>
              </div>

              {shipment_period <= Quotation0910Data && <Grid item>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FormControlLabel
                    control={
                      <Radio
                        onChange={() => props.IFsetSelectedPaymentMethodF3(1)}
                        checked={props.selectedPaymentMethodF3 == 1}
                        value={t("PayWithTaxF3")}
                      />
                    }
                    label={t("PayWithTaxF3")}
                  />
                  <span className='flex-start color-blue fw-bold '>
                    {/* {t(props.sendLocal === 'UK' ? "PayWithTaxSubUKF3" : props.sendLocal === 'CA' ? "PayWithTaxSubCAF3" : "PayWithTaxSubAUF3")} */}
                  </span>
                </div>
                <Grid item>
                  <div className='color-blue fw-bold ps-5'>{t(props.sendLocal === 'UK' ? "PayWithTaxSubUKF3" : props.sendLocal === 'CA' ? "PayWithTaxSubCAF3" : "PayWithTaxSubAUF3")}</div>
                </Grid>
              </Grid>}

              <Grid item>
                {props.selectedPaymentMethodF3 == 1 && <Container>
                  <Row >
                    <Col xs="12" md="12" lg="10" className=''>
                      <AlertComponent alertMsg={DDPMSG[props.sendLocal as keyof typeof DDPMSG]} alertType={"info"} IFsetShowOff={props.IFsetShowMsg1} alertCustom='text-start' />
                    </Col>
                  </Row>
                </Container>
                }
              </Grid>
              <Grid item>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FormControlLabel
                    control={
                      <Radio
                        onChange={() => props.IFsetSelectedPaymentMethodF3(0)}
                        checked={props.selectedPaymentMethodF3 == 0}
                        value={t("PayWithoutTaxF3")}
                      />
                    }
                    label={t("PayWithoutTaxF3")}
                  />
                  <span className='flex-start color-blue fw-bold '>
                    
                  </span>
                </div>
                <Grid item>
                  <div className='color-blue fw-bold ps-5'>
                    {shipment_period < Quotation0910Data ?
                      <>{t(props.sendLocal === 'UK' ? "PayWithTaxSubUKF3" : props.sendLocal === 'CA' ? "PayWithTaxSubCAF3" : "PayWithTaxSubAUF3")}</>
                      : <>{t("PayWithoutTaxSubF3")}</>
                    }
                  </div>
                </Grid>
              </Grid>
              <Grid item>
                {props.selectedPaymentMethodF3 == 0 && <Container>
                  <Row >
                    <Col xs="12" md="12" lg="10" className=''>
                      <AlertComponent alertMsg={DDUMSG[props.sendLocal as keyof typeof DDUMSG]} alertType={"info"} IFsetShowOff={props.IFsetShowMsg2} alertCustom='text-start' />
                    </Col>
                  </Row>
                </Container>
                }
              </Grid>
              {/* <Grid item>
                <div className='color-red'>{t("PayWithoutTaxContentF3")}</div>
              </Grid> */}


              {shipment_period >= 240617 && <>
                <Grid item>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FormControlLabel
                      control={
                        <Radio
                          onChange={() => props.IFsetSelectedPaymentMethodF3(5)}
                          checked={props.selectedPaymentMethodF3 == 5}
                          value={t("PayWithoutTaxShipFlightF3")}
                        />
                      }
                      label={t("PayWithoutTaxShipFlightF3")}
                    />
                    <span className='flex-start color-blue fw-bold '>
                      
                    </span>
                  </div>
                  {/* <Grid item>
                    <div className='color-red'>{t("PayWithoutTaxContentF3")}</div>
                  </Grid> */}
                  <Grid item>
                    <div className='color-blue fw-bold ps-5'>
                      {t("PayTaxShipFlightUKF3")}
                    </div>
                  </Grid>
                </Grid>
                {props.selectedPaymentMethodF3 == 5 && 
                  <Container>
                    <Row >
                      <Col xs="12" md="12" lg="10" className=''>
                        <AlertComponent alertMsg={t('SEAUKF3')} alertType={"info"} IFsetShowOff={props.IFsetShowMsg1} alertCustom='text-start' />
                      </Col>
                    </Row>
                  </Container>
                }
              </>}

            </Grid>

            <div className='text-start mt-5 fw-bold'>{t("TaxContentremarksF3")}</div>

            <hr />

            <h5 className='text-start mt-3 fw-bold'>{t("subTitle2F3")}</h5>
            <h5 className='text-start mt-3'>{t("subTitle3F3")}</h5>

            <FormControlLabel control={<Checkbox onChange={props.IFsetInsuranceF3} checked={props.insuranceF3} />} label={t(checkboxLabelF3)} className="float-start" />

            <div className="float-start mt-2 color-coral">{t(checkboxtipLabelF3)}</div>

            <a href={t("InsurancePolicyPDF")!!} target="_blank" className='float-start mt-2 ms-3' >{t("InsurancePolicyLabelF3")}</a>
          </Col>
        </Row>

        <Row>
          <Col xs="12" md="12" lg="8" className=' mt-3'>
            {props.insuranceF3 && PriceDemoResultLabel}

          </Col>
        </Row>
      </Container>







    </div>
  );
}

export default ThridPage;

