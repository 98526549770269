import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import {StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import axios, { AxiosRequestConfig } from 'axios';
import { useEffect, useRef, useState } from 'react';
import {Form, Button} from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner';
import { useParams } from 'react-router-dom';
import { UploadJSONItemObject } from '../page/Block/Block1';
import { AutoSKUPromoCodeResponse } from '../page/MainForm';

const stripePromise = loadStripe('pk_live_51N15aBBNkTsadMm6gpVpSwOuhlpeNt7YqUzIcx0pJh1jlOmwldg8y8PoPEDXyTF3cfaMHU3Z8HNIp94yRUXvbbEm006IENebHX');
const testStripePromise = loadStripe('pk_test_51MzHR8B0Ndba8r35ADNxtzPTgO6ZXbZtBtZ76to5IVDKAHia3tjq55C9l10LhPX1QBw8UonG5mUiR9dy5RFmCIR700FLiV431M');

export const CheckCoupon = async (promoCode: string,membership_level_str:string,shipment_number:string,finalPrice65:number,finalKGF6:number,autoapply:number,TaxType:number): Promise<boolean> => {
    let FisSuccessful = false;
    await axios
    .get(`${process.env.REACT_APP_BACKEND_URL}/promo-code/${promoCode}?membership_level=${membership_level_str}&shipid=${shipment_number}&FinalPrice=${finalPrice65}&FinalKG=${finalKGF6}&autoapply=${autoapply}&TaxType=${TaxType}&totalSKUPromoQty=0`)
    .then((res) => {
        //console.log("coupon success")
        FisSuccessful = true;
    }).catch((err) => {
        //console.log("failed to get coupon")
        FisSuccessful = false;
    });

    if(FisSuccessful){
        //console.log("edit coupon")
        const jwt = new URL(window.location.href).searchParams.get('token')
            const axiosConfig:AxiosRequestConfig = {
                headers:{Authorization: `Bearer ${jwt}`}
            }
        await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/update-coupon`,{promo_code_id: promoCode},axiosConfig)
        .then((res) => {
            FisSuccessful = true;
        }).catch((err) => {
            FisSuccessful = false;
        });
    }

    return FisSuccessful;
}

interface StripeFormBaseProps{
    IFsetActivePage(e: number):void,
    getBlock1Data ():Record<string, any>,
    promoCode: string,
    autoPromoCode: string,
    membership_level_str:string,
    shippingfee:number,
    taxfee:number,
    setErrorNumber(e: string):void,
    finalPrice65:number,
    finalKGF6:number,
    overCharge:number,
    usedPoint:number,
    getPoint:number,
    SKUPromoCodeList:AutoSKUPromoCodeResponse | null,
}

interface StripeFormProps extends StripeFormBaseProps{
    clientSecret: string,
    shipment_number: string
}

function StripeCreditForm(props: StripeFormProps): JSX.Element {

    const stripe = useStripe();
    const elements = useElements();
    const params = useParams();
    let environments :string = params.environments ?? "";

    const [isdisable, setIsdisable] = useState(false)
    
    const mounted = useRef(false)
    useEffect(() => {
        if (mounted.current === false ) {
            console.log(1)
        }
    })

    const LogError = (errType:string, errCode:string, errMsg:string,orderId:string, shipment_number:string) => {
        const jwt = new URL(window.location.href).searchParams.get('token')
        const axiosConfig:AxiosRequestConfig = {
            headers:{Authorization: `Bearer ${jwt}`}
        }
        const errorData = {
            error_type: errType,
            error_code: errCode,
            error_message: errMsg,
            shipment_number: shipment_number,
            orderId: orderId
        }
        axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/stripe-error-log`, 
            errorData, 
            axiosConfig
        ).then((res) => {
            console.log("OK")
            //console.log(res.data)
        }).catch( (err) => {
            console.log("error")
            console.log(err)
        }) 
    }


    const handleSubmit = async (e:React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsdisable(true);

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        // console.log(`stripe and elements loaded ${elements}`);
        
        let urlObj = new URL(window.location.href)
        
        let isSuccessful: boolean = false;
        if (props.promoCode !== "") {
            isSuccessful = await CheckCoupon(props.promoCode,props.membership_level_str,props.shipment_number,props.finalPrice65,props.finalKGF6,0,0);
        } else {
            isSuccessful = true;
        }

        if(isSuccessful){
            if (props.autoPromoCode !== "") {
                isSuccessful = await CheckCoupon(props.autoPromoCode,props.membership_level_str,props.shipment_number,props.finalPrice65,props.finalKGF6,1,0);
            } else {
                isSuccessful = true;
            }
        }

        if (isSuccessful) {
            let language :string = params.language === 'zh' ? "zh" : "en";
            const queryParameters = new URLSearchParams(window.location.search);
            const jwt = queryParameters.get("token");

            const result = await stripe.confirmPayment({
                //`Elements` instance that was used to create the Payment Element
                elements,
                confirmParams: {
                return_url: urlObj.origin + `/c0501/${language}/p/AlipayResult?token=${jwt}`,
                },
                redirect: 'if_required' 
            });
            
            var confirmPaymentData: Record<string, any>
            props.IFsetActivePage(8);
            if (result.error) {
                // Show error to your customer (for example, payment details incomplete)
                const errType = result.error.type;
                const errMsg = result.error.message;
                const errCode = result.error.code;
                var orderId = props.clientSecret.split("_secret_")[0];
                //console.log(result.error);
                LogError(errType,errCode ?? "" ,errMsg ?? "",orderId, props.shipment_number);

                if (errType === "validation_error") {
                    props.IFsetActivePage(5);
                    return;
                } else {
                    
                    var block1Data = props.getBlock1Data();
                    
                    const jwt = new URL(window.location.href).searchParams.get('token')
                    const axiosConfig:AxiosRequestConfig = {
                        headers:{Authorization: `Bearer ${jwt}`}
                    }
                    confirmPaymentData = {
                        payment_id: orderId,
                        request: block1Data.request,
                        items: block1Data.items,
                        promo_code: props.promoCode ?? null,
                        auto_promo_code: props.autoPromoCode ?? null,
                    }
                    //console.log(confirmPaymentData) 
                    axios.post(
                        `${process.env.REACT_APP_BACKEND_URL}/stripe-payment/${props.shipment_number}/failed`,
                        confirmPaymentData, 
                        axiosConfig
                    ).then((res) => {
                        console.log("OK")
                        //console.log(res.data)
                    }).catch( (err) => {
                        console.log("error")
                        console.log(err)
                    }) 
                    props.IFsetActivePage(5);
                }
                // window.location.href = "/stripe-test/failed"
                SaveData(props);
            } else {
                var orderId = props.clientSecret.split("_secret_")[0];
                var block1Data = props.getBlock1Data();
                //console.log(orderId);
                const jwt = new URL(window.location.href).searchParams.get('token')
                const axiosConfig:AxiosRequestConfig = {
                    headers:{Authorization: `Bearer ${jwt}`}
                }

                confirmPaymentData = {
                    payment_id: orderId,
                    request: block1Data.request,
                    items: block1Data.items,
                    shippingfee: props.shippingfee,
                    taxfee: props.taxfee,
                    over_charge: props.overCharge,
                    promo_code: props.promoCode ?? null,
                    auto_promo_code: props.autoPromoCode ?? null,
                    lang: params.language === 'zh' ? "zh" : "en",
                    usedPoint:props.usedPoint * 20,
                    addPoint:props.getPoint,
                    SKUPromoCodeList:props.SKUPromoCodeList?.matching_promo_codes ?? null
                }

                 //console.log(confirmPaymentData);
                axios.post(
                    `${process.env.REACT_APP_BACKEND_URL}/stripe-payment/${props.shipment_number}/confirm`, 
                    confirmPaymentData, 
                    axiosConfig
                ).then((res) => {
                    console.log("OK")
                    //console.log(res.data)
                    SaveData(props);
                    props.IFsetActivePage(4);
                    // window.location.href = "/c0501/resultS"
                }).catch( (err) => {
                    console.log("error")
                    console.log(err)
                    console.error(err.response.status);
                    props.setErrorNumber(err.response.status);
                    SaveData(props);
                    let smsJson = {
                        errorcode: err.message,
                        payment_id: orderId,
                    }

                    axios.post(
                        `${process.env.REACT_APP_BACKEND_URL}/senderrorsms`, 
                        smsJson, 
                        axiosConfig
                    ).then((res) => {
                        console.log(res.data)
                    }).catch( (err) => {
                        console.log(err)
                    }) 

                    props.IFsetActivePage(9);   //Paid But Error Page
                    // window.location.href = "/c0501/resultF"
                }) 

            }

        } else {
            SaveData(props);
            props.IFsetActivePage(10);
        }   

    }
    

    return <Form id="stripe_form" onSubmit = {handleSubmit}>
        <PaymentElement />
        <div className='mt-3'>
            <Button type='submit' disabled={isdisable}>
                {isdisable ? <Spinner animation="border" role="status" className='me-2' size="sm" /> : null}
                Pay
            </Button>
        </div>
    </Form>
        
}

export function StripeForm (props: StripeFormProps): JSX.Element {

    const params = useParams();

    var orderId = props.clientSecret.split("_secret_")[0];
    const options: StripeElementsOptions = {
        clientSecret: props.clientSecret,
        locale: "zh-HK",
    }

    return <Elements stripe={ process.env.REACT_APP_IS_DEV === "true" ? testStripePromise : stripePromise } options={options}  >
        <StripeCreditForm shipment_number = {props.shipment_number} clientSecret={props.clientSecret} IFsetActivePage={props.IFsetActivePage} 
        getBlock1Data={props.getBlock1Data} promoCode={props.promoCode} autoPromoCode={props.autoPromoCode} membership_level_str={props.membership_level_str} setErrorNumber={props.setErrorNumber} 
        shippingfee={props.shippingfee} taxfee={props.taxfee} finalPrice65={props.finalPrice65} finalKGF6={props.finalKGF6} overCharge={props.overCharge}
        usedPoint={props.usedPoint} getPoint={props.getPoint} SKUPromoCodeList={props.SKUPromoCodeList}/>
    </Elements>
}

function SaveData(props: StripeFormProps) {

      const queryParameters = new URLSearchParams(window.location.search);
      const jwt = queryParameters.get("token");

      let block1Data = props.getBlock1Data();

      let FormJson = {"request":{
                          "paytax": block1Data.request.paytax,
                          "total_price":{
                                "value":block1Data.request.total_price.value
                          },
                          "sender":{"name":block1Data.request.sender.name
                                    ,"email":block1Data.request.sender.email
                                    ,"country_code":block1Data.request.sender.country_code
                                    ,"number":block1Data.request.sender.number
                          },
                          "receiver":{"contact_name":block1Data.request.receiver.contact_name
                                    ,"company_name":block1Data.request.receiver.company_name
                                    ,"tax_number":block1Data.request.receiver.tax_number
                                    ,"area_code":block1Data.request.receiver.area_code
                                    ,"phone":block1Data.request.receiver.phone
                                    ,"email":block1Data.request.receiver.email
                                    ,"address_line_1":block1Data.request.receiver.address_line_1
                                    ,"address_line_2":block1Data.request.receiver.address_line_2
                                    ,"address_line_3":block1Data.request.receiver.address_line_3
                                    ,"zip_code":block1Data.request.receiver.zip_code
                                    ,"city":block1Data.request.receiver.city
                                    ,"province":block1Data.request.receiver.province
                                    ,"country":block1Data.request.receiver.country
                                    
                          },
                          "insurance":{
                            "has_insurance":block1Data.request.insurance.has_insurance,
                            "sum_assured":block1Data.request.insurance.sum_assured
                          }
                          
                        },
                        "items":block1Data.items
                      };

                      
      axios
      .patch(`${process.env.REACT_APP_BACKEND_URL}/shipment-number/${props.shipment_number}`,FormJson ,{headers:{Authorization: `Bearer ${jwt}`}})
      .then((res) => {
          console.log("success");
      }).catch((err) => {
          console.error(err);
      });
    
}
