import React, { useEffect, useState } from 'react';
import { IItemList, JSONType, JSONTypeRequestReceiver } from '../MainForm';
import FirstPage from './Form/FirstPage';
import SecondPage from './Form/SecondPage';
import ThridPage from './Form/ThridPage';
import axios from 'axios';
import { Button ,Row, Col,Container} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Alert from 'react-bootstrap/Alert';
import AlertComponent from './BlockAlert/AlertComponent';
import { useParams } from 'react-router-dom';
import qs from 'qs';
import { SelectChangeEvent } from '@mui/material';


export interface IBlock1{
  title:string,
  titleF1:string,
  contactNameF1:string,
  areaCodeF1:number,
  contactPhoneF1:string,
  contactEmailF1:string,
  IFsetContactNameF1(e: React.ChangeEvent<HTMLInputElement>):void,
  IFsetAreaCodeF1(e: React.ChangeEvent<HTMLInputElement>):void,
  IFsetContactPhoneF1(e: React.ChangeEvent<HTMLInputElement>):void,
  IFsetContactEmailF1(e: React.ChangeEvent<HTMLInputElement>):void,
  titleF2:string,
  contactNameF2:string,
  companyNameF2:string,
  taxNumberF2:string,
  areaCodeF2:number,
  contactPhoneF2:string,
  contactEmailF2:string,
  contactAddress1F2:string,
  contactAddress2F2:string,
  contactAddress3F2:string,
  zipCodeF2:string,
  cityF2:string,
  provinceF2:string,
  countryF2:string,
  IFsetContactNameF2(e: React.ChangeEvent<HTMLInputElement>):void,
  IFsetCompanyNameF2(e: React.ChangeEvent<HTMLInputElement>):void,
  IFsetTaxNumberF2(e: React.ChangeEvent<HTMLInputElement>):void,
  IFsetAreaCodeF2(e: React.ChangeEvent<HTMLInputElement>):void,
  IFsetContactPhoneF2(e: React.ChangeEvent<HTMLInputElement>):void,
  IFsetContactEmailF2(e: React.ChangeEvent<HTMLInputElement>):void,
  IFsetContactAddress1F2(e: React.ChangeEvent<HTMLInputElement>):void,
  IFsetContactAddress2F2(e: React.ChangeEvent<HTMLInputElement>):void,
  IFsetContactAddress3F2(e: React.ChangeEvent<HTMLInputElement>):void,
  IFsetZipCodeF2(e: React.ChangeEvent<HTMLInputElement>):void,
  IFsetCityF2(e: React.ChangeEvent<HTMLInputElement>):void,
  IFsetProvinceF2(e: React.ChangeEvent<HTMLInputElement>):void,
  IFsetCountryF2(e: React.ChangeEvent<HTMLInputElement>):void,
  titleF3:string,
  itemListsF3: IItemList[],
  IFItemListsF3(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,index:number):void,
  insuranceF3:boolean,
  IFsetInsuranceF3(e: React.ChangeEvent<HTMLInputElement>):void,
  totalPriceF3:number,
  IFsetTotalPriceF3(list:IItemList[]):void,
  shipment_number:string,
  demoPrice?:number,
  IFsetDemoPrice(e: React.ChangeEvent<HTMLInputElement>):void,
  IFsetActivePage(e: number):void,
  customAddressNoteF2:JSONTypeRequestReceiver[],
  IFsetSingeCustomAddress: (e: number) => void,
  IFsetSaveCustomAddress(e: number):void,
  sendLocal:string,
  // far250fee:boolean,
  selectedPaymentMethodF3:number,
  IFsetSelectedPaymentMethodF3(e: number):void,
  exchangeRate:number,
  showMsg1F3:boolean,
  showMsg2F3:boolean,
  IFsetShowMsg1():void,
  IFsetShowMsg2():void,
  IFsetProvinceSelectF2:(event: SelectChangeEvent) => void,
  handleHideDialog:()=>void,
  customPKey:string,
}

export interface UploadJSONItemObject {
  package_no: number,
  items_no: number,
  unit_price: UploadJSONItemUnit_priceObject,
}

interface UploadJSONItemUnit_priceObject {
  value?: number,
}

function Block1(props:IBlock1) {
  const params = useParams();
  //para
  const [showAlert,setShowAlert] = useState(false);
  const [alertType,setAlertType] = useState("");
  const [alertMsg,setAlertMsg] = useState("");

  const [fillAllMsg,setFillAllMsg] = useState("");

  //CheckRequired
    //F1
    const [contactNameF1Err,setContactNameF1Err] = useState(false);
    const [areaCodeF1Err,setAreaCodeF1Err] = useState(false);
    const [contactPhoneF1Err,setContactPhoneF1Err] = useState(false);
    const [contactEmailF1Err,setContactEmailF1Err] = useState(false);

    //F2
    const [contactNameF2Err,setContactNameF2Err] = useState(false);
    const [areaCodeF2Err,setAreaCodeF2Err] = useState(false);
    const [contactPhoneF2Err,setContactPhoneF2Err] = useState(false);
    const [contactEmailF2Err,setContactEmailF2Err] = useState(false);
    const [contactAddress1F2Err,setContactAddress1F2Err] = useState(false);
    const [zipCodeF2Err,setZipCodeF2Err] = useState(false);
    const [cityF2Err,setCityF2Err] = useState(false);
    const [countryF2Err,setCountryF2Err] = useState(false);

  //lan
  const { t } = useTranslation();

  //InterfaceFunction
  let IFsetShowOff = () => {
    setShowAlert(false);
  }

  const SavePage1Data = () => {
    if(!validateEmail(props.contactEmailF1) || !validateEmail(props.contactEmailF2)){
      setAlertMsg(t("emailError")!!);
      setAlertType("warning");
      setShowAlert(true);
      console.log("emailError");
      return;
    }
    const queryParameters = new URLSearchParams(window.location.search);
    const jwt = queryParameters.get("token");

    let itemList:UploadJSONItemObject[] = []; 

    props.itemListsF3.map((value,index) => {
      let jsonObj :UploadJSONItemObject = {"items_no":value.item_no
                                          ,"package_no":value.package_no
                                          ,"unit_price":{"value":value.itemPrice}};
      itemList = [...itemList,jsonObj];
  })

    let FormJson = {"request":{
                        "paytax": props.selectedPaymentMethodF3,
                        "total_price":{
                              "value":props.totalPriceF3
                        },
                        "sender":{"name":props.contactNameF1
                                  ,"email":props.contactEmailF1
                                  ,"country_code":props.areaCodeF1
                                  ,"number":props.contactPhoneF1
                        },
                        "receiver":{"contact_name":props.contactNameF2
                                  ,"company_name":props.companyNameF2
                                  ,"tax_number":props.taxNumberF2
                                  ,"area_code":props.areaCodeF2
                                  ,"phone":props.contactPhoneF2
                                  ,"email":props.contactEmailF2
                                  ,"address_line_1":props.contactAddress1F2
                                  ,"address_line_2":props.contactAddress2F2
                                  ,"address_line_3":props.contactAddress3F2
                                  ,"zip_code":props.zipCodeF2
                                  ,"city":props.cityF2
                                  ,"province":props.provinceF2
                                  ,"country":props.countryF2
                                  
                        },
                        "insurance":{
                          "has_insurance":props.insuranceF3
                          ,"sum_assured":props.demoPrice
                        }
                        
                      },
                      "items":itemList
                    };
                    
    axios
    .patch(`${process.env.REACT_APP_BACKEND_URL}/shipment-number/${props.shipment_number}`,FormJson ,{headers:{Authorization: `Bearer ${jwt}`}})
    .then((res) => {
        //alert(t("saveDataB1"));
        setAlertMsg(t("saveDataB1")!!);
        setAlertType("success");
        setShowAlert(true);
        console.log("success");
    }).catch((err) => {
        setAlertMsg(t("saveDataB1Error")!!);
        setAlertType("warning");
        setShowAlert(true);
        console.error(err);
    });
  };
  

  //CheckRequired
  let IFCheckRequired = () => {
    if(CheckinputF1()){
      window.scrollTo(0, 0);
      setFillAllMsg(t('FillAllMsgF1'));
      return;
    }
    setFillAllMsg("");
    props.handleHideDialog();
    props.IFsetActivePage(2);
  }


  let CheckinputF1 = function () : boolean{
    let IsContactNameF1Err = props.contactNameF1 === "";
    let IsAreaCodeF1Err = props.areaCodeF1 === 0;
    let IsContactPhoneF1Err = props.contactPhoneF1 === "";
    let IsContactEmailF1Err = !validateEmail(props.contactEmailF1);
    setContactNameF1Err(IsContactNameF1Err);
    setAreaCodeF1Err(IsAreaCodeF1Err);
    setContactPhoneF1Err(IsContactPhoneF1Err);
    setContactEmailF1Err(IsContactEmailF1Err);

    let TestBool =  CheckinputF2();

    let returnVal: boolean = TestBool ||IsContactNameF1Err || IsAreaCodeF1Err || IsContactPhoneF1Err || IsContactEmailF1Err;

    return returnVal;
  }

  let CheckinputF2 = function () : boolean{
    let IsContactNameF2Err = props.contactNameF2 === "";
    let IsAreaCodeF2Err = props.areaCodeF2 === 0;
    let IsContactPhoneF2Err = props.contactPhoneF2 === "";
    let IsContactAddress1F2Err = props.contactAddress1F2 === "";
    let IsZipCodeF2Err = props.zipCodeF2 === "";
    let IsCityF2Err = props.cityF2 === "";
    let IsCountryF2Err = props.countryF2 === "";
    let IsContactEmailF2Err = !validateEmail(props.contactEmailF2);
    setContactNameF2Err(IsContactNameF2Err);
    setAreaCodeF2Err(IsAreaCodeF2Err);
    setContactPhoneF2Err(IsContactPhoneF2Err);
    setContactEmailF2Err(IsContactEmailF2Err);
    setContactAddress1F2Err(IsContactAddress1F2Err);
    setZipCodeF2Err(IsZipCodeF2Err);
    setCityF2Err(IsCityF2Err);
    setCountryF2Err(IsCountryF2Err);
    let returnVal: boolean = IsContactNameF2Err || IsAreaCodeF2Err || IsContactPhoneF2Err || IsContactAddress1F2Err || IsZipCodeF2Err || IsCityF2Err || IsCountryF2Err || IsContactEmailF2Err;
    return returnVal;
  }
  let CheckinputF3 = function () : boolean{
    
    return false;
  }
  //CheckRequired

  function validateEmail(email: string): boolean {
    const re = /\S+@\S+\.\S+/;
    return re.test(email) || email === "";
  }

  return (
    <div className="App">


            <div className='mt-5 mb-5'>
              <FirstPage title={props.titleF1}
                        contactNameF1={props.contactNameF1}
                        areaCodeF1={props.areaCodeF1}
                        contactPhoneF1={props.contactPhoneF1}
                        contactEmailF1={props.contactEmailF1}
                        IFsetContactNameF1={props.IFsetContactNameF1}
                        IFsetAreaCodeF1={props.IFsetAreaCodeF1}
                        IFsetContactPhoneF1={props.IFsetContactPhoneF1}
                        IFsetContactEmailF1={props.IFsetContactEmailF1} 
                        shipment_number={props.shipment_number} 
                        contactNameF1Err={contactNameF1Err}
                        areaCodeF1Err={areaCodeF1Err}
                        contactPhoneF1Err={contactPhoneF1Err}
                        contactEmailF1Err={contactEmailF1Err}
                        sendLocal={props.sendLocal}
                        fillAllMsg={fillAllMsg}
                        customPKey={props.customPKey}
                        />
            </div>

            <div className="border-bottom border-dark"></div>

            <div className='mt-5 mb-5'>
              <SecondPage title={props.titleF2}
                        contactNameF2={props.contactNameF2}
                        companyNameF2={props.companyNameF2}
                        taxNumberF2={props.taxNumberF2}
                        areaCodeF2={props.areaCodeF2}
                        contactPhoneF2={props.contactPhoneF2}
                        contactEmailF2={props.contactEmailF2}
                        contactAddress1F2={props.contactAddress1F2}
                        contactAddress2F2={props.contactAddress2F2} 
                        contactAddress3F2={props.contactAddress3F2} 
                        zipCodeF2={props.zipCodeF2} 
                        cityF2={props.cityF2} 
                        provinceF2={props.provinceF2} 
                        countryF2={props.countryF2} 
                        IFsetContactNameF2={props.IFsetContactNameF2} 
                        IFsetCompanyNameF2={props.IFsetCompanyNameF2} 
                        IFsetTaxNumberF2={props.IFsetTaxNumberF2} 
                        IFsetAreaCodeF2={props.IFsetAreaCodeF2}
                        IFsetContactPhoneF2={props.IFsetContactPhoneF2}
                        IFsetContactEmailF2={props.IFsetContactEmailF2}
                        IFsetContactAddress1F2={props.IFsetContactAddress1F2}
                        IFsetContactAddress2F2={props.IFsetContactAddress2F2}
                        IFsetContactAddress3F2={props.IFsetContactAddress3F2}
                        IFsetZipCodeF2={props.IFsetZipCodeF2}
                        IFsetCityF2={props.IFsetCityF2}
                        IFsetProvinceF2={props.IFsetProvinceF2}
                        IFsetCountryF2={props.IFsetCountryF2} 
                        contactNameF2Err={contactNameF2Err} 
                        areaCodeF2Err={areaCodeF2Err} 
                        contactPhoneF2Err={contactPhoneF2Err} 
                        contactEmailF2Err={contactEmailF2Err}
                        contactAddress1F2Err={contactAddress1F2Err} 
                        zipCodeF2Err={zipCodeF2Err} 
                        cityF2Err={cityF2Err} 
                        countryF2Err={countryF2Err} 
                        customAddressNoteF2={props.customAddressNoteF2}
                        IFsetSingeCustomAddress={props.IFsetSingeCustomAddress}
                        IFsetSaveCustomAddress={props.IFsetSaveCustomAddress}
                        sendLocal={props.sendLocal}
                        IFsetProvinceSelectF2={props.IFsetProvinceSelectF2}
                        // far250fee={props.far250fee}
                         />
            </div>

            <div className="border-bottom border-dark"></div>

            <div className='mt-5 mb-5'>
              <ThridPage title={props.titleF3}
                        itemLists={props.itemListsF3} 
                        insuranceF3={props.insuranceF3}
                        IFsetInsuranceF3={props.IFsetInsuranceF3}
                        IFItemLists={props.IFItemListsF3}
                        totalPriceF3={props.totalPriceF3}
                        IFsetTotalPriceF3={props.IFsetTotalPriceF3}
                        demoPrice={props.demoPrice}
                        IFsetDemoPrice={props.IFsetDemoPrice}
                        sendLocal={props.sendLocal}
                        selectedPaymentMethodF3={props.selectedPaymentMethodF3}
                        IFsetSelectedPaymentMethodF3={props.IFsetSelectedPaymentMethodF3}
                        exchangeRate={props.exchangeRate}
                        showMsg1F3={props.showMsg1F3}
                        showMsg2F3={props.showMsg2F3}
                        IFsetShowMsg1={props.IFsetShowMsg1}
                        IFsetShowMsg2={props.IFsetShowMsg2}
                        shipment_number={props.shipment_number}
                        />
            </div> 

            <Row>
              <Col xs={12} lg= {12} className='order-1 order-lg-1 '>
              <Button variant="outline-dark" className='float-end mb-5 ms-3' onClick={() => {IFCheckRequired()}}>{t("nextStepMF")}</Button>{' '}
              <Button 
                variant="outline-dark" 
                className='float-end mb-5' 
                onClick={SavePage1Data}
              >
                {t(props.sendLocal === 'UK' ? "SaveLabelF3" : "SaveLabelF3AU")}
              </Button>
              </Col>
            </Row>
            
            {showAlert && <Container>
                            <Row >
                                <Col xs="12" md="12" lg="12" className=''>
                                  <AlertComponent alertMsg={alertMsg} alertType={alertType} IFsetShowOff={IFsetShowOff}  alertCustom=''/> 
                                </Col>
                            </Row>
                          </Container>
            }
            

            <div className='mb-5'></div>

  </div>
  );
}

export default Block1;
