import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import {Container, Row,Col, Button} from "react-bootstrap";
import { useTranslation } from 'react-i18next';

import axios from 'axios';

import { useNavigate, useParams } from 'react-router-dom';
import i18n from '../../../i18n';
import MaintenancePage from '../MaintenancePage';
import { IClientData, IShipmentRecord } from '../../ShipmentRecord';
import CompanyImg from "../../../image/company_logo.jpg";



function ShipmentRecordList(props:IShipmentRecord) {
    const params = useParams();
    const navigate = useNavigate();
    //lan
    const { t } = useTranslation();

    //Page Control
    const [FirstLoad,setFirstLoad] = useState(true);    

    const [shipmentData, setShipmentData] = useState<ShipmentDataRecord[]>([]);

    const [clientData, setClientData] = useState<IClientData>();

    useEffect(() => {

        if(FirstLoad){
          setLanguage();
              LoadData();
              LoadClientData();
          setFirstLoad(false);
        }
        
    });
    
    const setLanguage = () => {
        let language :string = params.language === 'zh' ? "zh" : "en";
        handleChangeLng(language);
        document.documentElement.lang = language;
    }

    const handleChangeLng = (lng: string) => {
		i18n.changeLanguage(lng);
		localStorage.setItem("lng", lng);
	};

    
    const LoadData =  async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/GetRecordList`,
                { custompKey : props.custompKey},
                { headers: { Authorization: `Bearer ` } }
            );
        
            if (response.data) {
                setShipmentData(response.data);
                return true;
            }
            return false;
        } catch (error) {
            console.error('An error occurred while checking for GetRecordList:', error);
            // Handle the error according to your needs
            return false;
        }
        
    }

    const LoadClientData =  async () => {
        try {
            const response = await axios.post(
              `${process.env.REACT_APP_BACKEND_URL}/GetClientData`,
              { custompKey: props.custompKey},
              { headers: { Authorization: `Bearer ` } }
            );
      
            if (response.status === 200) {
              setClientData(response.data);
              return true;
            }
            return false;
          } catch (error) {
            console.error('An error occurred while checking for maintenance:', error);
            return false;
          }
    }

    const GoToShipmentDetail = (shipment_detail:ShipmentDataRecord) => {
        navigate(`/c0501/${params.language}/p/RecordDetail`, { state: { shipmentDataRecord: shipment_detail } });
    }

    return (
        <div className="">

            <Container className='mt-5 mb-5'>
                <Row>
                    <Col xs="12" md="12" lg="12"  className='non-p'>
                        <Button variant="outline-dark float-start" className='me-2' onClick={() =>  navigate(-1) }>{t("previousStepMF")}</Button>
                        <div className='fw-bold font-size-20'>{t('ShipmentRecordBtn')}</div>
                        <img src={CompanyImg} className={`img-size half-opacity float-end`} />

                    </Col>
                    <Col xs="12" md="12" lg="12"  className='non-p'>
                        {clientData != undefined && <div className='fw-bold font-size-20'>{t('CustomPointTitle')}{clientData!!.customer_user_point + clientData!!.customer_user_point_vba}</div>}
                    </Col>
                </Row>
            </Container>

            {shipmentData.map((item, index) => (
                <div key={index} style={{
                    textAlign: 'left', 
                    border: '1px solid #ddd', 
                    borderRadius: '15px',
                    padding: '10px',
                    marginBottom: '10px',
                    cursor: 'pointer'
                }}
                onClick={() => GoToShipmentDetail(item)}
                >
                    <h3>{t("ShipmentNumberTitleRecord")}{item.shipment_detail.shipment_number}</h3>
                    <Container className='fullWidthContainer'>
                        <Row>
                            <Col xs="12" md="12" lg="5" className=''>
                                <p>{t("OrderNumberTitleRecord")}{item.shipment_detail.order_number}</p>
                            </Col>
                            <Col xs="6" md="6" lg="5" className=''>
                                <p>{t("TotalPriceTitleRecord")}{item.shipment_detail.total_price_value}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="6" md="6" lg="5" className=''>
                                <p>{t("AddressTitleRecord")}{item.shipment_detail.receiver_address}</p>
                            </Col>
                            <Col xs="6" md="6" lg="5" className=''>
                                <p>{t("ReceiverZIPCodeTitleRecord")}{item.shipment_detail.receiver_zip_code}</p>
                            </Col>
                            <Col xs="6" md="6" lg="5" className=''>
                                <p>{t("PackageCountTitleRecord")}{item.package_count}</p>
                            </Col>
                        </Row>
                         <Row>
                            <Col xs="6" md="6" lg="5" className=''>
                            <p>{t("StatusTitleRecord")}{t(`Status${item.shipment_detail.request_status}`)}</p>
                                {item.shipment_detail.payment_record &&<p>{t("UsedPointTitleRecord")}{t(`PointTitleRecord`)} {item.shipment_detail.payment_record?.payment_used_points}</p>}
                                
                            </Col>
                            <Col xs="6" md="6" lg="5" className=''>
                                <p>{t("PaidAmountTitleRecord")}{item.shipment_detail.payment_record?.payment_currency_code.toUpperCase()} {item.shipment_detail.payment_record?.payment_amount}</p>
                                {item.shipment_detail.payment_record &&<p>{t("AddPointTitleRecord")}{t('PointTitleRecord')} {item.shipment_detail.payment_record?.payment_get_points}</p>}
                                
                            </Col>

                            <Col xs="6" md="6" lg="5" className=''>
                                <p>{t("TaxTypeTitleRecord")}{t(TaxTypeNumber[item.shipment_detail.request_paytax])}</p>
                            </Col>
                        </Row>
                        
                    </Container>
                </div>
            ))}
        </div>
    );
}

export default ShipmentRecordList;

export const TaxTypeNumber: { [key: number]: string }  = {
    0: "PayWithoutTaxF3",
    1: "PayWithTaxF3",
    2: "PayFreeTaxF3",
    3: "PayTaxAirFlightF3",
    4: "PayTaxShipFlightF3",
    5: "PayWithoutTaxShipFlightF3",
};


export interface PackageRecord {
    package_no: number;
    dimension_length: number;
    dimension_width: number;
    dimension_height: number;
    weight: number;
    weight_unit: string;
}

export interface ShipmentDetailRecord {
    request_id: number;
    shipment_number: string;
    order_number: string;
    total_price_value: string;
    receiver_address: string;
    request_status: string;
    payment_record?: PaymentDetailRecord;
    sender_contact_name: string;
    sender_contact_email: string;
    sender_contact_phone: string; // Assuming this combines country code and phone number
    receiver_contact_name?: string; // Optional because it can be null
    receiver_tax_number?: string; // Optional because it can be null
    receiver_phone: string; // Assuming this combines area code and phone number
    receiver_email?: string; // Optional because it can be null
    receiver_zip_code?: string; // Optional because it can be null
    receiver_city?: string; // Optional because it can be null
    receiver_province?: string; // Optional because it can be null
    receiver_country?: string; // Optional because it can be null
    request_has_insurance: boolean;
    request_paytax:number;
  }

export interface PaymentDetailRecord {
    payment_record_id: number;
    payment_amount: number;
    payment_currency_code:string;
    payment_get_points: number;
    payment_used_points: number;
}

export interface ItemsRecord {
    package_no: number;
    items_no: number;
    items_name_zh:string;
    items_qty:number;
    items_unit_price_value:string;
}
export interface PromoCodeRecord {
    promo_code_auto_apply: boolean;
    promo_code_string: string;
    promo_code_detail: string;
    promo_code_type:string;
    promo_code_point:number;
}

export interface ShipmentDataRecord {
    shipment_detail: ShipmentDetailRecord;
    package_count: number;
    packages: PackageRecord[];
    items: ItemsRecord[];
    promo_codes: PromoCodeRecord[];
}

interface IShipmentRecordListProps {
    custompKey?: string;
}