import React, { useEffect, useState } from 'react';
import {Container, Row,Col,Button} from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';

import axios, { AxiosRequestConfig } from 'axios';
import AlertComponent from './Block/BlockAlert/AlertComponent';
import CompanyImg from "../image/company_logo.jpg";

function InquireForm() {
    //lan
    const { t } = useTranslation();

    const [shipmentNumber, setShipmentNumber] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');

    const [clickSubmit,setClickSubmit] = useState<boolean>(false);

    const [showErrorMsg, setShowErrorMsg] = useState<boolean>(false);

    const CloseErrorMsg = () => {
        setShowErrorMsg(false);
    };

    const Inquire = async () => {
        setClickSubmit(true);
        if (shipmentNumber == "" || phoneNumber == "") {
            return;
        }
        else {
            const axiosConfig: AxiosRequestConfig = {
                headers: { Authorization: `Bearer ` }
              }
          
              await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/GenStatusToken`,
                { shipment_number: shipmentNumber,
                    phone_number:phoneNumber},
                axiosConfig
              ).then((res) => {
                window.location.href = `/c0501/zh/p/shipmentstatus?token=${res.data.token}`;
              }).catch((err) => {
                setShowErrorMsg(true);
              });
            //navigate(`/c0501/${language}/p/shipmentstatus`);
        }
    };

    return (
        <div className="App">

                <Container className='mt-5'>
                <Row>
                        <Col xs="12" md="12" lg="12"  className='non-p'>

                            <div className='text-end'>
                            <img src={CompanyImg} className={`img-size half-opacity`} />

                            </div>
                        </Col>
                    </Row>
                    <Row className='justify-content-center'>
                        <Col xs="6" md="6" lg="6"  className='non-p'>
                            <h2 className='fw-bold mt-3 float-start'>{t("TitleInquireForm")}</h2>

                        </Col>
                    </Row>
                    <Row className='mt-5 justify-content-center'>
                        <Col xs="6" md="6" lg="6" className='non-p'>

                            <TextField
                                required
                                error={shipmentNumber == "" && clickSubmit}
                                id="shipmentNumber"
                                name="shipmentNumber"
                                className=''
                                fullWidth
                                label={t("shipmentNumberInquireForm")}
                                onChange={(e) => setShipmentNumber(e.target.value)}
                                value={shipmentNumber}
                                type='text'
                                inputProps={{ maxLength: 30 }}
                            />

                        </Col>
                    </Row>
                    <Row className='mt-3 justify-content-center'>
                        <Col xs="6" md="6" lg="6" className='non-p'>

                            <TextField
                                required
                                error={phoneNumber == "" && clickSubmit}
                                id="phoneNumber"
                                name="phoneNumber"
                                className=''
                                fullWidth
                                label={t("phoneNumberInquireForm")}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                value={phoneNumber}
                                type='number'
                                inputProps={{ maxLength: 30 }}
                            />

                        </Col>
                    </Row>
                    <Row className='mt-3 justify-content-center'>
                        <Col xs="2" md="2" lg="12" className='non-p'>
                            <Button variant="outline-dark" className='' onClick={() => Inquire()}>{t("InquireInquireForm")}</Button>{' '}
                        </Col>
                    </Row>
                    <Row className='mt-5 justify-content-center'>
                        <Col xs="6" md="6" lg="6" className='non-p'>
                            {showErrorMsg && <AlertComponent alertMsg={t('ErrorInquireForm')} alertType="warning" IFsetShowOff={CloseErrorMsg}  alertCustom=''/> }
                        </Col>
                    </Row>
                </Container>
        </div>
    );
}

export default InquireForm;
