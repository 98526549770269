import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import {Container, Row,Col} from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import axios from 'axios';
import { JSONType } from './MainForm';
import { useParams } from 'react-router-dom';
import StatusComponent, { IStatusComponent } from './StatusBlock/StatusComponent/StatusComponent';
import StatusDetail from './StatusBlock/StatusDetail';
import LoadUrlFailed from './Block/LoadUrlFailed';
import MaintenancePage from './Block/MaintenancePage';



function StatusForm() {
    const params = useParams();
    //lan
    const { t } = useTranslation();

    //Page Control
    const [activePage,setActivePage] = useState(0);
    //Page Control
    const [FirstLoad,setFirstLoad] = useState(true);
    const [jsonData,setJsonData] = useState<IStatusPackageJsonType>();

    //MaintainPage
    const [maintainMsg, setMaintainMsg] = useState("");
    //MaintainPage

    useEffect(() => {

        if(FirstLoad){
          setLanguage();
          CheckMaintenance().then((isUnderMaintenance) => {
            if (!isUnderMaintenance) {
              LoadData();
            }
          });
          setFirstLoad(false);
        }
        
      });
    
      const setLanguage = () => {
        let language :string = params.language === 'zh' ? "zh" : "en";
        handleChangeLng(language);
        document.documentElement.lang = language;
      }

      const handleChangeLng = (lng: string) => {
		i18n.changeLanguage(lng);
		localStorage.setItem("lng", lng);
	};

    const CheckMaintenance = async () => {
        const queryParameters = new URLSearchParams(window.location.search);
        const jwt = queryParameters.get('token');
    
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/CheckMaintenance`,
            {},
            { headers: { Authorization: `Bearer ${jwt}` } }
          );
    
          if (response.data.maintenance_msg) {
            setMaintainMsg(response.data.maintenance_msg);
            setActivePage(11); //Display Maintenance Page
            return true;
          }
          return false;
        } catch (error) {
          console.error('An error occurred while checking for maintenance:', error);
          // Handle the error according to your needs
          return false;
        }
      };
    
      const LoadData =  () => {
        const queryParameters = new URLSearchParams(window.location.search);
        const jwt = queryParameters.get("token");
        //console.log(jwt);
    
        axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/delivery-status`,{headers:{Authorization: `Bearer ${jwt}`}})
        .then((res) => {
            let JsonData:IStatusPackageJsonType  = res.data.data;
            // console.log(res.data.data);
            setJsonData(JsonData);
            setActivePage(1);
        }).catch((err) => {
            setActivePage(2);
            console.error(err)
        });
      }

    return (
        <div className="App">

                <Container>
                    <Row>
                        <Col xs="12" md="12" lg="12" className='non-p'>

                            {activePage == 1 && <StatusDetail  JsonData={jsonData!!}/> }

                            {activePage == 2 && <LoadUrlFailed /> }

                            {activePage === 11 && <MaintenancePage maintainMsg={maintainMsg}/>}

                        </Col>
                    </Row>
                </Container>
        </div>
    );
}

export default StatusForm;

export interface IStatusPackageJsonType {
    shipment_status:IStatusJsonType[],
    package_count:number,
    request_transport_company:string,
    request_waybill_number:string,
    shipment_number:string,
    request_location:string,
}

export interface IStatusJsonType {
    shipment_number:string,
    shipment_step:string,
    shipment_datetime:string,
    shipment_details:string | null,
}
