

import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';



function LoadingBlock() {

  const { t } = useTranslation();

  return (
    <div className="text-center">

      <CircularProgress size={80} className='mt-3'/>
          <div className='next-line-if-lf fw-bold font-size-t4 fw-bold mt-5'>{t("LoadingP8")}</div>
  </div>
  );
}

export default LoadingBlock;
