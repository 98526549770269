import React, { useCallback, useEffect } from 'react';

import { AutoSKUPromoCodeResponse, IItemList, IPromoCode, JSONType } from '../MainForm';
import FifthPage from './Form/FifthPage';
import FourthPage from './Form/FourthPage';
import SixthPage from './Form/SixthPage';
import { Button, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap'
import Paypal from '../../component/PaypalForC0501';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { v4 as uuid } from "uuid";
import { CheckCoupon } from '../../component/StripeCreditCard';
import { Alert } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

export interface IBlock2 {
  title: string,
  titleF4: string,
  contactNameF2: string,
  contactAddress1F2: string,
  contactAddress2F2: string,
  contactAddress3F2: string,
  contactPhoneF2: string,
  areaCodeF2: number,
  contactEmailF2: string,
  zipCodeF2: string,
  contactNameF1: string,
  areaCodeF1: number,
  contactPhoneF1: string,
  contactEmailF1: string,
  titleF5: string,
  couponCodeF5: string,
  IFsetCouponCodeF5(e: React.ChangeEvent<HTMLInputElement>): void,
  autoCouponCodeF5: string,
  IFsetAutoCouponCodeF5(e: React.ChangeEvent<HTMLInputElement>): void,
  itemListsF5: IItemList[],
  totalPriceF5: number,
  IFsetTotalPriceF5(e: number): void,
  titleF6: string,
  term1F6: boolean,
  term2F6: boolean,
  showStripeForm: boolean,
  IFsetTerm1F6(e: React.ChangeEvent<HTMLInputElement>): void,
  IFsetTerm2F6(e: React.ChangeEvent<HTMLInputElement>): void,
  IFsetStripeButton(): void,
  stripeForm: JSX.Element,
  shipment_number: string,
  insuranceF3: boolean,
  totalPriceF3: number,
  jsonData: JSONType,
  getBlock1Data(): Record<string, any>
  IFsetFinalPrice65(e: number): void,
  IFsetFinalPrice92(e: number): void,
  finalPrice65: number,
  finalPrice92: number,
  insuranceVal: number,
  IFsetInsuranceVal(e: number): void,
  agreementMsg: JSX.Element
  IFsetActivePage(e: number): void,
  promoObjF5: IPromoCode | null,
  IFsetPromoObjF5(e: IPromoCode | null): void,
  autoPromoObjF5: IPromoCode | null,
  IFsetAutoPromoObjF5(e: IPromoCode | null): void,
  promoPriceF6: number,
  autoPromoPriceF6:number,
  IFsetPromoPriceF6(e: number): void,
  IFsetAutoPromoPriceF6(e: number): void,
  IFsetActivePage(e: number): void,
  membership_level: number,
  membership_level_str: string,
  IFsetFirstLoadPage6(): void,
  firstLoadPage6: boolean,
  sendLocal: string,
  membership_after15kg: number,
  finalKGF6: number,
  IFsetFinalKGF6(e: number): void,
  IFReconPayment: () => void,
  // far250fee:boolean,
  // IFsetFarPostalCodeFee(e:number):void,
  // farPostalCodeFee:number,
  selectedPaymentMethodF3: number,
  tax1F6: number,
  tax2F6: number,
  tax3F6: number,
  tax1Rate: number,
  tax2Rate: number,
  tax3Rate: number,
  showCancelBoxP6: boolean,
  IFsetHideMsgBoxP6(): void,
  handleCheckboxChangeP6: (event: React.ChangeEvent<HTMLInputElement>) => void,
  setOverCharge1: (e: number) => void,
  setOverCharge2: (e: number) => void,
  overCharge1: number,
  overCharge2: number,
  customPKey:string,
  customPoint:number,
  pointDiscount:number,
  IFsetPointDiscount:(e:React.ChangeEvent<HTMLInputElement>)=>void,
  IFsetPointDiscountH:(e:number)=>void,
  SKUPromoCodeList:AutoSKUPromoCodeResponse | null,
  setSKUPromoCodeList:(e:AutoSKUPromoCodeResponse)=>void,
  SKUPromoPriceF6:number,
  setSKUPromoPriceF6:(e:number)=>void,
  SaveData:()=>void,
  setAgreementMsg:(e:JSX.Element)=>void,
  setPromoPoint:(e:number)=>void,
  promoPoint:number,
  pageSixFirstLoad:boolean,
  setPageSixFirstLoad:(e:boolean)=>void,
  cumulativeKgQtyMinus: number,
  setCumulativeKgQtyMinus:(e:number)=>void,
  NewSKUPromoQtyF6:number,
  NewSKUPromoPriceF6:number,
  setNewSKUPromoPriceF6:(e:number)=>void,
}

function Block2(props: IBlock2) {
  //lan
  const { t } = useTranslation();
  const params = useParams();

  const queryParameters = new URLSearchParams(window.location.search);
  const jwt = queryParameters.get("token");

  const IFGlobePayment = async () => {
    await props.SaveData();

    if (!(props.term1F6 && props.term2F6)) {
      props.setAgreementMsg(<Alert variant='warning' dismissible onClose={() =>{props.setAgreementMsg(<></>)}}>{t('FinalSubmitVaildF6')}</Alert>)
      return;
    }

    props.IFsetActivePage(8);

    const signatureEndpoint = `${process.env.REACT_APP_BACKEND_URL}/globe-payment/generate_signature`;

    const unique_id = uuid();
    const small_id = unique_id.slice(0, 10);
    const reference_number = `${props.shipment_number}${small_id}`

    // Prepare the data to send to the API
    // const payload = {
    //   transaction_type: "authorization",
    //   reference_number: "1350029885979",
    //   amount: `10.00`, // Example amount
    //   currency: "HKD",
    //   locale: "zh",
    //   profile_id: "6561C76D-A09F-437A-822E-C2DC434D3926",
    //   access_key: "55aaff90a7133b518241e2610a0e4ec0",
    //   signed_field_names: "access_key,amount,currency,locale,profile_id,reference_number,signed_date_time,signed_field_names,transaction_type,transaction_uuid,unsigned_field_names",
    //   unsigned_field_names:"",
    // };

    let getOrginAmount = Math.round((props.finalPrice92 + props.insuranceVal - props.SKUPromoPriceF6 - props.promoPriceF6 - props.autoPromoPriceF6 + props.tax1F6 + props.tax2F6 + props.tax3F6 + props.overCharge1 + props.overCharge2 - props.pointDiscount) * 100) / 100;

    let shippingfee = Math.round((props.finalPrice65 + props.insuranceVal - props.SKUPromoPriceF6 - props.promoPriceF6 - props.autoPromoPriceF6) * 100) / 100;

    let taxfee = Math.round((props.tax1F6 + props.tax2F6 + props.tax3F6) * 100) / 100;

    let OverChargefee = props.overCharge1 + props.overCharge2;

    let getAmount = Math.round((shippingfee + taxfee + OverChargefee - props.pointDiscount) * 100) / 100;

    let getAddPoint = Math.round((props.finalPrice65 - props.SKUPromoPriceF6 - props.promoPriceF6 - props.autoPromoPriceF6) * 100) / 100 - props.pointDiscount;

    let isSuccessful = false;
    let promoCode = ""
    if (props.promoObjF5 != null) {
      promoCode = props.promoObjF5.promo_code_string;
      isSuccessful = await CheckCoupon(promoCode,props.membership_level_str,props.shipment_number,props.finalPrice65,props.finalKGF6,0,props.selectedPaymentMethodF3);
    } else {
      isSuccessful = true;
    }

    let autoPromoCode = ""
    if(isSuccessful){
      if (props.autoPromoObjF5 != null) {
        autoPromoCode = props.autoPromoObjF5.promo_code_string;
        isSuccessful = await CheckCoupon(autoPromoCode,props.membership_level_str,props.shipment_number,props.finalPrice65,props.finalKGF6,1,props.selectedPaymentMethodF3);
      } else {
        isSuccessful = true;
      }
    }

    if(!isSuccessful){
      props.IFsetActivePage(10);
      return;
    }

    if(getAmount <= 0){
      getAmount = 0;
      getOrginAmount = 0;
      getAddPoint = 0;
      const PayData:IPayFree = {
        getAmount:getAmount,
        getOrginAmount:getOrginAmount,
        shippingfee:shippingfee,
        taxfee:taxfee,
        OverChargefee:OverChargefee,
        getAddPoint:getAddPoint,
        promoCode:promoCode,
        autoPromoCode:autoPromoCode,
        language:params.language === 'zh' ? "zh" : "en",
      }
      await PayByFree(PayData);
      return;
    }

    props.SaveData();

    const payload = {
      transaction_type: "sale",
      reference_number: reference_number,
      amount: getAmount,
      currency: "HKD",
      locale: "zh",
      bill_to_address_city: "Hong Kong",
      bill_to_address_country: "CN",
      bill_to_address_line1: "xxxxxxxxxxxxxxxxxx",
      bill_to_address_postal: "000000",
      bill_to_address_state : "HK",
      bill_to_email: "xxxxxxx@gmail.com",
      bill_to_forename : "xxxxxxxxxxxxxxxx",
      bill_to_surname : "xxxxxxxxxxxxxxxx",
      signed_field_names: "access_key,amount,bill_to_address_city,bill_to_address_country,bill_to_address_line1,bill_to_address_postal,bill_to_address_state,bill_to_email,bill_to_forename,bill_to_surname,currency,locale,profile_id,reference_number,signed_date_time,signed_field_names,transaction_type,transaction_uuid,unsigned_field_names",
      unsigned_field_names:"",
    };
    const createPaymentEndpoint = `${process.env.REACT_APP_BACKEND_URL}/globe-payment/create_payment`;
    const paymentJosn = {
      reference_number: reference_number,
      request_id: `${props.jsonData.request.request_id}`,
      currency: "HKD",
      payment_amount: getAmount,
      payment_orgin_amount: getOrginAmount,
      payment_shipping_fee: shippingfee,
      payment_tax_fee: taxfee,
      payment_over_charge:OverChargefee,

      get_add_point : getAddPoint,
      promo_code: promoCode,
      auto_promo_code: autoPromoCode,
      used_point: props.pointDiscount * 20,
      SKUPromoCodeList:props.SKUPromoCodeList?.matching_promo_codes ?? null,
      language: params.language === 'zh' ? "zh" : "en",
    };
    try {
      // Send data to your Django backend to generate a signature
      const signatureResponse = await axios.post(signatureEndpoint, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const signatureData : ISign= signatureResponse.data;

      const createGlobeResponse = await axios.post(createPaymentEndpoint, paymentJosn, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if(createGlobeResponse.status >= 200 && createGlobeResponse.status < 300){
        setCookie("jwt", createGlobeResponse.data.token, 1);
        submitPaymentForm(signatureData);
        return;
      }

      
      
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        console.error("Error processing payment:", axiosError.response.data);
      } else {
        console.error("Error processing payment:", axiosError.message);
      }
    }
  };

  const PayByFree = async (PayData:IPayFree) => {
        props.IFsetActivePage(8);
        const jwt = new URL(window.location.href).searchParams.get('token');

          const axiosConfig: AxiosRequestConfig = {
            headers: { Authorization: `Bearer ${jwt}` }
          }
          let language: string = params.language === 'zh' ? "zh" : "en";
          const insertPaymentData = {
            "request": props.jsonData!!.request.request_id,
            "payment_order_id": props.jsonData!!.request.shipment_number,
            "payment_currency_code": "hkd",
            "payment_amount": PayData.getAmount,
            "payment_net_amount": PayData.getAmount,
            "payment_orgin_amount": PayData.getOrginAmount,
            "payment_shipping_fee": PayData.shippingfee,
            "payment_tax_fee": PayData.taxfee,
            "payment_over_charge":PayData.OverChargefee,
            "payment_status": "SUCCESS",
            "usedPoint": props.pointDiscount * 20,
            "couponCode": PayData.promoCode,
            "autoCouponCode": PayData.autoPromoCode,
            "lang": language,
            "SKUPromoCodeList" : props.SKUPromoCodeList?.matching_promo_codes ?? null,
          }
          
          axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/insertPaymentRecord`,
            insertPaymentData,
            axiosConfig
          ).then((res) => {
            props.IFsetActivePage(4);
          }).catch((err) => {
            props.IFsetActivePage(5);
          }).finally(() => {
            props.SaveData();
          });
          return;
  }

  function submitPaymentForm(signatureData : ISign) {
    // Create a form element
    const form = document.createElement('form');
    form.method = 'post';
    form.action = `${process.env.REACT_APP_GLOBE_PAYMENT_URL}`;
  
    // Add the signature data as hidden input fields
    Object.entries(signatureData).forEach(([key, value]) => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = value;
      form.appendChild(input);
    });
  
    // Append the form to the body and submit it
    document.body.appendChild(form);
    form.submit();
  
    // Optionally, remove the form after submission
    document.body.removeChild(form);
  }
    


  return (
    <div className="App">

  <FourthPage title={props.titleF4}
        contactNameF2={props.contactNameF2}
        contactAddress1F2={props.contactAddress1F2}
        contactAddress2F2={props.contactAddress2F2}
        contactAddress3F2={props.contactAddress3F2}
        areaCodeF2={props.areaCodeF2}
        contactPhoneF2={props.contactPhoneF2}
        contactEmailF2={props.contactEmailF2}
        zipCodeF2={props.zipCodeF2}
        contactNameF1={props.contactNameF1}
        areaCodeF1={props.areaCodeF1}
        contactPhoneF1={props.contactPhoneF1}
        contactEmailF1={props.contactEmailF1}
        shipment_number={props.shipment_number}
        sendLocal={props.sendLocal}
        customPKey={props.customPKey}
      />

      <div className="border-bottom border-dark"></div>

      <FifthPage title={props.titleF5}
        couponCodeF5={props.couponCodeF5}
        IFsetCouponCodeF5={props.IFsetCouponCodeF5}
        autoCouponCodeF5={props.autoCouponCodeF5}
        IFsetAutoCouponCodeF5={props.IFsetAutoCouponCodeF5}
        itemLists={props.itemListsF5}
        totalPriceF5={props.totalPriceF5}
        IFsetTotalPriceF5={props.IFsetTotalPriceF5}
        promoObjF5={props.promoObjF5}
        autoPromoObjF5={props.autoPromoObjF5}
        IFsetPromoObjF5={props.IFsetPromoObjF5}
        IFsetAutoPromoObjF5={props.IFsetAutoPromoObjF5}
        finalPrice65={props.finalPrice65}
        IFsetPromoPriceF6={props.IFsetPromoPriceF6}
        IFsetAutoPromoPriceF6={props.IFsetAutoPromoPriceF6}
        membership_level_str={props.membership_level_str}
        shipment_number={props.shipment_number}
        sendLocal={props.sendLocal}
        finalKGF6={props.finalKGF6}
        jsonData={props.jsonData}
        customPoint={props.customPoint}
        setPromoPoint={props.setPromoPoint}
        selectedPaymentMethodF3={props.selectedPaymentMethodF3}
        SKUPromoCodeList={props.SKUPromoCodeList}
        cumulativeKgQtyMinus={props.cumulativeKgQtyMinus}
      />

      <div className="border-bottom border-dark"></div>

      <SixthPage
        shipment_number={props.shipment_number}
        title={props.titleF6}
        term1F6={props.term1F6}
        term2F6={props.term2F6}
        IFsetTerm1F6={props.IFsetTerm1F6}
        IFsetTerm2F6={props.IFsetTerm2F6}
        insuranceF3={props.insuranceF3}
        totalPriceF3={props.totalPriceF3}
        jsonData={props.jsonData}
        IFsetFinalPrice65={props.IFsetFinalPrice65}
        IFsetFinalPrice92={props.IFsetFinalPrice92}
        finalPrice65={props.finalPrice65}
        insuranceVal={props.insuranceVal}
        IFsetInsuranceVal={props.IFsetInsuranceVal}
        promoObjF5={props.promoObjF5}
        autoPromoObjF5={props.autoPromoObjF5}
        promoPriceF6={props.promoPriceF6}
        autoPromoPriceF6={props.autoPromoPriceF6}
        showStripeForm={props.showStripeForm}
        membership_level={props.membership_level}
        membership_level_str={props.membership_level_str}
        IFsetPromoObjF5={props.IFsetPromoObjF5}
        IFsetAutoPromoObjF5={props.IFsetAutoPromoObjF5}
        IFsetPromoPriceF6={props.IFsetPromoPriceF6}
        IFsetAutoPromoPriceF6={props.IFsetAutoPromoPriceF6}
        IFsetFirstLoadPage6={props.IFsetFirstLoadPage6}
        firstLoadPage6={props.firstLoadPage6}
        sendLocal={props.sendLocal}
        membership_after15kg={props.membership_after15kg}
        // far250fee={props.far250fee}
        IFsetFinalKGF6={props.IFsetFinalKGF6}
        // IFsetFarPostalCodeFee={props.IFsetFarPostalCodeFee}
        // farPostalCodeFee={props.farPostalCodeFee}
        selectedPaymentMethodF3={props.selectedPaymentMethodF3}
        tax1F6={props.tax1F6}
        tax2F6={props.tax2F6}
        tax3F6={props.tax3F6}
        tax1Rate={props.tax1Rate}
        tax2Rate={props.tax2Rate}
        tax3Rate={props.tax3Rate}
        showCancelBoxP6={props.showCancelBoxP6}
        IFsetHideMsgBoxP6={props.IFsetHideMsgBoxP6}
        handleCheckboxChangeP6={props.handleCheckboxChangeP6}
        setOverCharge1={props.setOverCharge1}
        setOverCharge2={props.setOverCharge2}
        overCharge1={props.overCharge1}
        overCharge2={props.overCharge2}
        customPoint={props.customPoint}
        pointDiscount={props.pointDiscount}
        IFsetPointDiscount={props.IFsetPointDiscount}
        IFsetPointDiscountH={props.IFsetPointDiscountH}
        SKUPromoCodeList={props.SKUPromoCodeList}
        setSKUPromoCodeList={props.setSKUPromoCodeList}
        SKUPromoPriceF6={props.SKUPromoPriceF6}
        setSKUPromoPriceF6={props.setSKUPromoPriceF6}
        setPromoPoint={props.setPromoPoint}
        promoPoint={props.promoPoint}
        pageSixFirstLoad={props.pageSixFirstLoad}
        setPageSixFirstLoad={props.setPageSixFirstLoad}
        setCumulativeKgQtyMinus={props.setCumulativeKgQtyMinus}
        NewSKUPromoQtyF6={props.NewSKUPromoQtyF6}
        NewSKUPromoPriceF6={props.NewSKUPromoPriceF6}
        setNewSKUPromoPriceF6={props.setNewSKUPromoPriceF6}
      />

      <Row>
        <Col xs={12} lg={12} className='mb-3'>
          
          <Button variant="outline-dark" className='float-end mb-5 ms-3' disabled={props.showStripeForm} onClick={() => {props.IFReconPayment()}}>{t("SubmitLabelReconF6")}</Button>
          
          <Button variant="outline-dark" className='float-end mb-5 ms-3' onClick={() => {IFGlobePayment();}}>{t("SubmitLabelF6")}</Button>
          

          <div className="d-flex justify-content-end">
            <Button variant="outline-dark" className='me-2' onClick={() => { props.setPageSixFirstLoad(true);props.IFsetActivePage(1); }}>{t("previousStepMF")}</Button>{' '}
            {/* <Button variant="warning" disabled={props.showStripeForm} onClick={props.IFsetStripeButton}>{t("SubmitLabelF6")}</Button>*/}
          </div>
        </Col>
        <Col xs={12} lg={12} className='mb-3 mt-5'>
          {props.agreementMsg}
          {props.membership_level > 0 &&
            <Row className='justify-content-center'>
              <Col xs={12} md={6} xl={4} >{props.stripeForm}</Col>
            </Row>
          }

          {props.membership_level <= 0 &&
            <Row className='justify-content-center'>
              <Col xs={12} md={6} xl={4} >{t('membership_level_error')}</Col>
            </Row>
          }

        </Col>
      </Row>

    </div>
  );
}

export default Block2;


interface ISign{
  access_key:string,
  profile_id: string,
  transaction_uuid: string,
  signed_field_names: string,
  unsigned_field_names: string,
  signed_date_time: string,
  locale: string,
  transaction_type: string,
  reference_number: string,
  amount: string,
  currency: string,
  signature: string,
}


export const setCookie = (name: string, value: string, days: number) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};



export interface IPayFree{
  getAmount:number,
  getOrginAmount:number,
  getAddPoint:number,
  promoCode:string,
  autoPromoCode:string,
  language:string,
  shippingfee:number,
  taxfee:number,
  OverChargefee:number,
}