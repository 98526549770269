

import { useTranslation } from 'react-i18next';




function CouponError() {

  const { t } = useTranslation();


  return (
    <div className="">

                    <fieldset className="border radius-10 mt-5 text-start p-5 backcolor-gray">
                      <div className='text-start'>
                        <div className='next-line-if-lf fw-bold '>{t("CouponErrorMsg")}</div>

                      </div>
                    </fieldset>
   
          
  </div>
  );
}

export default CouponError;

