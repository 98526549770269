

import { useTranslation } from 'react-i18next';

export interface ICancelPage {
  Msg: string;
}


function CancelPage(props: ICancelPage) {

  const { t } = useTranslation();

  return (
    <div className="">

                    <fieldset className="border radius-10 mt-5 text-start p-5 backcolor-FF9999">
                      <div className='text-start'>
                        <div className='next-line-if-lf fw-bold '>{props.Msg}</div>

                      </div>
                    </fieldset>
   
          
  </div>
  );
}

export default CancelPage;

