import React from 'react';
import TextField from '@mui/material/TextField';
import {Container, Row,Col} from "react-bootstrap";
import { useTranslation } from 'react-i18next';

export interface IPriceListItem{
    itemTitle: string,
    itemSubTitle: string,
    itemPrice: number | null,
    itemColor: string,
    promoPoint?: number,
  }

function PriceListItem(props:IPriceListItem) {
  //lan
  const { t } = useTranslation();

  var color = 'text-start fw-bold ' + props.itemColor;

  return (
    <div className="App mt-3 mb-3">
        <h5 className={color}><span className=''>{props.itemTitle}</span>
        <span className='color-red'>{props.itemSubTitle}</span>
        {props.itemPrice != null && <span className='float-end '>{t("PriceCurrency1F6")} {props.itemPrice!!.toLocaleString()} {t("PriceCurrency2F6")}</span>}</h5>
        {props.promoPoint != null && props.promoPoint > 0 && <div className='color-gray text-end '>{t('PromoPointF6')} {props.promoPoint}</div>}

    </div>
  );
}

export default PriceListItem;

