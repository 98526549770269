
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingBlock from '../page/Block/LoadingBlock';
import ResultPage2 from '../page/Block/ResultPage2';
import ResultPage from '../page/Block/ResultPage';
import axios, { AxiosRequestConfig } from 'axios';
import Button from '@mui/material/Button';
import { useParams } from 'react-router-dom';


function Alipay(): JSX.Element {
    const urlParams = new URLSearchParams(window.location.search);
    const [FirstLoad,setFirstLoad] = useState(true);

    //Page Control
    const [activePage,setActivePage] = useState(0);
    //Page Control

    // Get the value of a specific parameter
    const paymentIntent = urlParams.get('payment_intent');
    const clientSecret = urlParams.get('payment_intent_client_secret');
    const redirectStatus = urlParams.get('redirect_status');
    const token = urlParams.get('token');
    const { t } = useTranslation();
    const params = useParams();

    let language :string = params.language === 'zh' ? "zh" : "en";

    const axiosConfig:AxiosRequestConfig = {
      headers:{Authorization: `Bearer ${token}`}
    }
    const confirmPaymentData = {
      payment_id: paymentIntent,
      lang: language,
    } 

    function PaidSuccess() {
      axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/payment_confirm`,
          confirmPaymentData, 
          axiosConfig
      ).then((res) => {
        setActivePage(4);
      }).catch( (err) => {
        let smsJson = {
            errorcode: err.message,
            payment_id: paymentIntent,
        }

        axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/senderrorsms`, 
            smsJson, 
            axiosConfig
        ).then((res) => {
            //console.log(res.data)
            setActivePage(5);
        }).catch( (err) => {
            //console.log(err)
            setActivePage(5);
        }) 
      }) 
      
    }
    function PaidFailed() {
      axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/payment_failed`,
          confirmPaymentData, 
          axiosConfig
      ).then((res) => {
      }).catch( (err) => {
      }) 
      setActivePage(5);
    }
    
    const IFToStatusPage = () => {
      window.location.href = `/c0501/zh/p/shipmentstatus?token=${token}`;
    };

    useEffect(() => {
        if(FirstLoad){
            setActivePage(8);
          if(redirectStatus === 'succeeded'){
            PaidSuccess();
          }else{
            PaidFailed();
          }
          setFirstLoad(false);
        }
      });

    return (
        <>
            {activePage === 8 &&  <LoadingBlock />}

            {activePage === 5 &&  <ResultPage2 />}

            {/* {activePage === 4 &&  <ResultPage />} */}

            {/* {activePage == 4 &&  <div className='text-center'>
              <Button variant="contained" color="info" className='float-center mt-5' onClick={() => {IFToStatusPage()}}>
                {t("ToStatus")}
              </Button>
            </div>}  */}
        </>
      );
        
}

export default Alipay;


