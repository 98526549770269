import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import {Container, Row,Col} from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import axios from 'axios';
import { JSONType } from './MainForm';
import { useLocation, useParams } from 'react-router-dom';
import StatusComponent, { IStatusComponent } from './StatusBlock/StatusComponent/StatusComponent';
import StatusDetail from './StatusBlock/StatusDetail';
import LoadUrlFailed from './Block/LoadUrlFailed';
import MaintenancePage from './Block/MaintenancePage';
import ShipmentRecordList from './Block/RecordBlock/ShipmentRecordList';
import LoadingBlock from './Block/LoadingBlock';

export interface IShipmentRecord{
    custompKey?: string;
}

export interface IClientData{
  customer_user_id: string;
  customer_user_point: number;
  customer_user_point_vba: number;
}

function ShipmentRecord() {
    const params = useParams();
    //lan
    const { t } = useTranslation();

    //Page Control
    const [activePage,setActivePage] = useState(0);
    //Page Control
    const [FirstLoad,setFirstLoad] = useState(true);    

    //MaintainPage
    const [maintainMsg, setMaintainMsg] = useState("");
    //MaintainPage

    const location = useLocation();
    const state = location.state as { custompKey: string }; // Typecasting for safety
    const custompKey = state?.custompKey;


    useEffect(() => {

        if(FirstLoad){
          setLanguage();
          CheckMaintenance().then((isUnderMaintenance) => {
            if (!isUnderMaintenance) {
              LoadData();
            }
          });
          setFirstLoad(false);
        }
        
    });
    
    const setLanguage = () => {
        let language :string = params.language === 'zh' ? "zh" : "en";
        handleChangeLng(language);
        document.documentElement.lang = language;
      }

    const handleChangeLng = (lng: string) => {
		i18n.changeLanguage(lng);
		localStorage.setItem("lng", lng);
	};

    const CheckMaintenance = async () => {
        const queryParameters = new URLSearchParams(window.location.search);
        const jwt = queryParameters.get('token');
    
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/CheckMaintenance`,
            {},
            { headers: { Authorization: `Bearer ${jwt}` } }
          );
    
          if (response.data.maintenance_msg) {
            setMaintainMsg(response.data.maintenance_msg);
            setActivePage(11); //Display Maintenance Page
            return true;
          }
          return false;
        } catch (error) {
          console.error('An error occurred while checking for maintenance:', error);
          // Handle the error according to your needs
          return false;
        }
      };
    
      const LoadData =  () => {
        setActivePage(1)
      }

    return (
        <div className="App">

                <Container>
                    <Row>
                        <Col xs="12" md="12" lg="12" className='non-p'>

                            {activePage === 0 && <LoadingBlock />}

                            {activePage == 1 && <ShipmentRecordList custompKey={custompKey} /> }
                            
                            {activePage === 11 && <MaintenancePage maintainMsg={maintainMsg}/>}

                        </Col>
                    </Row>
                </Container>
        </div>
    );
}

export default ShipmentRecord;
