import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IShipmentRecord } from '../page/ShipmentRecord';
import { Button } from 'react-bootstrap';



function RecordButton(props:IShipmentRecord): JSX.Element {
    const urlParams = new URLSearchParams(window.location.search);
    const { t } = useTranslation();
    const params = useParams();
    const navigate = useNavigate();

    let language :string = params.language === 'zh' ? "zh" : "en";

    const GoToRecord = () => {
        navigate(`/c0501/${language}/p/Record`, { state: { custompKey: props.custompKey } });
    };


    return (
        <>
            <Button variant="outline-dark" className='float-end mb-5 ms-3' onClick={() => {GoToRecord()}}>{t("ShipmentRecordBtn")}</Button>
        </>
      );
        
}

export default RecordButton;


