

import { useTranslation } from 'react-i18next';

interface MaintenancePage {
  maintainMsg:string,
}


function MaintenancePage(props:MaintenancePage) {

  const { t } = useTranslation();

  return (
    <div className="">

      <fieldset className="border radius-10 mt-5 text-start p-5 backcolor-gray">
        <div className='text-center'>
        <div className='next-line-if-lf fw-bold '>{props.maintainMsg}</div>

        </div>
      </fieldset>
          
  </div>
  );
}

export default MaintenancePage;

