import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';


import 'bootstrap/dist/css/bootstrap.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";

import MainForm from './page/MainForm';
import StatusForm from './page/StatusForm';
import Alipay from './component/Alipay';
import Recon from './component/Recon';
import InquireForm from './page/InquireForm';
import ShipmentRecord from './page/ShipmentRecord';
import ShipmentRecordDetail from './page/Block/RecordBlock/ShipmentRecordDetail';
import GlobePaymentPage from './component/GlobePayment';
import BlankPage from './component/BlankPage';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
      <Routes>
        <Route path="/" element = {<App />}>
          <Route path="" element={<App />} />
          {/* <Route path="c0501/zh/:shipment_number" element={<GetC0501AccessTokenFun />} ></Route> */}
          <Route path="c0501/:language/p/shipment" element={<MainForm />} />
          <Route path="c0501/:language/p/shipmentstatus" element={<StatusForm />} />
          <Route path="c0501/:language/p/AlipayResult" element={<Alipay />} />
          <Route path="c0501/:language/p/ReconResult" element={<Recon />} />
          <Route path="c0501/:language/p/Inquire" element={<InquireForm />} />
          <Route path="c0501/:language/p/Record" element={<ShipmentRecord />} />
          <Route path="c0501/:language/p/RecordDetail" element={<ShipmentRecordDetail />} />
          <Route path="c0501/:language/p/shipmentPayment" element={<GlobePaymentPage />} />
          
        </Route>
      </Routes>
    </BrowserRouter>
);

//<Route path="/Generate" element={<GenPage />} />

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
